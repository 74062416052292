<template>
    <el-card shadow="never">
        <template #header>
            <div class="clearfix" style="text-align: left">
                <el-date-picker v-model="range" size="small" type="daterange" unlink-panels range-separator="至"
                    start-placeholder="开始时间" end-placeholder="结束时间" :shortcuts="shortcuts" style="margin-right: 10px"
                    @selection-change="CheckSelection">
                </el-date-picker>
                <el-input v-model="keyword" size="small" placeholder="客户姓名/联系方式/订单编号/物流单号/套餐标题/业务员"
                    prefix-icon="el-icon-search" style="width: 400px" class="blockitem"></el-input>
                <el-select v-model="ispurchase" size="small" clearable style="margin-right: 10px; width: 150px"
                    placeholder="请选择订单类型">
                    <el-option label="特惠单" value="1" />
                    <el-option label="随心购" value="2" />
                    <el-option label="六期" value="3" />
                    <el-option label="租快享" value="4" />
                </el-select>
                <el-button @click="init" size="small" type="primary"> 查询 </el-button>
                <el-button type="primary" size="small" @click="exportorder1">
                    导出订单
                </el-button>
            </div>
        </template>
        <el-tabs v-model="active" class="demo-tabs" @tab-click="handleClick">
            <el-tab-pane v-for="(item, index) in tab_for" :label="item.one + item.two + item.three" :key="index"
                :name="item.name">
                <table style="width: 100%; margin: 1em 0; font-size: 14px">
                    <tr>
                        <th style="width: 300px; text-align: left; padding-right: 10px">
                            套餐标题
                        </th>
                        <th style="width: 15%; text-align: left; padding-right: 10px">
                            租赁信息
                        </th>
                        <th style="width: 10%; text-align: left; padding-right: 10px">
                            押金信息
                        </th>
                        <th style="width: 15%; text-align: left; padding-right: 10px">
                            用户信息
                        </th>
                        <!-- <th style="width: 10%; text-align: left; padding-right: 10px">
              订单来源
            </th> -->
                        <th style="width: 10%; text-align: left; padding-right: 10px">
                            备注/证据
                        </th>
                        <th style="width: 10%; text-align: left; padding-right: 10px">
                            订单状态
                        </th>
                        <th style="width: 5%; text-align: left">操作</th>
                        <th style="width: 15%"></th>
                    </tr>
                </table>
                <div class="order-item" v-for="(item, index) in orderdata" :key="item">
                    <div class="orderheader">
                        <span style="margin-right: 20px">
                            <el-checkbox></el-checkbox>
                        </span>
                        <el-image style="width: 32px; height: 20px" v-if="item.wxtype == 1" :src="weixin" fit="fit" />
                        <el-image style="width: 25px; height: 20px" v-else-if="item.wxtype == 3" :src="douyin"
                            fit="fit" />
                        <el-image style="width: 32px; height: 20px" v-else :src="zhifubao1" fit="fit" />
                        <span class="headerspan">
                            <el-tag v-if="item.ParentID > 0" type="danger" size="large">续</el-tag>
                            订单编号：{{ item.OrderUID }}
                        </span>
                        <span class="headerspan">下单时间：{{ item.AddDTime }}</span>
                        <!--<span class="headerspan" v-if="ismerchant != 1 || mid == 9">e签宝 
                              <el-tag type="success" v-if="item.EsignStatus == 1" class="el-icon-check" size="mini" style="margin-left:5px;"></el-tag>
                              <el-tag type="danger" v-else class="el-icon-close" size="mini" style="margin-left:5px;"></el-tag>
                          </span>-->
                        <span class="headerspan">商户名称:
                            <span v-if="item.storename && item.allot == 0">
                                {{ item.storename }}
                            </span>
                            <span v-else>{{ item.Merchant }} </span>
                            <!-- <span style="cursor: pointer" @click="diaobo(item, item.wxtype)"
									v-if="ismerchant == 0 && item.Status == 2"> -->
                            <span style="cursor: pointer" @click="diaobo(item, item.wxtype)"
                                v-if="ismerchant == 0 && item.Status == 2">
                                <el-tag size="small" round="true">调拨</el-tag>
                            </span>
                        </span>
                        <span class="headerspan">结算比例:
                            <span v-if="item.OrderRate">{{ item.OrderRate }}%</span>
                            <span v-else>{{ item.Rate }}%</span>
                            <i class="el-icon-edit-outline" style="color: #0099ff; margin-left: 10px"
                                @click="EditValue(item.ID)" v-if="ismerchant != 1"></i>
                        </span>
                        <span class="headerspan">续租比例:
                            <span v-if="item.premiumrate">{{ item.premiumrate }}倍</span>
                            <i class="el-icon-edit-outline" style="color: #0099ff; margin-left: 10px"
                                @click="EditValue2(item.ID)"></i>
                        </span>
                        <!-- <span class="headerspan" v-if="item.partnerid > 0 && ismerchant != 1">代理商店铺类型:
                <span v-if="item.storetype == 1" style="color:#9566fd;">连锁</span>
                <span v-if="item.storetype == 2" style="color:#67b1ff;">店面</span>
                <span v-if="item.storetype == 3" style="color:yellow;">柜台</span>
              </span> -->
                        <span class="headerspan" v-if="item.partnerid > 0 && ismerchant != 1">代理商:
                            <span>{{ item.agentname }}</span>
                        </span>
                        <span class="headerspan" v-if="item.partnerid > 0 && ismerchant != 1">分销店铺:
                            <span @click="fenxiao(item.ID)" v-if="item.storetype == 1">{{
                                item.storename
                            }}</span>
                            <span @click="fenxiao(item.ID)" v-else-if="item.storetype == 2">{{
                                item.storename
                            }}</span>
                            <span @click="fenxiao(item.ID)" v-else-if="item.storetype == 3">{{
                                item.storename
                            }}</span>
                            <span @click="fenxiao(item.ID)" v-else style="cursor: pointer">{{
                                item.storename
                            }}</span>
                            <el-tag @click="changedp(item.ID)" v-if="item.Status == 4" style="cursor: pointer"
                                size="small">切换店铺</el-tag>
                        </span>
                        <span class="headerspan" v-if="ismerchant == 0">
                            <span v-if="item.ywyname != null && item.ywyname != ''">业务员：{{ item.ywyname }}</span>
                            <span style="cursor: pointer" @click="saveywy(item.ID)" v-else>请填写业务员</span>
                        </span>
                        <div v-if="item.Logistic" style="display: flex; flex-direction: row; align-items: center">
                            <span class="headerspan" v-if="item.Status > 4">IMEI/SN：{{ item.Logistic.UID }}</span>
                            <span class="headerspan" v-if="
                                item.Status == 5 ||
                                item.Status == 6 ||
                                item.Status == 7 ||
                                item.Status == 8 ||
                                item.Status == 9 ||
                                item.Status == 11
                            ">物流公司: {{ item.Logistic.Name }}</span>
                            <span style="font-size: 14px; font-weight: bold; color: #757575" v-if="
                                item.Status == 5 ||
                                item.Status == 6 ||
                                item.Status == 7 ||
                                item.Status == 8 ||
                                item.Status == 9 ||
                                item.Status == 11
                            ">快递单号:
                            </span>
                            <span style="position: relative; display: flex" v-if="
                                item.Status == 5 ||
                                item.Status == 6 ||
                                item.Status == 7 ||
                                item.Status == 8 ||
                                item.Status == 9 ||
                                item.Status == 11 ||
                                item.Status == 12 ||
                                item.Status == 24
                            ">
                                <el-button type="text" size="medium"
                                    @click="GetLogisticDetail(item.Logistic.LogisticNo)">{{
                                        item.Logistic.LogisticNo }}
                                </el-button>
                                <el-tooltip trigger="click" transition="el-fade-in-linear" auto-close="1000"
                                    content="复制成功">
                                    <el-image :src="copy" style="
                      margin-left: 10px;
                      position: relative;
                      height: 1.5rem;
                      top: 7px;
                      cursor: pointer;
                    " @click="copycourier(item.Logistic.LogisticNo)"></el-image>
                                </el-tooltip>
                            </span>
                            <!--授权单号(点击获取)-->
                            <!-- <el-tooltip 
                  trigger="click"
                  transition="el-fade-in-linear"
                  auto-close="1000"
                  content="复制成功"> -->
                            <span v-if="ismerchant != 1" style="
                  font-size: 14px;
                  font-weight: bold;
                  color: #757575;
                  margin-left: 10px;
                  cursor: pointer;
                " @click="getauth(item.ID)">授权单号(点击获取)
                            </span>
                            <!-- </el-tooltip> -->
                        </div>
                    </div>
                    <table style="width: 100%; margin: 1em 0; font-size: 14px">
                        <tr>
                            <td style="width: 300px; text-align: left; padding-right: 10px">
                                <div class="pdt-block">
                                    <el-image :src="item.PdtThumb" style="
                      width: 100px;
                      height: auto;
                      margin-right: 15px;
                      border: 1px solid #dcdcdc;
                    "></el-image>
                                    <div>
                                        <!-- <span style="color:red;margin:3px;border:1px solid red">{{item.PlanProp.quality}}</span>&nbsp; -->
                                        <el-tag class="ml-2" style="height: 20px; line-height: 20px" type="danger">{{
                                            item.PlanProp.quality }}</el-tag>
                                        <span @click="Gotopdt(item.PdtCode)" style="color: #6699ff; cursor: pointer">{{
                                            item.PdtName }}</span>
                                        <br />
                                        <div style="color: #708090; margin-top: 15px">
                                            套餐名称: {{ item.PlanTitle }}
                                        </div>
                                        <div style="color: #708090; margin-top: 15px">
                                            参数: {{ item.PlanProp.params }}
                                        </div>
                                        <div style="color: #708090; margin-top: 15px">
                                            配送方式：<span v-if="item.Deliver">{{
                                                item.Deliver
                                            }}</span>
                                            <span v-else>寄出到付,归还自付</span>
                                        </div>
                                        <span style="display: block; margin-top: 10px">
                                            是否支持买断:
                                            <span v-if="item.PlanProp.buyout == 1">是</span>
                                            <span v-if="item.PlanProp.buyout == 2">否</span>
                                        </span>
                                    </div>
                                </div>
                            </td>
                            <td style="width: 15%; text-align: left; padding-right: 10px">
                                <div>用户租期：{{ item.RentDay }}天</div>
                                <div v-if="item.ResignDTime">
                                    开始：{{ formatDay(item.ResignDTime) }}
                                </div>
                                <div v-if="item.ReturnDTime">
                                    结束：{{ formatDay(item.ReturnDTime) }}
                                </div>
                                <div>期数：{{ item.RentCount }}</div>
                                <div>签约价：{{ item.PlanProp.buyoutprice }}</div>
                                <div v-if="item.CouponType" style="text-decoration: line-through">
                                    原租金：{{ item.TotalOrderMoney }}
                                </div>
                                <div v-if="item.CouponType && item.UseType == 1">
                                    实付租金：{{
                                        (item.TotalOrderMoney * 1 - item.CouponValue * 1).toFixed(2)
                                    }}
                                </div>
                                <div v-if="item.CouponType && item.UseType == 2">
                                    实付租金：{{
                                        (
                                            item.TotalOrderMoney *
                                            1 *
                                            (item.CouponValue / 100)
                                        ).toFixed(2)
                                    }}
                                </div>
                                <div v-if="!item.CouponType">
                                    总租金：<span v-if="item.TotalDiscountMoney != 0" style="
                      text-decoration-line: line-through;
                      margin-right: 5px;
                    ">{{ item.sum }}.00</span>{{ item.TotalOrderMoney }}
                                </div>
                                <div>
                                    到期买断价：{{
                                        item.PlanProp.buyoutprice * 1 - item.TotalOrderMoney * 1 > 0
                                            ? (
                                                item.PlanProp.buyoutprice * 1 -
                                                item.TotalOrderMoney * 1
                                            ).toFixed(2)
                                            : 0.0
                                    }}
                                </div>
                                <div>数量：{{ item.OrderCount }}</div>
                                <span v-if="item.ID > 46444">
                                    <span v-if="item.ispurchase == 1">
                                        <el-tag>特惠单</el-tag>
                                    </span>
                                    <span v-if="item.ispurchase == 2">
                                        <el-tag>随心购</el-tag>
                                    </span>
                                    <span v-if="item.ispurchase == 3">
                                        <el-tag>六期</el-tag>
                                    </span>
                                    <span v-if="item.ispurchase == 4">
                                        <el-tag>租快享</el-tag>
                                    </span>
                                </span>
                                <div v-if="item.CouponType">
                                    优惠券名称：{{ item.CouponType }}
                                </div>
                                <div v-if="item.CouponType && item.UseType == 1">
                                    优惠金额：{{ item.CouponValue }}
                                </div>
                                <div v-if="item.CouponType && item.UseType == 2">
                                    优惠金额：{{ item.CouponValue }}%
                                </div>
                            </td>
                            <td style="width: 10%; text-align: left; padding-right: 10px">
                                <!--<el-button type="text" @click="ShowDeposit(item.ID)">押金信息</el-button>-->
                                <!--
                      this.plan_deposit = response.data.PlanDeposit*1;
                      this.plan_sdeposit = response.data.SDecrease*1;
                      this.plan_rdeposit = response.data.RDecrease*1;
                      this.plan_replenish = response.data.Replenish*1;
                      this.plan_depositpaid = response.data.DepositPaid*1;
                      this.restor_deposit = response.data.DepositPaid*1;
                      this.deposit = response.data.Deposit*1;
                      this.cur_order_status = response.data.Status;
                      this.zhimadecrease = response.data.ZhimaDecrease*1;
                  -->
                                套餐类型：<span v-if="item.PlanProp.plantype == 1">租赁</span>
                                <span v-if="item.PlanProp.plantype == 2">到期赠送</span>
                                <span v-if="item.PlanProp.plantype == 3">售卖</span>
                                <span v-if="item.PlanProp.plantype == 4">定制租赁</span>
                                <br />
                                套餐押金：<span v-if="item.iDeposit">{{
                                    item.iDeposit * 1
                                }}</span>
                                <br />
                                已交租：¥{{ item.Paid ? item.Paid * 1 : 0.0 }}<br />
                                未交租：¥{{ item.UnPaid ? item.UnPaid * 1 : 0.0 }}
                                <!-- 免押额度：<span v-if="item.Depositinfo">{{
                    item.Depositinfo.SDecrease * 1 +
                      item.Depositinfo.RDecrease * 1 +
                      item.Depositinfo.ZhimaDecrease * 1
                  }}</span> -->
                                <br />
                                <!-- 补押金：<span v-if="item.Depositinfo">{{
                    item.Depositinfo.Replenish * 1
                  }}</span>
                  <br /> -->
                                <!--已冻结押金：<span v-if="item.Depositinfo">{{
                    item.Depositinfo.DepositPaid * 1
                  }}</span>
                  <br />
                  <span
                    v-if="
                      (cur_order_status == 9 ||
                        cur_order_status == 10 ||
                        cur_order_status == 21 ||
                        cur_order_status == 13) &&
                      item.Depositinfo
                    "
                    >已解冻押金：{{ item.Depositinfo.DepositPaid * 1 }}</span
                  >
                  <span v-else>已解冻押金：0</span>
                  <br />-->
                                <el-tag size="small" type="success">已冻结押金：{{
                                    item.DepositPaid - item.TotalServiceMoney > 0
                                        ? (item.DepositPaid - item.TotalServiceMoney).toFixed(2)
                                        : item.DepositPaid
                                }}</el-tag>
                                <br />
                                <span style="font-size: 12px; color: red">其中押金转支付租金：</span>{{
                                    item.BuyerPayAmount * 1 - item.TotalServiceMoney > 0
                                        ? (
                                            item.BuyerPayAmount * 1 -
                                            item.TotalServiceMoney
                                        ).toFixed(2)
                                        : item.BuyerPayAmount * 1
                                }}
                                <br />
                                <!-- <span>已解冻押金111：</span>{{ item.Thawed * 1 }}
                  <br /> -->
                                <span>未解冻押金：</span>{{ item.NotThawed * 1 }}<br />
                                <span>补押金：</span>
                                <span v-if="item.isdeposit == 1" style="color: #67b1ff">{{
                                    item.newdeposit
                                }}</span>
                                <span v-else>{{ item.newdeposit }}</span>
                                <el-tag @click="newdeposit(item.ID, item.wxtype)" v-if="item.newdeposit > 0"
                                    style="cursor: pointer">详情</el-tag>
                            </td>
                            <td style="width: 15%; text-align: left; padding-right: 10px">
                                <div>联系人：{{ item.Receiver }}</div>
                                <div>联系方式：{{ item.Mobile }}</div>
                                <div>
                                    地址：{{ item.province }} {{ item.city }}
                                    {{ item.area }} {{ item.Address }}
                                </div>
                                <!-- <div>买家备注：</div> -->
                                <!-- <div>卖家备注：</div> -->
                                <div>邮箱：{{ item.Email }}</div>
                                <div>实名电话：{{ item.RealMobile }}</div>
                            </td>
                            <!--<td style="width: 10%; text-align: left; padding-right: 10px">
                  支付宝小程序
                </td>-->
                            <td style="width: 10%; text-align: left; padding-right: 10px">
                                <!-- <div
                    style="cursor: pointer"
                    v-if="item.jid != 0"
                    @click="viewNotes(item.ID)"
                  >
                    查看备注 ({{ item.jid }})
                  </div> -->
                                <div style="color: #409eff; cursor: pointer; margin-top: 5px"
                                    @click="evidence2(item.ID)">
                                    录入证据
                                </div>
                                <div v-if="item.kid == 0" style="color: #aaaaaa; margin-top: 10px">
                                    暂无证据
                                </div>
                                <div style="cursor: pointer; margin-top: 10px" v-if="item.kid != 0"
                                    @click="viewev(item.ID)">
                                    查看证据({{ item.kid }})
                                </div>
                                <div style="color: #409eff; cursor: pointer; margin-top: 5px"
                                    @click="infolist2(item.ID)" v-if="ismerchant != 1">
                                    审核资料
                                </div>
                                <div style="color: #409eff; cursor: pointer; margin-top: 5px" @click="jiaofu(item.ID)">
                                    交付资料
                                </div>
                            </td>
                            <td style="width: 10%; text-align: left; padding-right: 10px">
                                <div style="
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                  " v-if="
                    item.Status == 6 || item.Status == 12 || item.Status == 24
                ">
                                    <span>待归还
                                        <el-tag style="cursor: pointer" v-if="ismerment != 1" size="samll"
                                            @click="editstatus(item.ID, 'gui')">修改状态</el-tag>
                                    </span>
                                    <el-tag type="danger" size="mini"
                                        v-if="item.Status == 12 && item.deviceoverdue > 0">
                                        还机逾期({{ item.deviceoverdue }})天</el-tag>
                                    <el-tag type="danger" size="mini" v-if="item.Status == 24 && item.moneyoverdue > 0">
                                        还款逾期({{ item.moneyoverdue }})天</el-tag>
                                </div>
                                <div v-else>
                                    <span v-if="item.Status == 1">风控审核中</span>
                                    <span v-if="item.Status == 2">人工审核中</span>
                                    <span v-if="item.Status == 3">待付款</span>
                                    <span v-if="item.Status == 4">待发货</span>
                                    <span v-if="item.Status == 5">待收货 </span>
                                    <!-- <span v-if="item.Status == 6 || item.Status == 12">待归还
                    </span> -->
                                    <span v-if="item.Status == 7">归还中
                                        <span @click="editstatus(item.ID, item.Status)"><el-tag size="small"
                                                style="cursor: pointer" v-if="ismerment != 1">修改状态</el-tag></span>
                                    </span>
                                    <span v-if="item.Status == 8">定损中</span>
                                    <span v-if="item.Status == 9">已完成</span>
                                    <span v-if="item.Status == 10">已取消</span>
                                    <span v-if="item.Status == 11">强制买断中</span>
                                    <span v-if="item.Status == 14">定损待赔付</span>
                                    <!--<span v-if="item.Status == 15">定损已赔付</span>-->
                                    <span v-if="item.Status == 16">返修待赔付</span>
                                    <span v-if="item.Status == 17">返修已赔付</span>
                                    <span v-if="item.Status == 13">审核不通过</span>
                                    <span v-if="item.Status == 19">取消订单赔款中</span>
                                    <span v-if="item.Status == 20">返修中</span>
                                    <span v-if="item.Status == 21">已买断</span>
                                    <span v-if="item.Status == 22">赔偿金已支付</span>
                                    <span v-if="item.Status == 23">强制买断</span>
                                    <span v-if="item.Status == 25">买断中</span>
                                    <span v-if="item.Status == 26">下单中</span>
                                    <span v-if="item.Status == 27">自动取消</span>
                                    <span v-if="item.Status == 29" style="
                      display: block;
                      background-color: red;
                      color: white;
                      padding: 4px 10px;
                      border-radius: 10px;
                      width: 48%;
                      font-size: 10px;
                    ">用户申请取消</span>
                                    <br />
                                    <span v-if="item.Status == 29" style="
                      font-size: 12px;
                      color: #b0b0b0;
                      display: block;
                      margin-top: -16px;
                    ">
                                        取消原因：{{ item.Reason.split("|")[0] }}
                                    </span>
                                    <!-- <el-tag
                      type="danger"
                      size="mini"
                      v-if="item.Status == 5"
                      style="margin-top: 5px"
                      >物流已签收</el-tag
                    > -->
                                </div>
                                <!-- <div >
                    已交租：¥{{ item.Paid ? item.Paid * 1 : 0.0 }}
                  </div>
                  <div >
                    未交租：¥{{ item.UnPaid ? item.UnPaid * 1 : 0.0 }}
                  </div> -->
                                <el-tag type="danger" size="small">预授权代扣</el-tag>
                                <el-button type="primary" size="mini" style="margin-top: 10px" @click="
                                    GetOrderBill(
                                        item.ID,
                                        item.Status,
                                        item.RentCount,
                                        item.wxtype
                                    )
                                    ">查看账单</el-button>
                            </td>
                            <td style="width: 5%; text-align: left">
                                <div style="color: #409eff; cursor: pointer; margin-top: 5px"
                                    @click="tradingsnapshot(item.ID)">
                                    交易快照
                                </div>
                                <div style="color: #409eff; cursor: pointer; margin-top: 5px" @click="Oplog(item.ID)">
                                    操作日志
                                </div>
                                <div type="success" size="mini" style="color: #409eff; cursor: pointer; margin-top: 5px"
                                    @click="GetTrade(item.ID)">
                                    交易记录
                                </div>
                            </td>
                            <td style="width: 15%" v-if="ismerchant != 1">
                                手机号下单：{{ item.mobile }}<br />
                                下单次数：{{ item.mobile_order_count }}<br />
                                在租订单数：{{ item.mobile_rent_count }}<br />
                                在审核订单数：0<br />
                                身份证下单：{{ item.license }}<br />
                                下单次数：{{ item.license_order_count }}<br />
                                在租订单数：{{ item.license_rent_count }}<br />
                                在审核订单数：0
                            </td>
                        </tr>
                    </table>
                    <div class="orderbottom">
                        <div class="leftbtn" v-show="ismerchant != 1">
                            <span class="msginfo">推送风控信息 : </span>
                            <el-switch v-model="item.IsShow" active-value="1" inactive-value="0"
                                @change="PushMsg(item.ID, item.IsShow)"></el-switch>
                            <span style="font-size: 14px; color: #708090; margin-left: 20px">
                                <span style="cursor: pointer" v-if="ismerchant == 0" @click="authentication(item)">
                                    认证资料：
                                </span>
                                <span v-else>认证资料：</span>
                                {{ item.RealName }}
                                ({{ item.LicenseID }})&nbsp;&nbsp;
                                {{ item.Gender == "m" ? "性别：男" : "性别：女" }}
                                年龄：{{ item.Age }}
                            </span>
                            <span style="
                  margin-left: 15px;
                  display: flex;
                  height: 2.27rem;
                  cursor: pointer;
                ">
                                <el-image v-if="item.LicenseImg != '' && item.LicenseImg != '[]'" :src="idshow"
                                    @click="showid(item.LicenseImg)">
                                </el-image>
                                <el-image v-if="item.LicenseImg == '' || item.LicenseImg == '[]'" :src="idhide"
                                    @click="addidinfo(item.ID)">
                                </el-image>
                            </span>
                        </div>
                        <div class="leftbtn" v-show="ismerchant == 1">
                            <span style="font-size: 14px; color: #708090">认证资料：{{ item.RealName }}(
                                <span v-if="item.LicenseID">{{
                                    changeToStar(item.LicenseID, 6, 4)
                                }}</span>)&nbsp;&nbsp;{{
                                        item.Gender == "m" ? "性别：男" : "性别：女"
                                    }}
                                年龄：{{ item.Age }}</span>
                            <span style="
                  margin-left: 15px;
                  display: flex;
                  height: 2.27rem;
                  cursor: pointer;
                ">
                                <el-image v-if="item.LicenseImg != ''" :src="idshow" @click="showid(item.LicenseImg)">
                                </el-image>
                                <el-image v-if="item.LicenseImg == ''" :src="idhide" @click="addidinfo(item.ID)">
                                </el-image>
                            </span>
                        </div>
                        <div class="rightbtn" style="display: flex">
                            <!--<el-button size="small" @click="Comfirm(item.ID)" v-if="item.Status == 2">审核</el-button>-->
                            <div class="leftbtn" v-show="item.Status == 13">
                                <span v-if="item.Reason != ''" style="font-size: 14px; color: #708090">审核不通过的原因：{{
                                    item.Reason
                                }}</span>
                            </div>
                            <div class="leftbtn" v-show="item.Status == 10">
                                <span v-if="item.Reason != ''" style="font-size: 14px; color: #708090">取消原因：{{
                                    item.Reason
                                }}</span>
                            </div>
                            <el-button size="small" v-if="item.lease != null && item.Status != 10"
                                style="background-color: #9566fd; color: white">区块链</el-button>
                            <el-button size="small" type="success" @click="Download(item.insuranceurl)" v-if="
                                item.Status >= 6 &&
                                item.Status != 10 &&
                                item.lease != null &&
                                item.resultCode == 200
                            ">投保成功</el-button>
                            <el-button size="small" type="danger" @click="lookerror(item.resultMsg)" v-if="
                                item.Status >= 6 &&
                                item.Status != 10 &&
                                item.lease != null &&
                                item.resultCode != 200 &&
                                item.resultCode != null
                            ">投保失败</el-button>
                            <el-button size="small" @click="Prepay(item.ID, item)"
                                v-if="item.Status == 2 || item.Status == 29">审核
                            </el-button>
                            <el-button size="small" @click="EditOrder(item.ID)"
                                v-if="item.Status == 2 && ismerchant == 0 && item.IsEdit == 0">修改订单</el-button>
                            <el-button size="small" @click="Extend(item.ID)" v-if="
                                ismerchant == 0 &&
                                (item.Status == 4 ||
                                    item.Status == 5 ||
                                    item.Status == 6 ||
                                    item.Status == 12 ||
                                    item.Status == 24) &&
                                operator == '管理员'
                            ">延长订单</el-button>
                            <!--<el-button size="small" @click="Addition(item.ID,item.Status)" v-if="item.Status == 6 || item.Status == 7 || item.Status == 8">补订单</el-button>-->
                            <el-button size="small" @click="OpenCancel(item.ID, item.TotalPayMoney, item)" v-if="
                                ismerchant != 1 &&
                                (item.Status == 2 ||
                                    item.Status == 3 ||
                                    item.Status == 5 ||
                                    item.Status == 4 ||
                                    item.Status == 29)
                            ">取消订单</el-button>
                            <el-button size="small" @click="bohui(item.ID)" v-if="item.Status == 29">驳回</el-button>
                            <!--<el-button size="small" @click="CheckReceipt(item.ID)" v-if="item.Status == 4 || item.Status == 5">商户交付确认单</el-button>-->
                            <el-button size="small" @click="AddLogistic(item.ID, item, item.lease)"
                                v-if="item.Status == 4">填写发货信息
                            </el-button>
                            <el-button size="small" @click="EditAddress(item.ID)"
                                v-if="item.Status == 2 || item.Status == 3 || item.Status == 4">修改用户地址</el-button>
                            <el-button size="small" @click="EditLogistic(item.ID)" v-if="item.Status == 5">修改发货信息
                            </el-button>
                            <el-button size="small" @click="UploadConsign(item.ID)" v-if="
                                item.Status == 6 &&
                                item.Status != 26 &&
                                item.Status != 27 &&
                                item.Status != 10 &&
                                item.Status != 13 &&
                                !item.ReceiveProof
                            ">上传签收证据</el-button>
                            <el-button size="small" @click="Receive(item.ID)" v-if="item.Status == 5">确认收货</el-button>
                            <!-- 
                  <el-button
                  size="small"
                  @click="ReReceive(item.ID)"
                  v-if="item.Status == 7"
                  >确认收货</el-button
                > 
                <el-button
                  size="small"
                  @click="Overdue(item.ID)"
                  v-if="item.Status == 11"
                  >转为逾期</el-button
                >-->
                            <el-button size="small" @click="Complete(item.ID, item)" v-if="
                                (item.Status == 8 ||
                                    item.Status == 15 ||
                                    item.Status == 22 ||
                                    item.Status == 6 ||
                                    item.Status == 7) &&
                                operator == '管理员' &&
                                ismerchant == 0
                            ">交易完成</el-button>
                            <!--<el-button size="small" @click="Mitigate(item.ID)" v-if="item.Status == 12">减免滞纳金</el-button>-->
                            <el-button size="small" @click="Offline(item, item.ID)" v-if="
                                item.Status == 6 || item.Status == 12 || item.Status == 24
                            ">填写归还物流</el-button>
                            <el-button size="small" @click="CheckReturn(item.ID)"
                                v-if="item.Status == 7 || item.Status == 20">查看归还物流</el-button>
                            <!--<el-button size="small" @click="GetLogisticDetail(item.RetLogistic.LogisticNo)" v-if="item.Status == 7 || item.Status == 20">查看归还物流</el-button>-->
                            <!--<el-button size="small" @click="CheckContract(item.ID)" disabled v-if="item.Status != 10">合同</el-button>-->
                            <el-button size="small" @click="EstimateInfo(item.ID)"
                                v-if="item.Status == 20 || item.Status == 8">填写检测报告</el-button>
                            <!--<el-button size="small" @click="EstimateInfo(item.ID)" v-if="item.Status == 8">填写赔付信息</el-button>-->
                            <!-- <el-button
                  size="small"
                  @click="ShowReport(index)"
                  v-if="item.Report.length > 0"
                  >查看检测报告
                </el-button> -->
                            <el-button size="small" @click="CheckContract(item.OrderUID, item.lease)" v-if="
                                ((item.Status >= 3 && item.Status < 10) ||
                                    (item.Status > 10 && item.Status < 13) ||
                                    (item.Status > 13 && item.Status < 26)) &&
                                item.wxtype != 1
                            ">合同</el-button>
                            <el-button size="small" @click="signcontract(item.FlowIds, item.isFlow)"
                                v-if="item.wxtype == 1 && item.isFlow == 0"
                                style="background-color: #b6b6b6; color: white">合同</el-button>
                            <el-button size="small" @click="signcontract(item.FlowIds, item.isFlow)"
                                v-if="item.wxtype == 1 && item.isFlow == 1"
                                style="background-color: green; color: white">合同</el-button>
                            <!-- <el-button v-show="item.Status != 10" size="small" @click="AddRemark(item.ID)">添加备注</el-button> -->
                            <el-button v-show="item.Status == 12" size="small" @click="relet(item)">续租</el-button>
                            <!--<el-button size="small" @click="Recover(item.ID)" v-if="item.Status == 17">恢复订单</el-button>-->
                        </div>
                    </div>
                    <div class="riskinfo-block">
                        <el-button icon="el-icon-edit-outline" type="text" class="editbtn"
                            @click="EditRiskMsg(item.ID, index)" v-if="ismerchant != 1"></el-button>
                        <div v-if="!item.RiskMsg && ismerchant != 1" style="display: inline">
                            <p v-if="
                                item.RiskEstimate && JSON.parse(item.RiskEstimate).msg == 'OK'
                            ">
                                <span>风控结果：{{
                                    JSON.parse(item.RiskEstimate).result
                                }}；风控分数：{{
                                        JSON.parse(item.RiskEstimate).score
                                    }}；风控策略：{{ JSON.parse(item.RiskEstimate).pdt }}</span>
                            </p>
                            <p v-if="!item.RiskEstimate">
                                <el-button size="small" type="primary"
                                    @click="handrisk(item.ID, item.fkMemberID, item)">蚁盾</el-button>
                            </p>
                        </div>
                        <div v-if="
                            (ismerchant == 1 && !item.RiskMsg) ||
                            (ismerchant == 1 && item.IsShow == 0)
                        " style="display: inline">
                            <p>风控信息：【未风控】 尚未生成风控信息,请等待</p>
                        </div>
                        <div v-if="
                            (ismerchant == 1 && item.IsShow == 1) ||
                            (ismerchant != 1 && item.RiskMsg)
                        " style="display: inline">
                            <p>蚁盾：{{ item.RiskMsg }}</p>
                        </div>
                    </div>
                    <div v-if="ismerchant != 1" class="riskinfo-block">
                        <p v-if="!item.rainrisk" style="margin-left: 10px">
                            <el-button size="small" type="primary"
                                @click="rainrisk(item.ID, item.LicenseID, item)">昆仑金盾</el-button>
                        </p>
                        <p v-if="item.rainrisk">
                            昆仑金盾：
                            <text v-if="item.rainrisk == 'A'"> 前科 </text>
                            <text v-if="item.rainrisk == 'B'"> 涉毒 </text>
                            <text v-if="item.rainrisk == 'C'"> 吸毒 </text>
                            <text v-if="item.rainrisk == 'D'"> 在逃 </text>
                            <text v-if="item.rainrisk == 'E'"> 涉稳、涉恐 </text>
                            <text v-if="item.rainrisk == 'F'"> 法院判决文书 </text>
                            <text v-if="item.rainrisk == '1'"> 其他风险人员 </text>
                            <text v-if="item.rainrisk == '0'"> 正常 </text>
                        </p>
                    </div>
                    <div class="riskinfo-block" style="color: #757575">
                        用户留言：{{ item.BuyerRemark }}
                    </div>
                    <div class="riskinfo-block" v-show="item.Status != 10" style="display: flex; color: #757575">
                        <div @click="AddRemark(item.ID, item)" v-if="ismerchant == 0" style="flex: 1">
                            卖家备注：{{ item.lastRemark }}
                        </div>
                        <!-- <div  v-if="ismerchant == 0" style="flex:1">卖家备注：{{ item.lastRemark }}</div> -->
                        <div v-if="ismerchant == 0" style="flex: 1">
                            <span v-if="item.uName">{{ item.uName }}</span>
                            <span v-else>暂无</span>
                            <span style="padding: 0 10px">{{ item.aTime }}</span>
                            <span style="border-bottom: 1px solid; cursor: pointer" v-if="item.jid != 0"
                                @click="viewNotes(item.ID)">
                                {{ item.jid }}条记录
                            </span>
                        </div>
                    </div>
                </div>
                <el-pagination @current-change="handleCurrentChange" @size-change="handleSizeChange"
                    :current-page="curpage" :page-size="pagesize" :page-sizes="[10, 20, 30, 50, 100]"
                    layout="sizes,total, prev, pager, next, jumper" :total="totalcount" :hide-on-single-page="false"
                    style="text-align: center"></el-pagination>
            </el-tab-pane>
        </el-tabs>
    </el-card>
    <el-dialog v-model="saveywy1" title="添加业务员" width="12%">
        <el-select v-model="ywyid" clearable placeholder="请选择业务员" filterable>
            <el-option v-for="item in ywyoptions" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="saveywy1 = false">取消</el-button>
                <el-button type="primary" @click="saveywy2"> 保存 </el-button>
            </span>
        </template>
    </el-dialog>
    <!--切换店铺-->
    <el-dialog v-model="dialog" title="更改店铺" width="12%">
        <el-select v-model="vv" placeholder="请选择/输入更改的店铺" multiple-limit="1" filterable clearable remote reserve-keyword
            remote-show-suffix :remote-method="remoteMethod">
            <el-option v-for="item in parts" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="dialog = false">取消</el-button>
                <el-button type="primary" @click="savedp"> 保存 </el-button>
            </span>
        </template>
    </el-dialog>
    <!--交易完成-->
    <el-dialog v-model="accomplish" title="交易完成" width="20%">
        <el-radio-group v-model="wanchengstatus" class="ml-4">
            <el-radio label="9" size="large">归还</el-radio>
            <el-radio label="21" size="large">买断</el-radio>
        </el-radio-group>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="accomplish = false">取消</el-button>
                <el-button type="primary" @click="wancheng"> 确认 </el-button>
            </span>
        </template>
    </el-dialog>
    <!--驳回-->
    <el-dialog v-model="bohui2" title="驳回" width="20%" align-center>
        <el-radio-group v-model="bohui3" class="ml-4">
            <el-radio label="1" size="small">已与客户协商一致</el-radio>
        </el-radio-group>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="bohui2 = false">取消</el-button>
                <el-button type="primary" @click="bohui4"> 确认 </el-button>
            </span>
        </template>
    </el-dialog>
    <!--续租-->
    <el-dialog v-model="xuzu" title="续租" width="30%" align-center>
        <el-container>
            <el-main>
                <el-row>
                    <el-col :span="24" style="
              border-left: 3px solid #409eff;
              padding-left: 5px;
              font-weight: bold;
            ">基本信息</el-col>
                </el-row>
                <el-row style="margin-top: 10px">
                    <el-col :span="4">商品名称：</el-col>
                    <el-col :span="20">{{ Object1.PdtName }}</el-col>
                </el-row>
                <el-row style="margin-top: 10px">
                    <el-col :span="4">数量：</el-col>
                    <el-col :span="20">{{ Object1.OrderCount }}</el-col>
                </el-row>
                <el-row style="margin-top: 10px">
                    <el-col :span="4">套餐名称：</el-col>
                    <el-col :span="20">{{ Object1.PlanTitle }}</el-col>
                </el-row>
                <el-row style="margin-top: 10px">
                    <el-col :span="4">工单类型：</el-col>
                    <el-col :span="20">申请续租</el-col>
                </el-row>
                <el-row style="margin-top: 10px">
                    <el-col :span="4">订单ID：</el-col>
                    <el-col :span="20">{{ Object1.OrderUID }}</el-col>
                </el-row>
                <el-row style="margin-top: 10px">
                    <el-col :span="4">订单租金：</el-col>
                    <el-col :span="20">{{ Object1.TotalOrderMoney }}元</el-col>
                </el-row>
                <el-row style="margin-top: 10px">
                    <el-col :span="4">套餐押金：</el-col>
                    <el-col :span="20">{{ Object1.iDeposit }}元</el-col>
                </el-row>
                <el-row style="margin-top: 10px">
                    <el-col :span="4">租期：</el-col>
                    <el-col :span="20">{{ formatDay(Object1.ResignDTime) }}～{{
                        formatDay(Object1.ReturnDTime)
                    }}</el-col>
                </el-row>
                <el-row>
                    <el-col :span="24" style="
              margin-top: 10px;
              border-left: 3px solid #409eff;
              padding-left: 5px;
              font-weight: bold;
            ">申请续租</el-col>
                </el-row>
                <el-row style="margin-top: 10px; display: flex; align-items: center">
                    <el-col :span="4">续租租期：</el-col>
                    <el-col :span="15">{{ formatDay(Object1.ReturnDTime) }}~
                        <el-date-picker v-model="xuzutime" type="date" placeholder="请选择一个日期" format="YYYY-MM-DD"
                            value-format="x" :disabled-date="disabledDate(formatDay(Object1.ReturnDTime))"
                            size="small" />
                    </el-col>
                    <el-col :span="5" v-if="xuzu2">(共续租{{ xuzu2 }}天)</el-col>
                </el-row>
                <el-row style="margin-top: 10px; display: flex; align-items: center">
                    <el-col :span="4">续租租金：</el-col>
                    <el-col :span="20">总租金<el-input-number v-model="rentmoney" :precision="2" :step="0.1" size="small"
                            controls-position="right" />元(总租金=原租金/用户租期x续租天数x续租比例)</el-col>
                </el-row>
                <el-row>
                    <el-col :span="24" style="
              margin-top: 10px;
              border-left: 3px solid #409eff;
              padding-left: 5px;
              font-weight: bold;
            ">问题描述</el-col>
                </el-row>
                <el-row>
                    <el-col :span="24" style="padding: 10px 0">我要反馈</el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-input v-model="textarea" :rows="2" type="textarea" placeholder="输入续租申请内容" />
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24" style="padding: 10px 0">上传凭证：</el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-upload drag :action="upload_url" :on-success="xuzuupload" multiple>
                            <div class="el-upload__text">
                                把文件拖拽到此处或 <em>点击此处</em>
                            </div>
                            <template #tip>
                                <div class="el-upload__tip">
                                    支持上传jpg、png, 图片大小建议2M以内
                                </div>
                            </template>
                        </el-upload>
                    </el-col>
                </el-row>
            </el-main>
        </el-container>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="xuzu = false">取消</el-button>
                <el-button type="primary" @click="reletfunction"> 确认 </el-button>
            </span>
        </template>
    </el-dialog>
    <el-dialog v-model="ht" title="合同" width="21%" :before-close="handleClose5" center>
        <el-row>
            <el-col :span="12" style="text-align: center; border: 1px solid #bcbdc1; padding: 6px 0">合同</el-col>
            <el-col :span="12" style="
          text-align: center;
          border: 1px solid #bcbdc1;
          padding: 6px 0;
          color: #409eff;
          cursor: pointer;
        " @click="jumpurl(1)">查看合同</el-col>
        </el-row>
        <el-row>
            <el-col :span="12" style="text-align: center; border: 1px solid #bcbdc1; padding: 6px 0">存证</el-col>
            <el-col :span="12" style="
          text-align: center;
          border: 1px solid #bcbdc1;
          padding: 6px 0;
          color: #409eff;
          cursor: pointer;
        " @click="jumpurl(2)">验签报告</el-col>
        </el-row>
        <template #footer>
            <span class="dialog-footer">
                <el-button type="primary" @click="ht = false">确认</el-button>
            </span>
        </template>
    </el-dialog>
    <el-dialog v-model="depositinfo" title="押金信息" width="20%">
        <div style="padding: 10px">套餐押金：{{ deposit }}</div>
        <div style="padding: 10px">
            免押额度：{{ plan_sdeposit + plan_rdeposit + zhimadecrease }}
        </div>
        <div style="padding: 10px">补押金：{{ plan_replenish }}</div>
        <div style="padding: 10px">已冻结押金：{{ plan_depositpaid }}</div>
        <div style="padding: 10px" v-if="
            cur_order_status == 9 ||
            cur_order_status == 10 ||
            cur_order_status == 21 ||
            cur_order_status == 13
        ">
            已解冻押金：{{ restor_deposit }}
        </div>
        <div style="padding: 10px" v-else>已解冻押金：0</div>
    </el-dialog>
    <el-dialog v-model="ev3" title="查看证据" width="40%">
        <template v-for="(item, index) in viewevidence" v-bind:key="index">
            <el-row>
                <el-col :span="6" style="display: flex">
                    <div class="rad">{{ item.i }}</div>
                    &nbsp;&nbsp;&nbsp;
                    <div>{{ options[item.value]["label"] }}</div>
                </el-col>
                <el-col :span="4" style="display: flex"><span style="color: #aaaaaa">存证人:</span>{{ item.adduser }}
                </el-col>
                <el-col :span="7" style="display: flex"><span style="color: #aaaaaa">存证时间:</span>{{ item.addtime }}
                </el-col>
                <el-col :span="7" style="display: flex"><span style="color: #aaaaaa">存证类型:</span>{{
                    options2[item.value2]["label"] }} &nbsp;&nbsp;<button style="
              background-color: #409eff;
              color: white;
              border: 2px solid #409eff;
              border-radius: 6px;
              cursor: pointer;
            " @click="Export(item.id, item.imgurl)">
                        导出数据
                    </button></el-col>
            </el-row>
            <el-row v-for="(item3, index3) in item.newimgurl" v-bind:key="index3"
                style="margin-left: 40px; margin-top: 10px">
                <el-image v-if="item3" :src="item3" style="width: 100px; height: 100px"></el-image>
            </el-row>
            <el-divider />
        </template>
        <template #footer>
            <span class="dialog-footer">
                <el-button type="primary" style="float: left" prop="viewev1" @click="exportall">全部导出</el-button>
                <el-button @click="ev3 = false">关闭</el-button>
            </span>
        </template>
    </el-dialog>
    <el-dialog v-model="centerDialogVisible" title="备注详情" width="30%" center>
        <el-row>
            <el-col :span="6">
                <div class="grid-content ep-bg-purple" />
                备注详情
            </el-col>
            <el-col :span="9">
                <div class="grid-content ep-bg-purple" />
                备注时间
            </el-col>
            <el-col :span="3">
                <div class="grid-content ep-bg-purple" />
                备注人
            </el-col>
            <el-col :span="6">
                <div class="grid-content ep-bg-purple" />
                附件信息
            </el-col>
        </el-row>
        <template style="height: 250px; width: 100%" v-for="(item, index) in tableData" v-bind:key="index">
            <el-row :gutter="20" style="margin-top: 20px">
                <el-col :span="6">
                    <div class="grid-content ep-bg-purple" />
                    {{ item.info }}
                </el-col>
                <el-col :span="9">
                    <div class="grid-content ep-bg-purple" />
                    {{ item.addtime }}
                </el-col>
                <el-col :span="3">
                    <div class="grid-content ep-bg-purple" />
                    {{ item.username }}
                </el-col>
                <el-col :span="6" v-for="(item2, index2) in item.imgurlArray" v-bind:key="index2">
                    <div class="grid-content ep-bg-purple" />
                    <el-image v-if="item2" :src="item2" :preview-src-list="item.imgurlArray">
                    </el-image>
                </el-col>
            </el-row>
        </template>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="centerDialogVisible = false">关闭</el-button>
            </span>
        </template>
    </el-dialog>
    <el-dialog v-model="evidence" title="录入证据" width="30%">
        <el-row :gutter="20">
            <el-col :span="12" style="font-size: large; text-align: center">
                <h1>证据选项：</h1>
            </el-col>
            <el-col :span="12">
                <el-select v-model="value" filterable style="margin-top: 16px" @change="ev1" placeholder="请选择">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
            </el-col>
            <el-input v-if="ev" type="text" v-model="ev2" placeholder="请输入自定义存证名称"></el-input>
        </el-row>
        <el-divider />
        <el-row :gutter="20">
            <el-col :span="12" style="font-size: large; text-align: center">
                <h1>证据录入类型：</h1>
            </el-col>
            <el-col :span="12">
                <el-select v-model="value2" filterable @change="tipSelect" style="margin-top: 16px" placeholder="请选择">
                    <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
                <p>{{ tip }}</p>
            </el-col>
        </el-row>
        <el-divider />
        <el-row style="margin-top: 15px">
            <el-upload :action="uploadfile" :on-success="UploadSuccess3" :on-change="compress" :on-error="UploadError3"
                :on-remove="RemovePic3" :on-preview="Preview3" multiple :file-list="FileList3"
                :before-upload="beforeAvatarUpload">
                <el-button type="primary">上传文件</el-button>
            </el-upload>
        </el-row>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="evidence = false">取消</el-button>
                <el-button @click="saveEvidence" type="primary">保存</el-button>
            </span>
        </template>
    </el-dialog>
    <el-dialog v-model="cancelorder" width="30%" title="取消订单" center>
        <div style="margin-bottom: 25px; border-bottom: 1px solid #dcdfe6"></div>
        <el-form :model="cancelinfo" label-width="150px" ref="cancelinfo" :rules="cancelrule">
            <el-form-item label="取消类型" required prop="type">
                <el-select v-model="cancelinfo.type" placeholder="请选择取消原因">
                    <el-option label="风控审核不通过" value="1"></el-option>
                    <el-option label="客户要求取消" value="2"></el-option>
                    <el-option label="缺货" value="3"></el-option>
                    <el-option label="无法联系到客户" value="4"></el-option>
                    <el-option label="客户不愿补押金" value="5"></el-option>
                    <el-option label="用户拒收" value="6"></el-option>
                    <el-option label="其他" value="7"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="取消原因">
                <el-input type="textarea" v-model="cancelinfo.reason" :autosize="{ minRows: 2, maxRows: 4 }"></el-input>
            </el-form-item>
            <!-- <el-form-item label="是否扣除用户违约金">
        <el-radio-group v-model="cancelinfo.haspenalty">
          <el-radio :label="1">是</el-radio>
          <el-radio :label="2">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="已付金额" v-if="cancelinfo.haspenalty == 1">
        <span>{{ cancelinfo.paymoney }}元</span>
      </el-form-item>
      <el-form-item label="扣除违约金" v-if="cancelinfo.haspenalty == 1">
        <el-input-number
          v-model="cancelinfo.penalty"
          :min="0"
          controls-position="right"
          :controls="false"
        >
        </el-input-number>
      </el-form-item> -->
        </el-form>
        <template #footer>
            <el-button size="small" type="primary" @click="SaveCancel">确定</el-button>
            <el-button size="small" @click="CloseCancel">取消</el-button>
        </template>
    </el-dialog>
    <el-dialog v-model="logistic" center :title="title" width="63%" @close="CloseLogistic">
        <div style="margin-bottom: 25px; border-bottom: 1px solid #dcdfe6"></div>
        <el-table :data="logisticinfo.logisticdata" size="small">
            <el-table-column label="序号" width="80px" align="center">
                <template #default="scope">
                    <span>{{ scope.$index + 1 }}</span>
                </template>
            </el-table-column>
            <el-table-column label="IMEI/SN" prop="UID" width="300px" align="center">
                <template #default="scope">
                    <el-input v-model="scope.row.UID"></el-input>
                </template>
            </el-table-column>
            <el-table-column label="数量" prop="ShippingCount" width="120px" align="center">
                <template #default="scope">
                    <el-input-number v-model="scope.row.ShippingCount" :controls="false" style="width: 100px">
                    </el-input-number>
                </template>
            </el-table-column>
            <el-table-column label="快递公司" prop="LogisticCompany" width="250px" align="center">
                <template #default="scope">
                    <el-select v-model="scope.row.LogisticCompany" @change="Changetip($event)" placeholder="请选择快递公司"
                        clearable>
                        <el-option v-for="item in logcom" :key="item" :label="item.Name" :value="item.ID"></el-option>
                    </el-select>
                </template>
            </el-table-column>
            <el-table-column label="物流单号" prop="LogisticNo" align="center">
                <template #default="scope">
                    <el-input style="width: 200px" v-model="scope.row.LogisticNo"></el-input>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="100px" align="center">
                <template #default="scope">
                    <el-button v-if="scope.$index > 0" @click="Del(scope.$index)" size="small" type="danger">删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <div style="
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
      ">
            <div>归还地址：</div>
            <el-select v-model="logisticinfo.returnid" style="width: 90%">
                <el-option v-for="item in returnid" :key="item" :value="item.ID" :label="item.ReturnAddress">
                </el-option>
            </el-select>
        </div>
        <p style="margin-left: 20px; color: #aaaaaa" v-if="hint">
            提示：【上门自取】无需填写快递单号，可在【录入证据】处保存相关证据。
        </p>
        <template #footer>
            <!-- <span v-if="hetong" style="margin-right:1rem;">
        区块链合同生成中，请稍等几秒...
        <el-button size="small" type="primary" disabled>确定</el-button>
      </span> -->
            <el-button size="small" type="primary" @click="Add">新增</el-button>
            <el-button size="small" type="success" @click="SaveLogistic">{{
                savelog
            }}</el-button>
            <el-button size="small" @click="CloseLogistic">取消</el-button>
        </template>
    </el-dialog>
    <el-dialog v-model="customeraddr" center title="用户地址" width="40%">
        <el-form :model="addrinfo" label-width="100px" ref="addrinfo">
            <el-form-item label="客户名">
                <el-input :disabled="true" v-model="addrinfo.Receiver"></el-input>
            </el-form-item>
            <el-form-item label="联系方式">
                <el-input v-model="addrinfo.Mobile"></el-input>
            </el-form-item>
            <el-form-item label="地区">
                <el-select v-model="addrinfo.fkAreaIDL1" style="width: 160px; margin-right: 10px" @change="ResetCity"
                    placeholder="请选择">
                    <el-option v-for="item in provinces" :key="item" :value="item.region_id" :label="item.region_name">
                    </el-option>
                </el-select>
                <el-select v-model="addrinfo.fkAreaIDL2" style="width: 160px; margin-right: 10px" @change="ResetArea"
                    placeholder="请选择">
                    <el-option v-for="item in citys" :key="item" :value="item.region_id" :label="item.region_name">
                    </el-option>
                </el-select>
                <el-select v-model="addrinfo.fkAreaIDL3" style="width: 160px">
                    <el-option v-for="item in areas" :key="item" :value="item.region_id" :label="item.region_name">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="详细地址">
                <el-input v-model="addrinfo.Address"></el-input>
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button size="small" type="primary" @click="SaveAddr">确定</el-button>
            <el-button size="small" @click="CloseAddr">取消</el-button>
        </template>
    </el-dialog>
    <el-dialog v-model="receipt" title="商家交付确认单" center>
        <el-table :data="receiptdata" border style="width: 100%; margin: 1em 0">
            <el-table-column label="序号">
                <template #default="scope">
                    <span>{{ scope.$index + 1 }}</span>
                </template>
            </el-table-column>
            <el-table-column label="IMEI/SN" prop="UID"></el-table-column>
            <el-table-column label="物流单号" prop="LogisticNo"></el-table-column>
            <el-table-column label="操作">
                <template #default="scope">
                    <el-link type="primary" :href="scope.row.Receipt" target="_blank">商家交付确认单下载</el-link>
                </template>
            </el-table-column>
        </el-table>
    </el-dialog>
    <el-dialog v-model="addition" title="补订单" center width="50%">
        <el-form :model="additioninfo" label-width="150px" ref="additioninfo">
            <el-form-item label="用途" prop="type" :rules="{
                required: true,
                message: '需要选择补订单类型',
                trigger: 'blur',
            }">
                <el-select v-model="additioninfo.type" placeholder="请选择" size="small">
                    <el-option value="4" label="买断金" v-if="
                        additioninfo.status == 6 ||
                        additioninfo.status == 7 ||
                        additioninfo.status == 8
                    "></el-option>
                    <el-option value="7" label="押金" v-if="additioninfo.status == 2"></el-option>
                    <!--<el-option value="5" label="赔偿金" v-if="additioninfo.status == 8"></el-option>-->
                </el-select>
            </el-form-item>
            <el-form-item label="未还金额" v-if="additioninfo.type == 4">
                <span>{{ additioninfo.needbuyoutprice }}</span>
            </el-form-item>
            <el-form-item label="金额">
                <el-input-number v-model="additioninfo.money" :controls="false" size="small"></el-input-number><br />
                <!--<el-tag type="danger" size="small">注意：补款金额不可超过</el-tag>-->
            </el-form-item>
            <el-form-item label="赔款证明" v-if="additioninfo.type == 5">
                <el-upload class="avatar-uploader" :action="upload_url" list-type="picture-card"
                    :on-success="UploadSuccess" :on-change="UploadChange" :on-error="UploadError" :on-remove="RemovePic"
                    :on-preview="Preview" multiple :limit="5" :on-exceed="UploadExceed" :file-list="FileList">
                    <i class="el-icon-plus"></i>
                    <template #tip>
                        <div class="el-upload__tip">
                            (支持5张,格式为:jpg/png/jpeg/bmp,每张不超过10M)
                        </div>
                    </template>
                </el-upload>
                <el-dialog v-model="dialogVisible">
                    <img style="width: 100%" :src="dialogImageUrl" alt="" />
                </el-dialog>
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button size="small" type="primary" @click="SaveAddition">确定</el-button>
            <el-button size="small" @click="CloseAddition">取消</el-button>
        </template>
    </el-dialog>
    <el-dialog v-model="offline" width="25%" title="填写归还物流" center @close="CloseOffline">
        <el-form :model="offlineinfo" label-width="100px" ref="offlineinfo">
            <el-form-item label="归还类型:" prop="returntype"
                :rules="{ required: true, message: '请选择归还类型', trigger: 'blur' }">
                <el-select v-model="offlineinfo.returntype" placeholder="请选择">
                    <el-option value="2" label="客户归还"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="快递公司:" prop="shippingcom"
                :rules="{ required: true, message: '请选择快递公司', trigger: 'blur' }">
                <el-select v-model="offlineinfo.shippingcom" placeholder="请选择">
                    <el-option v-for="item in logcom" :key="item" :value="item.ID" :label="item.Name"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="快递单号:" prop="shippingno"
                :rules="{ required: true, message: '请填写快递单号', trigger: 'blur' }">
                <el-input v-model="offlineinfo.shippingno"></el-input>
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button size="small" type="primary" @click="SaveOffline">确定</el-button>
            <el-button size="small" @click="CloseOffline">取消</el-button>
        </template>
    </el-dialog>
    <el-dialog v-model="logisticdetail" title="物流详情" center>
        <div>物流公司：{{ expName }}</div>
        <div>物流单号：{{ danhao }}</div>
        <el-table :data="logdetail" style="width: 100%; margin: 1em 0" border>
            <el-table-column label="时间" prop="time" width="200px"></el-table-column>
            <el-table-column label="信息" prop="status"></el-table-column>
        </el-table>
    </el-dialog>
    <el-dialog v-model="prepay" title="审核" @close="ClosePrepay">
        <el-form :model="prepayinfo" label-width="150px">
            <el-form-item label="审核：">
                <el-radio-group v-model="prepayinfo.result" size="small">
                    <el-radio-button label="1" @change="getIdentityId($event)">通过</el-radio-button>
                    <el-radio-button label="2">不通过</el-radio-button>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="拒绝原因：" v-if="prepayinfo.result == 2">
                <el-input type="textarea" v-model="prepayinfo.reason"></el-input>
            </el-form-item>
            <div v-if="prepayinfo.result == 1">
                <!-- <el-form-item label="补押金">
          <el-input-number
            v-model="prepayinfo.replenish"
            :controls="false"
            :min="0"
            size="small"
          >
          </el-input-number>
        </el-form-item> -->
                <el-form-item label="是否预期收款:" v-if="prepayinfo.result == 1">
                    <el-radio-group v-model="prepayinfo.isprepay" size="small">
                        <el-radio-button label="1">是</el-radio-button>
                        <el-radio-button label="2">否</el-radio-button>
                    </el-radio-group>
                </el-form-item>
                <el-form-item v-if="prepayinfo.isprepay == 1" label="选择预收款期数">
                    <el-checkbox-group v-model="prepayinfo.pbino" @change="CheckSel">
                        <el-checkbox v-for="rentno in rentorder" :key="rentno" :label="rentno.ID"
                            :checked="rentno.RentOrder == 1 ? true : false"
                            :disabled="rentno.RentOrder == 1 ? true : false">{{ rentno.RentOrder }}期</el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item v-if="prepayinfo.tip">
                    <span style="color: red; font-size: 20px">注意：该用户未上传身份证，请提醒客户在【订单详情】页面上传
                        <el-popover placement="top" title="说明" :width="200" trigger="hover"
                            content="搜索【闲牛租】-【我的】-【待审核】订单-点击订单-【订单详情】-最下方上传身份证按钮">
                            <template #reference>
                                <el-image :src="question" style="top: 24px"></el-image>
                            </template>
                        </el-popover>
                    </span>
                    <br />
                    <span>另：若客户暂不方便上传，系统支持审核时或审核通过后在商户端进行上传；</span>
                </el-form-item>
            </div>
        </el-form>
        <template #footer>
            <el-button size="small" type="primary" @click="SavePrepay">确定</el-button>
            <el-button size="small" @click="ClosePrepay">取消</el-button>
        </template>
    </el-dialog>
    <!--补押金-->
    <el-dialog v-model="deposit2" title="补押金" width="30%" :before-close="deposit3">
        <el-row>
            <el-col :span="4">序号</el-col>
            <el-col :span="4">金额</el-col>
            <el-col :span="6">付款时间</el-col>
            <el-col :span="10">操作</el-col>
        </el-row>
        <el-row v-for="(item, index) in deposit4" :key="index">
            <el-col :span="4">{{ index + 1 }}</el-col>
            <el-col :span="4">{{ item.money }}元</el-col>
            <el-col :span="6">{{ item.addtime }}</el-col>
            <el-col :span="10">
                <el-dropdown v-if="
                    (ismerchant == 0 && item.status == 1) ||
                    (ismerchant == 0 && item.status == 3)
                " style="width: 50%" trigger="click" placement="right" size="small">
                    <el-button size="small">退款</el-button>
                    <template #dropdown>
                        <el-dropdown-menu>
                            <el-dropdown-item @click="tuikuan(item.id, item.money, 1)">退全款</el-dropdown-item>
                            <el-dropdown-item @click="tuikuan(item.id, item.money, 2)">退部分</el-dropdown-item>
                        </el-dropdown-menu>
                    </template>
                </el-dropdown>
                <el-button size="small" v-if="ismerchant == 0 && item.status == 1">完结</el-button>
                <span v-if="item.status == 2">已退款</span>
                <span v-else-if="item.status == 3">部分退款({{ item.money }}元)</span>
            </el-col>
        </el-row>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="deposit2 = false">关闭</el-button>
            </span>
        </template>
    </el-dialog>
    <el-dialog v-model="report" title="检测报告" center width="70%">
        <el-form :model="estimateinfo" label-width="100px" :inline="true" :label-position="top">
            <el-card>
                <template #header>
                    <div class="clearfix" style="text-align: left">
                        <span>订单信息</span>
                    </div>
                </template>
                <div>
                    <el-form-item label="订单号">
                        <el-input v-model="estimateinfo.OrderUID" :disabled="true"></el-input>
                    </el-form-item>
                    <el-form-item label="姓名">
                        <el-input v-model="estimateinfo.NickName" :disabled="true"></el-input>
                    </el-form-item>
                    <el-form-item label="身份证号">
                        <el-input v-model="estimateinfo.LicenseID" :disabled="true"></el-input>
                    </el-form-item>
                    <el-form-item label="联系电话">
                        <el-input v-model="estimateinfo.Mobile" :disabled="true"></el-input>
                    </el-form-item>
                    <el-form-item label="租期">
                        <el-input v-model="estimateinfo.RentDay" :disabled="true"></el-input>
                    </el-form-item>
                    <el-form-item label="订单状态">
                        <el-select v-model="estimateinfo.Status" :disabled="true">
                            <el-option label="待归还" value="6"></el-option>
                            <el-option label="定损中" value="8"></el-option>
                            <el-option label="返修中" value="20"></el-option>
                            <el-option label="已完成" value="9"></el-option>
                            <el-option label="强制买断" value="23"></el-option>
                            <el-option label="买断中" value="25"></el-option>
                        </el-select>
                    </el-form-item>
                </div>
            </el-card>
            <el-card style="margin-top: 10px">
                <template #header>
                    <div class="clearfix" style="text-align: left">
                        <span>租赁物信息</span>
                    </div>
                </template>
                <div>
                    <el-form-item label="品类">
                        <el-input v-model="estimateinfo.Category" :disabled="true"></el-input>
                    </el-form-item>
                    <el-form-item label="品牌">
                        <el-input v-model="estimateinfo.Brand" :disabled="true"></el-input>
                    </el-form-item>
                    <el-form-item label="型号">
                        <el-input v-model="estimateinfo.Model" :disabled="true"></el-input>
                    </el-form-item>
                    <el-form-item label="商品名">
                        <el-input v-model="estimateinfo.PdtName" :disabled="true"></el-input>
                    </el-form-item>
                    <el-form-item label="套餐标题">
                        <el-input v-model="estimateinfo.PlanTitle" :disabled="true"></el-input>
                    </el-form-item>
                    <el-form-item label="规格参数">
                        <el-input v-model="estimateinfo.Params" :disabled="true"></el-input>
                    </el-form-item>
                    <el-form-item label="标识码">
                        <el-input v-model="estimateinfo.UID" :disabled="true"></el-input>
                    </el-form-item>
                    <el-form-item label="物流单号">
                        <el-input v-model="estimateinfo.LogisticNo" :disabled="true"></el-input>
                    </el-form-item>
                </div>
            </el-card>
            <el-card style="margin-top: 10px">
                <template #header>
                    <div class="clearfix" style="text-align: left">
                        <span>检测信息</span>
                    </div>
                </template>
                <div>
                    <el-form-item label="检测类型" prop="TestType">
                        <el-select v-model="estimateinfo.TestType" placeholder="请选择检测类型">
                            <el-option value="1" label="设备返修"></el-option>
                            <el-option value="2" label="归还定损"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="检测时间">
                        <el-date-picker type="datetime" v-model="estimateinfo.AddDTime"></el-date-picker>
                    </el-form-item>
                    <el-form-item label="机器状态">
                        <el-select v-model="estimateinfo.PdtStatus" clearable placeholder="请选择">
                            <el-option value="1" label="完美"></el-option>
                            <el-option value="2" label="异常"></el-option>
                            <el-option value="3" label="非人为损坏"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="损坏类型" v-if="estimateinfo.PdtStatus != 1">
                        <el-select v-model="estimateinfo.DamageType" clearable placeholder="请选择">
                            <!--1指纹功能异常 2机器异常 3配件缺失或损失 4iCloud账户无法注销 5机器弯曲变形 6机器功能不正常 7无法正常开机 8屏幕碎裂，划痕等 9后盖碎裂，划痕等 10其他-->
                            <el-option value="1" label="指纹功能异常"></el-option>
                            <el-option value="2" label="机器异常"></el-option>
                            <el-option value="3" label="配件缺失或损失"></el-option>
                            <el-option value="4" label="iCloud账户无法注销"></el-option>
                            <el-option value="5" label="机器弯曲变形"></el-option>
                            <el-option value="6" label="机器功能不正常"></el-option>
                            <el-option value="7" label="无法正常开机"></el-option>
                            <el-option value="8" label="屏幕碎裂，划痕等"></el-option>
                            <el-option value="9" label="后盖碎裂，划痕等"></el-option>
                            <el-option value="10" label="其他"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="描述说明">
                        <el-input v-model="estimateinfo.Remark" type="textarea" :rows="4" style="width: 600px">
                        </el-input>
                    </el-form-item>
                </div>
            </el-card>
            <el-card style="margin-top: 10px">
                <template #header>
                    <div class="clearfix" style="text-align: left">
                        <span>检测证明</span>
                    </div>
                </template>
                <div>
                    <el-form-item>
                        <el-upload class="avatar-uploader" :action="upload_url" list-type="picture-card"
                            :on-success="UploadSuccess" :on-change="UploadChange" :on-error="UploadError"
                            :on-remove="RemovePic" :on-preview="Preview" multiple :limit="5" :on-exceed="UploadExceed"
                            :file-list="FileList">
                            <i class="el-icon-plus"></i>
                            <template #tip>
                                <div class="el-upload__tip">
                                    (支持5张,格式为:jpg/png/jpeg/bmp,每张不超过10M)
                                </div>
                            </template>
                        </el-upload>
                        <el-dialog v-model="dialogVisible">
                            <img style="width: 100%" :src="dialogImageUrl" alt="" />
                        </el-dialog>
                    </el-form-item>
                </div>
            </el-card>
            <el-card style="margin-top: 10px">
                <template #header>
                    <div class="clearfix" style="text-align: left">
                        <span>赔付详情</span>
                    </div>
                </template>
                <div>
                    <el-form-item label="赔偿总金额">
                        <el-input-number :controls="false" v-model="estimateinfo.TotalCompensation"></el-input-number>
                    </el-form-item>
                    <el-form-item label="减免金额">
                        <el-input-number :controls="false" v-model="estimateinfo.CompensationDecrease">
                        </el-input-number>
                    </el-form-item>
                    <el-form-item label="赔偿应付金额">
                        <el-input-number :controls="false" v-model="estimateinfo.Compensation"></el-input-number>
                    </el-form-item>
                    <el-form-item label="买断金额">
                        <el-input-number :controls="false" v-model="estimateinfo.ReferBuyoutPrice"></el-input-number>
                    </el-form-item>
                </div>
            </el-card>
        </el-form>
        <template #footer>
            <el-button size="small" type="primary" @click="SaveEstimate" v-if="
                estimateinfo.Status == 6 ||
                estimateinfo.Status == 8 ||
                estimateinfo.Status == 20
            ">确定</el-button>
            <el-button size="small" @click="CloseEstimate">取消</el-button>
        </template>
    </el-dialog>
    <el-dialog v-model="addremark" title="卖家备注" width="30%">
        <el-input v-model="sremark" type="textarea" :rows="4" placeholder="请输入备注"></el-input>
        <!-- <el-row style="margin-top: 15px">
      <span class="ml-3 w-35 text-gray-600 inline-flex items-center"
        >附件：</span
      >
      <el-upload
        :action="upload_url"
        list-type="picture-card"
        :on-success="UploadSuccess2"
        :on-change="UploadChange2"
        :on-error="UploadError"
        :on-remove="RemovePic2"
        :on-preview="Preview2"
        multiple
        :file-list="FileList2"
      >
        <el-button type="primary">选择文件</el-button>
        <template #tip>
          <div class="el-upload__tip">
            注：支持JPG、JPEG、PNG、PDF（支持上传多张）
          </div>
        </template>
      </el-upload>
    </el-row> -->
        <template #footer>
            <el-button size="small" type="primary" @click="SaveRemark">确定</el-button>
            <el-button size="small" @click="CloseRemark">取消</el-button>
        </template>
    </el-dialog>
    <el-dialog v-model="returnlog" title="归还物流" width="50%">
        <el-table :data="returnlogtable" border size="small">
            <el-table-column label="物流公司" prop="Name"></el-table-column>
            <el-table-column label="物流单号" prop="LogisticNo"></el-table-column>
            <el-table-column label="寄出时间">
                <template #default="scope">
                    <span>{{ formatDate(scope.row.AddDTime) }}</span>
                </template>
            </el-table-column>
            <el-table-column label="类型">
                <template #default="scope">
                    <span v-if="scope.row.Type == 2">返修寄回</span>
                    <span v-if="scope.row.Type == 4">客户归还</span>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="100px">
                <template #default="scope">
                    <el-button type="text" @click="GetLogisticDetail(scope.row.LogisticNo)">查看</el-button>
                </template>
            </el-table-column>
        </el-table>
    </el-dialog>
    <el-dialog v-model="checkreport" title="检测报告" width="30%">
        <el-table :data="curreport" border size="small">
            <el-table-column label="报告类型">
                <template #default="scope">
                    <span v-if="scope.row.Type == 1">返修检测报告</span>
                    <span v-if="scope.row.Type == 2">定损检测报告</span>
                </template>
            </el-table-column>
            <el-table-column label="提交时间">
                <template #default="scope">
                    <span>{{ formatDate(scope.row.AddDTime) }}</span>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="80px">
                <template #default="scope">
                    <el-button @click="CheckReport(scope.row.ID)" type="text" size="small">查看</el-button>
                </template>
            </el-table-column>
        </el-table>
    </el-dialog>
    <el-dialog v-model="oplogdialog" title="操作日志">
        <el-table :data="oplogdata" border size="small">
            <el-table-column label="日志" prop="Remark"></el-table-column>
            <el-table-column label="操作时间" prop="AddDTime">
                <template #default="scope">
                    <span>{{ formatDate(scope.row.AddDTime) }}</span>
                </template>
            </el-table-column>
            <el-table-column label="操作人" prop="OpName"></el-table-column>
        </el-table>
    </el-dialog>
    <el-dialog v-model="confirmreceive" title="选择收货时间" width="500px">
        <el-date-picker type="datetime" v-model="receiveday" placeholder="选择收货时间" style="width: 460px"></el-date-picker>
        <template #footer>
            <el-button size="small" type="primary" @click="SaveReceiveDay">确定</el-button>
            <el-button size="small" @click="CloseReceiveDay">取消</el-button>
        </template>
    </el-dialog>
    <el-dialog v-model="merchant" title="选择商户" center>
        <div class="merchant-block">
            <el-transfer v-model="selmerchant" filterable filter-placeholder="请输入商户名称" :data="merchantlist"
                :titles="transfertitle">
            </el-transfer>
            <div class="bottom">
                <el-button type="primary" size="small" @click="ConfirmMerchant">确定</el-button>
                <el-button size="small" @click="Back">取消</el-button>
            </div>
        </div>
    </el-dialog>
    <el-dialog v-model="agent" title="选择代理商" center>
        <div class="merchant-block">
            <el-transfer v-model="selagent" filterable filter-placeholder="请输入商户名称" :data="merchantlist2"
                :titles="transfertitle2">
            </el-transfer>
            <div class="bottom">
                <el-button type="primary" size="small" @click="ConfirmMerchant2">确定</el-button>
                <el-button size="small" @click="Back2">取消</el-button>
            </div>
        </div>
    </el-dialog>
    <el-dialog v-model="partner" title="选择分销商" center>
        <div class="merchant-block">
            <el-transfer v-model="selpartner" filterable filter-placeholder="请输入商户名称" :data="merchantlist3"
                :titles="transfertitle3">
            </el-transfer>
            <div class="bottom">
                <el-button type="primary" size="small" @click="ConfirmMerchant3">确定</el-button>
                <el-button size="small" @click="Back3">取消</el-button>
            </div>
        </div>
    </el-dialog>
    <el-dialog v-model="editrisk" title="编辑风控信息" center>
        <el-input type="textarea" v-model="curriskmsg" rows="5"> </el-input>
        <div style="margin-top: 20px">
            <span>同时推送到商户端：</span>
            <el-switch v-model="isshow" active-value="1" inactive-value="0"></el-switch>
        </div>
        <template #footer>
            <el-button type="primary" size="small" @click="SaveRiskMsg">确定</el-button>
            <el-button size="small" @click="CancelRiskMsg">取消</el-button>
        </template>
    </el-dialog>
    <el-dialog v-model="editorder" title="修改订单" center width="30%">
        <el-form :model="alterorderinfo" ref="alterorderinfo" label-width="150px">
            <el-form-item label="订单号:">
                {{ alterorderinfo.OrderUID }}
            </el-form-item>
            <el-form-item label="订单状态:">
                <span v-if="alterorderinfo.Status == 2">等待人工审核</span>
                <span v-if="alterorderinfo.Status == 3">待付款</span>
            </el-form-item>
            <el-form-item label="当前租期:">
                {{ alterorderinfo.RentDay }}天
            </el-form-item>
            <el-form-item label="当前总租金:">
                {{ alterorderinfo.TotalOrderMoney * 1 }}元
            </el-form-item>
            <el-form-item label="修改租期:">
                <el-input-number :controls="false" v-model="alterorderinfo.alterrentday" size="small"></el-input-number>
            </el-form-item>
            <el-form-item label="修改总租金:">
                <el-input-number :controls="false" v-model="alterorderinfo.alterordermoney" size="small" :max="alterorderinfo.ZhimaDecrease > 0
                    ? alterorderinfo.Deposit
                    : alterorderinfo.TotalOrderMoney
                    "></el-input-number>
                <br />
                <el-tag type="primary">当前可修改的租金上限:
                    {{
                        alterorderinfo.ZhimaDecrease > 0
                            ? alterorderinfo.Deposit
                            : alterorderinfo.TotalOrderMoney
                    }}</el-tag>
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button size="small" type="primary" @click="savealter">确认</el-button>
            <el-button size="small" @click="cancelalter">取消</el-button>
        </template>
    </el-dialog>
    <el-dialog v-model="extendorder" title="延长租期" center width="30%">
        <el-form :model="extendinfo" ref="extendinfo" label-width="150px">
            <el-form-item label="订单号:">
                {{ extendinfo.OrderUID }}
            </el-form-item>
            <el-form-item label="订单状态:">
                <span v-if="extendinfo.Status == 4">待发货</span>
                <span v-if="extendinfo.Status == 5">待收货</span>
                <span v-if="
                    extendinfo.Status == 6 ||
                    extendinfo.Status == 12 ||
                    extendinfo.Status == 24
                ">待归还</span>
            </el-form-item>
            <el-form-item label="累计租期:">
                <span v-if="extendinfo.ResignDTime && extendinfo.ReturnDTime">{{ extendinfo.ResignDTime }} ~ {{
                    extendinfo.ReturnDTime }}</span>
                <span v-else> / </span>
            </el-form-item>
            <el-form-item label="租期:"> {{ extendinfo.RentDay }}天 </el-form-item>
            <el-form-item v-if="extendinfo.Status == 4 || extendinfo.Status == 5" label="修改租期:">
                延长租期
                <el-input-number :controls="false" v-model="extendinfo.extendday" size="small"></el-input-number>
                天
            </el-form-item>
            <el-form-item v-else label="修改租期:">
                <el-radio-group v-model="extendinfo.extype" @change="DisableOption">
                    <el-radio :label="1">延长租期
                        <el-input-number v-model="extendinfo.extendday" size="small" :controls="false"
                            :disabled="extendinfo.extype == 1 ? false : true"></el-input-number>
                        天
                    </el-radio>
                    <el-radio :label="2">指定租赁结束日期
                        <el-date-picker type="date" placeholder="选择日期" v-model="extendinfo.newreturn" size="small"
                            :disabled="extendinfo.extype == 2 ? false : true"></el-date-picker>
                    </el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item v-if="
                extendinfo.Status == 6 ||
                extendinfo.Status == 12 ||
                extendinfo.Status == 24
            " label="预计新应还机日:">
                <span v-if="extendinfo.newreturn || extendinfo.extendday > 0">{{
                    GetReturnDay(extendinfo.extype)
                }}</span>
            </el-form-item>
            <el-form-item label="备注:">
                <el-input v-model="extendinfo.remark" size="small"></el-input>
            </el-form-item>
        </el-form>
        <el-divider></el-divider>
        <template #footer>
            <el-button @click="cancelextend">取消</el-button>
            <el-button @click="saveextend" type="primary">确定</el-button>
        </template>
    </el-dialog>
    <el-dialog v-model="orderbill" title="还租详情" width="70%" center>
        <el-table :data="billdata" border style="width: 100%; margin: 1em 0" size="medium" v-loading="load"
            element-loading-spinner="el-icon-loading" element-loading-text="处理中...">
            <el-table-column label="期数" width="50px" prop="RentOrder" align="center"></el-table-column>
            <el-table-column label="应还租日" prop="PayDate" align="center">
                <template #default="scope">
                    <span>{{ formatDay(scope.row.PayDate) }}</span>
                </template>
            </el-table-column>
            <el-table-column label="应还租金" width="100px" prop="RentMoney" align="center">
                <template #default="scope">
                    <span v-if="scope.$index == 0">{{
                        (scope.row.RentMoney - cur_guarant).toFixed(2)
                    }}</span>
                    <span v-else>{{ scope.row.RentMoney }}</span>
                </template>
            </el-table-column>
            <el-table-column label="实还租金" width="100px" prop="PayMoney" align="center">
                <template #default="scope">
                    <span v-if="scope.$index == 0 && scope.row.Status == 1">{{
                        (scope.row.PayMoney - cur_guarant).toFixed(2)
                    }}</span>
                    <span v-else>{{ scope.row.PayMoney }}</span>
                </template>
            </el-table-column>
            <el-table-column label="未还租金" width="100px" align="center">
                <template #default="scope">
                    <span v-if="scope.$index == 0">
                        <span v-if="scope.row.PayMoney > 0 && scope.row.Status == 1">0.00</span>
                        <span v-else>{{
                            (
                                scope.row.RentMoney * 1 -
                                scope.row.PayMoney * 1 -
                                cur_guarant
                            ).toFixed(2)
                        }}</span>
                    </span>
                    <span v-else>{{
                        (scope.row.RentMoney * 1 - scope.row.PayMoney * 1).toFixed(2)
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="BillNo" label="交易流水号" align="center" />
            <el-table-column label="结算情况" width="100px" align="center">
                <template #default="scope">
                    <span v-if="scope.row.Status == 0">未结清</span>
                    <span v-if="scope.row.Status == 1">已结清</span>
                    <span v-if="scope.row.Status == 2">已全额退款</span>
                    <span v-if="scope.row.Status == 3">已部分退款<el-tag style="cursor: pointer"
                            @click="getrefund(scope.row.ID)">查看详情</el-tag></span>
                </template>
            </el-table-column>
            <el-table-column label="本期结清时间" :formatter="Getdate" align="center">
                <template #default="scope">
                    <span v-if="scope.row.PayDTime">{{ scope.row.PayDTime }}</span>
                </template>
            </el-table-column>
            <el-table-column label="本期结清方式" width="120px;" align="center">
                <template #default="scope">
                    <span v-if="scope.row.PayType == 1">租金结算</span>
                    <span v-if="scope.row.PayType == 2">线下入账</span>
                    <span v-if="scope.row.PayType == 3">系统代扣</span>
                    <span v-if="scope.row.PayType == 4">续租</span>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="160px" style="display: flex" align="center">
                <template #default="scope">
                    <el-button v-if="
                        (ismerchant != 1 &&
                            curorderstatus != 2 &&
                            scope.row.Status == 0) ||
                        (ismerchant != 1 && curorderstatus != 2 && scope.row.Status == 2)
                    " type="text" size="small" @click="AgreementPay(scope.row.ID, scope.$index)">{{ scope.row.Btn == 1
                        ?
                        "扣款处理中" : "手动扣款" }}</el-button>
                    <!-- <el-button v-if="(ismerchant == 1 &&
							curorderstatus != 2 &&
							scope.row.PayDate * 1000 < Date.now() &&
							scope.row.Status == 0) ||
							(scope.row.Status == 2 &&
								ismerchant == 1 &&
								curorderstatus != 2 &&
								scope.row.PayDate * 1000 < Date.now())
							" @click="AgreementPay(scope.row.ID, scope.$index)" type="text" size="small"
						:disabled="excuting || scope.row.Btn == 1 ? true : false">{{ scope.row.Btn == 1 ? "扣款处理中" :
							"手动扣款"
						}}</el-button> -->
                    <el-dropdown v-if="
                        ismerchant != 1 &&
                        scope.row.Status == 1 &&
                        rentcount != 1 &&
                        num != rentcount
                    " trigger="click" placement="right" style="width: 50%" size="small">
                        <el-button type="primary" @click="movebill(scope.row.OrderID)" size="small">
                            移动分期
                        </el-button>
                        <template #dropdown>
                            <el-dropdown-menu>
                                <el-dropdown-item v-for="(item, index) in movebilldata" :key="index" @click="
                                    movenumber(
                                        scope.row.ID,
                                        item.ID,
                                        scope.row.RentOrder,
                                        item.RentOrder
                                    )
                                    ">第{{ item.RentOrder }}期</el-dropdown-item>
                            </el-dropdown-menu>
                        </template>
                    </el-dropdown>
                    <el-dropdown v-if="
                        (ismerchant != 1 && scope.row.Status == 1) ||
                        scope.row.Status == 3
                    " style="left: 15px; width: 50%" trigger="click" placement="right" size="small">
                        <el-button type="primary" size="small"> 退款 </el-button>
                        <template #dropdown>
                            <el-dropdown-menu>
                                <el-dropdown-item v-if="scope.row.Status != 3" @click="
                                    refund(
                                        scope.row.ID,
                                        scope.row.OrderID,
                                        scope.row.PayMoney,
                                        scope.row.BillNo,
                                        1,
                                        scope.row.outTradeNo,
                                        scope.$index
                                    )
                                    ">退全款</el-dropdown-item>
                                <el-dropdown-item @click="
                                    refund(
                                        scope.row.ID,
                                        scope.row.OrderID,
                                        scope.row.PayMoney,
                                        scope.row.BillNo,
                                        2,
                                        scope.row.outTradeNo,
                                        scope.$index
                                    )
                                    ">退部分</el-dropdown-item>
                            </el-dropdown-menu>
                        </template>
                    </el-dropdown>
                </template>
            </el-table-column>
        </el-table>
    </el-dialog>
    <el-dialog v-model="tradebill" title="交易流水" width="70%" center>
        <el-table :data="tradedata" border style="width: 100%; margin: 1em 0" size="medium">
            <el-table-column label="订单号" prop="OrderUID"></el-table-column>
            <el-table-column label="客户名" width="100px" prop="NickName"></el-table-column>
            <el-table-column label="交易流水号" prop="BillNo"></el-table-column>
            <el-table-column label="交易时间" :formatter="Getdate" prop="AddDTime"></el-table-column>
            <el-table-column label="交易金额" width="100px">
                <template #default="scope">
                    <span v-if="scope.row.Source == 1" style="color: rgb(112, 182, 3)">+ {{ scope.row.BillMoney
                        }}</span>
                    <span v-if="scope.row.Source == 2" style="color: rgb(255, 20, 20)">- {{ scope.row.BillMoney
                        }}</span>
                </template>
            </el-table-column>
            <el-table-column label="交易类型" width="120px">
                <template #default="scope">
                    <span v-if="scope.row.TradeType == 1">租金</span>
                    <span v-if="scope.row.TradeType == 2">押金</span>
                    <span v-if="scope.row.TradeType == 3">违约金</span>
                    <span v-if="scope.row.TradeType == 4">买断金</span>
                    <span v-if="scope.row.TradeType == 5">定损赔偿金</span>
                    <span v-if="scope.row.TradeType == 8">强制买断金</span>
                    <span v-if="scope.row.TradeType == 6">退款</span>
                    <span v-if="scope.row.TradeType == 7">补押金</span>
                    <span v-if="scope.row.TradeType == 10">续租</span>
                </template>
            </el-table-column>
            <el-table-column label="备注" prop="Remark"></el-table-column>
            <el-table-column v-if="ismerchant == 0" label="操作">
                <template #default="scope">
                    <el-tag type="danger" style="cursor: pointer" @click="deleteflow(scope.row.ID)">删除</el-tag>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination @current-change="handleTraCurrentChange" @size-change="handleTraSizeChange"
            :current-page="tra_curpage" :page-size="pagesize" :page-sizes="[10, 20, 30, 50, 100]"
            layout="sizes,total, prev, pager, next, jumper" :total="tra_totalcount" :hide-on-single-page="true"
            style="text-align: center"></el-pagination>
    </el-dialog>
    <!--查看身份证-->
    <el-dialog v-model="id" title="查看身份证" width="30%" :before-close="handleClose">
        <el-divider />
        <div style="display: flex">
            <div style="flex: 1" v-for="(url, index) in urlImg" :key="index">
                <el-image style="width: 100%; height: 250px" :src="url" :preview-src-list="urlImg" :initial-index="4"
                    fit="contain" />
                <span v-if="index == 0" style="
            color: #ababab;
            margin-top: 20px;
            font-size: 24px;
            display: block;
            text-align: center;
          ">人像面</span>
                <span v-if="index == 1" style="
            color: #ababab;
            margin-top: 20px;
            font-size: 24px;
            display: block;
            text-align: center;
          ">国徽面</span>
            </div>
        </div>
        <p style="color: #ababab; text-align: center">
            注意：若身份证不清晰，客户可在审核通过前，在【订单详情】中再次上传
        </p>
        <el-divider />
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="id = false">关闭</el-button>
            </span>
        </template>
    </el-dialog>
    <!--添加身份信息-->
    <el-dialog v-model="addid" title="上传身份证" width="30%" :before-close="beforeclose">
        <el-divider />
        <div style="display: flex">
            <el-upload style="flex: 1; text-align: center" :action="uploadfile" list-type="picture-card"
                :on-success="UploadSuccessid1" :on-change="UploadChangeid1" :on-error="UploadErrorid1"
                :on-remove="RemovePicid1" :on-preview="Previewid1" multiple :file-list="FileListid1" :limit="1"
                :before-upload="beforeAvatarUploadid">
                <el-dialog v-model="id1">
                    <img w-full :src="ImageUrl1" alt="Preview Image" />
                </el-dialog>
                <el-button type="primary" style="width: 143px">上传人像面身份证</el-button>
            </el-upload>
            <el-upload style="flex: 1; text-align: center" :action="uploadfile" list-type="picture-card"
                :on-success="UploadSuccessid2" :on-change="UploadChangeid2" :on-error="UploadErrorid2"
                :on-remove="RemovePicid2" :on-preview="Previewid2" multiple :file-list="FileListid2" :limit="1"
                :before-upload="beforeAvatarUploadid">
                <el-dialog v-model="id2">
                    <img w-full :src="ImageUrl2" alt="Preview Image" />
                </el-dialog>
                <el-button type="primary" style="width: 143px">上传人像面身份证</el-button>
            </el-upload>
        </div>
        <el-divider />
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="addinfocancel">取消</el-button>
                <el-button type="primary" @click="addinfoadd">保存</el-button>
            </span>
        </template>
    </el-dialog>
    <!--添加签收证据-->
    <el-dialog v-model="uploadproof" title="上传签收证据" width="20%" center>
        <el-upload class="avatar-uploader" :action="upload_url" list-type="picture-card"
            :on-success="UploadProofSuccess" :on-change="UploadProofChange" :on-error="UploadProofError"
            :on-remove="RemoveProof" :on-preview="Preview" accept=".jpg,.jpeg,.png" :show-file-list="false">
            <img v-if="curproof" :src="curproof" style="width: 146px; height: 146px" />
            <i class="el-icon-plus" v-else></i>
        </el-upload>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="uploadproof = false">取消</el-button>
                <el-button type="primary" @click="saveproof">保存</el-button>
            </span>
        </template>
    </el-dialog>
    <!--交易快照-->
    <el-dialog v-model="trade" title="交易快照" width="30%" :before-close="handleClose2">
        <el-scrollbar height="800px">
            <div class="img">
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-carousel height="340px">
                            <el-carousel-item v-for="(item, index) in trades.PdtImg" :key="index">
                                <el-image :src="item"></el-image>
                            </el-carousel-item>
                        </el-carousel>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <h3>
                            <el-tag class="ml-2" type="danger">{{ trades.quality }}</el-tag>&nbsp;&nbsp; <span>{{
                                trades.PdtName }}</span>
                        </h3>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">已选</el-col>
                    <el-col :span="12">{{ trades.PlanTitle }}</el-col>
                </el-row>
                <el-row style="margin-top: 10px">
                    <el-col :span="12">数量</el-col>
                    <el-col :span="12">1</el-col>
                </el-row>
                <el-row style="margin-top: 10px">
                    <el-col :span="12">租期</el-col>
                    <el-col :span="12" v-if="trades.Status != 10">{{ trades.RentDay }}天</el-col>
                    <el-col :span="12" v-if="trades.Status == 10">已取消</el-col>
                </el-row>
                <!-- <el-row style="margin-top: 10px">
        <el-col :span="12">租金</el-col>
        <el-col :span="12">
          <span v-if="trades.RentType == 1">{{ trades.MaxVal }}元/天</span>
          <span v-if="trades.RentType == 2">{{ trades.MaxVal }}元/天</span>
          <span v-if="trades.RentType == 3">{{ trades.MinNormal }}元/月</span>
          <span v-if="trades.RentType == 4">{{ trades.MinNormal }}元/月</span>
        </el-col>
      </el-row> -->
                <el-row style="margin-top: 10px">
                    <el-col :span="12">总租金</el-col>
                    <el-col :span="12">{{ trades.TotalOrderMoney }}元</el-col>
                </el-row>
                <el-row style="margin-top: 10px">
                    <el-col :span="12">买断价</el-col>
                    <el-col :span="12" v-if="trades.Buyout == 1">{{
                        trades.BuyoutPrice * 1 - trades.TotalOrderMoney * 1 > 0
                            ? (trades.BuyoutPrice * 1 - trades.TotalOrderMoney * 1).toFixed(
                                2
                            )
                            : 0
                    }}元</el-col>
                    <el-col :span="12" v-if="trades.Buyout == 2">不可买断</el-col>
                </el-row>
                <el-row style="margin-top: 10px">
                    <el-col :span="12">快照时间</el-col>
                    <el-col :span="12">{{ trades.AddDTime }}</el-col>
                </el-row>
                <el-row style="margin-top: 10px">
                    <el-col :span="12">商品押金</el-col>
                    <el-col :span="12">{{ trades.Deposit }}元</el-col>
                </el-row>
                <el-row style="margin-top: 10px">
                    <el-col :span="12">签约价</el-col>
                    <el-col :span="12">{{ trades.BuyoutPrice }}元</el-col>
                </el-row>
                <el-row v-if="trades.coupon_arr" style="margin-top: 10px">
                    <el-col :span="12">优惠</el-col>
                    <el-col :span="12">{{ trades.coupon_arr.CouponName }}</el-col>
                </el-row>
                <el-row v-if="trades.coupon_arr" style="margin-top: 10px">
                    <el-col :span="12">优惠金额</el-col>
                    <el-col :span="12">{{ trades.coupon_arr.CouponValue
                        }}{{ trades.coupon_arr.UseType == "(抵扣)" ? "元" : "%"
                        }}{{ trades.coupon_arr.UseType }}</el-col>
                </el-row>
                <el-row style="margin-top: 10px">
                    <el-col :span="12">配送方式</el-col>
                    <el-col :span="12">
                        {{ trades.deliver_type }}
                    </el-col>
                </el-row>
                <el-row style="
            text-align: center;
            height: 30px;
            line-height: 30px;
            margin-top: 10px;
          ">
                    <el-col :span="24">
                        <h3>商品参数</h3>
                    </el-col>
                </el-row>
                <el-row style="margin-top: 30px">
                    <el-col :span="12">品类</el-col>
                    <el-col :span="12">{{ trades.Category }}</el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">品牌</el-col>
                    <el-col :span="12">{{ trades.Brand }}</el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">型号</el-col>
                    <el-col :span="12">{{ trades.Model }}</el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">配置详情</el-col>
                    <el-col :span="12">{{ trades.Params }}</el-col>
                </el-row>
                <el-row style="
            text-align: center;
            height: 30px;
            line-height: 30px;
            margin-top: 10px;
          ">
                    <el-col :span="24">
                        <h3>商品详情</h3>
                    </el-col>
                </el-row>
                <el-row style="
            text-align: center;
            height: auto;
            line-height: 30px;
            margin-top: 10px;
          ">
                    <el-col :span="24">
                        <span v-html="trades.PdtDesc"></span>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col>
                        <el-collapse v-for="(item, index) in problem" :key="index" accordion>
                            <el-collapse-item :title="item.title" name="index">
                                <div v-if="item.problems">
                                    <div v-for="(ite, inde) in item.problems" :key="inde">
                                        <span style="color: red">
                                            <h3>{{ ite.title }}</h3>
                                        </span>
                                        <div v-html="ite.value"></div>
                                    </div>
                                </div>
                                <div style="color: red" v-else>
                                    <h3>{{ item.phone }}</h3>
                                </div>
                            </el-collapse-item>
                        </el-collapse>
                    </el-col>
                </el-row>
            </div>
        </el-scrollbar>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="trade = false">关闭</el-button>
            </span>
        </template>
    </el-dialog>
    <!--导出订单-->
    <el-dialog v-model="exportorder" title="导出订单" width="30%">
        <el-row style="text-align: center">
            <el-col :span="6" style="display: flex; align-items: center; justify-content: right">订单状态:</el-col>
            <el-col :span="18">
                <el-select style="width: 66%" @change="selectstatus" v-model="order.status2" clearable
                    placeholder="请选择订单状态">
                    <el-option v-for="item in orderstatus" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
            </el-col>
        </el-row>
        <el-row style="text-align: center; margin-top: 10px">
            <el-col :span="6" style="display: flex; align-items: center; justify-content: right">下单时间:</el-col>
            <el-col :span="18">
                <el-date-picker v-model="range" type="daterange" unlink-panels range-separator="至"
                    start-placeholder="开始时间" end-placeholder="结束时间" :shortcuts="shortcuts" style="width: 66%"
                    @selection-change="CheckSelection">
                </el-date-picker>
            </el-col>
        </el-row>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="exportorder = false">取消</el-button>
                <el-button type="primary" @click="exportorder = false">
                    <download-excel class="btn btn-default" :fetch="fetchData" :fields="json_fields"
                        :before-generate="startDownload" :before-finish="finishDownload" worksheet="My Worksheet"
                        type="csv" name="订单数据.csv">
                        <span style="padding: 11px 20px; margin: 11px -20px">导出</span>
                    </download-excel>
                </el-button>
            </span>
        </template>
    </el-dialog>
    <!--保险-->
    <el-dialog v-model="insurance" title="租赁宝保险" width="30%" :before-close="handleClose1">
        <pdf :src="pdfurl"> </pdf>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="insurance = false">关闭</el-button>
                <el-button type="primary" @click="Download">下载</el-button>
            </span>
        </template>
    </el-dialog>
    <!--上传身份证-->
    <el-dialog v-model="uploadcard" title="上传身份证" width="30%" center :before-close="handleClose">
        <el-row>
            <el-col :span="12" style="text-align: center">
                身份证正面：<el-upload :action="upload_url" list-type="picture-card" :on-success="zhengmian"
                    :before-upload="beforeUpload" :on-preview="handlePictureCardPreview1" :on-remove="handleRemove1"
                    limit="1">
                    <el-icon>上传身份证</el-icon>
                </el-upload>
                <el-dialog v-model="dialogVisible1">
                    <el-image style="width: 100%; height: 100%" :src="dialogImageUrl1" fit="fit" />
                </el-dialog>
            </el-col>
            <el-col :span="12" style="text-align: center">
                身份证反面：<el-upload :action="upload_url" list-type="picture-card" :on-success="fanmian"
                    :before-upload="beforeUpload2" :on-preview="handlePictureCardPreview2" :on-remove="handleRemove2"
                    limit="1">
                    <el-icon>上传身份证</el-icon>
                </el-upload>
                <el-dialog v-model="dialogVisible4">
                    <el-image style="width: 100%; height: 100%" :src="dialogImageUrl4" fit="fit" />
                </el-dialog>
            </el-col>
        </el-row>
        <template #footer>
            <span style="
          display: block;
          color: red;
          margin-bottom: 26px;
          text-align: center;
        ">上传文件大小不能超过2M</span>
            <span class="dialog-footer">
                <el-button @click="uploadcard = false">取消</el-button>
                <el-button type="primary" @click="uploadImg"> 确认 </el-button>
            </span>
        </template>
    </el-dialog>
    <!--上传身份证-->
    <el-dialog v-model="insurance" title="租赁宝保险" width="30%" :before-close="handleClose1">
        <pdf :src="pdfurl"> </pdf>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="insurance = false">关闭</el-button>
                <el-button type="primary" @click="Download">下载</el-button>
            </span>
        </template>
    </el-dialog>
    <!--退部分-->
    <el-dialog v-model="refundmodel" title="部分退款" width="22%" align-center>
        <label>请输入要退款的金额：</label><el-input-number v-model="refundmoney" :min="0.01" :max="paymoney"
            aria-placeholder="请输入要退款的金额" />(单位:元)
        <template #footer>
            <span class="dialog-footer" v-show="footer1">
                <el-button @click="refundmodel = false">取消</el-button>
                <el-button type="primary" @click="confirmrefund"> 确认 </el-button>
            </span>
            <span class="dialog-footer" v-show="footer2"> 正在处理中，请稍后！ </span>
        </template>
    </el-dialog>
    <!--部分退款-->
    <el-dialog v-model="partrefund" title="部分退款详情" width="24%">
        <el-table :data="refunddetail" style="width: 100%">
            <el-table-column type="index" width="50" />
            <el-table-column prop="refund" label="退款金额(单位/元)" width="180" />
            <el-table-column prop="createtime" label="退款时间" width="180" />
        </el-table>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="partrefund = false">关闭</el-button>
            </span>
        </template>
    </el-dialog>
    <!--获取订单号-->
    <el-dialog v-model="orderhao" title="选择逾期日期" width="15%">
        <el-date-picker v-model="yuqi" type="date" placeholder="选择逾期日期" format="YYYY-MM-DD" value-format="YYYY-MM-DD" />
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="orderhao = false">取消</el-button>
                <el-button type="primary" @click="auth"> 获取 </el-button>
            </span>
            <span style="display: block; text-align: left; margin-top: 10px">
                <span v-show="auth_no">授权单号：{{ auth_no }}</span> <br />
                <span v-show="trade_no">订单号：{{ trade_no }}</span>
                <br />
                <span v-show="uid">uid：{{ uid }}</span>
            </span>
        </template>
    </el-dialog>
    <!--修改状态-->
    <el-dialog v-model="editss" title="修改状态" width="20%">
        <el-radio-group v-model="statuss">
            <el-radio label="6">待归还</el-radio>
            <el-radio label="5">待收货</el-radio>
        </el-radio-group>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="editss = false">取消</el-button>
                <el-button type="primary" @click="editsss"> 确定 </el-button>
            </span>
        </template>
    </el-dialog>
    <!---资料列表-->
    <el-dialog v-model="infolist" title="资料列表" width="30%" :before-close="handleClose">
        <el-row>
            <el-col class="ziliao" :span="8">序号</el-col>
            <el-col class="ziliao" :span="8">名称</el-col>
            <el-col class="ziliao" :span="8">操作</el-col>
        </el-row>
        <el-row v-for="(item, index) in auditinfo2" :key="index">
            <el-col class="ziliao" :span="8">{{ index + 1 }}</el-col>
            <el-col class="ziliao" :span="8">资料{{ index + 1 }}</el-col>
            <el-col class="ziliao" :span="8" @click="viewaudit(item.id, item.orderid)">查看资料</el-col>
        </el-row>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="infolist = false">关闭</el-button>
            </span>
        </template>
    </el-dialog>
    <!--审核资料-->
    <el-dialog v-model="audit" title="风控表单" width="75%" center>
        <el-container>
            <el-header>（接单请回复1 /拒单请回复2 / 三分钟内未回复默认拒单） 是否显示图片
                <input type="radio" id="one" value="1" v-model="showpic" />
                <label for="one">是</label>
                <input type="radio" id="two" value="2" v-model="showpic" />
                <label for="two">否</label>
            </el-header>
            <el-container class="cen">
                <el-aside width="200px">
                    <div class="hei">订单基本信息</div>
                </el-aside>
                <el-main v-for="(item, index) in memberinfo" :key="index">
                    <el-row>
                        <el-col class="pad" :span="3">姓名/性别/年龄</el-col>
                        <el-col class="pad" :span="6">{{ item.RealName }}/{{ item.Gender == "m" ? "男" : "女" }}/{{
                            item.Age
                        }}</el-col>
                        <el-col class="pad" :span="9">审核费（签约价4999以下30元，5k以上50元）</el-col>
                        <el-col class="pad" :span="6">
                            <span>{{ auditinfo[0].shenhefei }}</span>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col class="pad" :span="3">产品型号</el-col>
                        <el-col class="pad" :span="6">{{ item.PdtName }} {{ item.Params }}</el-col>
                        <el-col class="pad" :span="9">锁机费/元</el-col>
                        <el-col class="pad" :span="6">
                            <span v-if="
                                item.CategoryID == 43 ||
                                item.CategoryID == 47 ||
                                (item.CategoryID == 58 && item.BrandID == 11)
                            ">60元</span>
                            <span v-else>0元</span>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col class="pad" :span="3">买断价</el-col>
                        <el-col class="pad" :span="6">{{
                            auditinfo[0].BuyoutPrice
                        }}</el-col>
                        <el-col class="pad" :span="9"></el-col>
                        <el-col class="pad" :span="6"></el-col>
                    </el-row>
                    <el-row>
                        <el-col class="pad" :span="3">机价（成本）</el-col>
                        <el-col class="pad" :span="6">{{ auditinfo[0].Margin
                            }}<el-tag @click="editprice(auditinfo[0].Margin)" style="cursor: pointer"
                                v-if="ismerchant == 0" size="small">修改</el-tag>
                        </el-col>
                        <el-col class="pad" :span="9">本单利润预估（签约价-成本-锁机-审核费）</el-col>
                        <el-col class="pad" :span="6">
                            <span>{{ auditinfo[0].lirun }}</span></el-col>
                    </el-row>
                    <el-row>
                        <el-col class="pad" :span="3">是否购买随心购</el-col>
                        <el-col class="pad" :span="6">
                            <span v-if="auditinfo[0].ispurchase == 2"> 是 </span>
                            <span v-else>否</span>
                        </el-col>
                        <el-col class="pad" :span="9">是否上监管锁</el-col>
                        <el-col class="pad" :span="6">
                            <span v-if="
                                item.CategoryID == 43 ||
                                item.CategoryID == 47 ||
                                (item.CategoryID == 58 && item.BrandID == 11)
                            ">是</span>
                            <span v-else>否</span>
                        </el-col>
                    </el-row>
                </el-main>
            </el-container>
            <el-container class="cen">
                <el-aside width="200px">
                    <div class="hei">客户基本信息</div>
                </el-aside>
                <el-main v-for="(item, index) in auditinfo" :key="index">
                    <el-row>
                        <el-col class="pad" :span="3">半年内支付宝是否产生逾期</el-col>
                        <el-col class="pad" :span="6">
                            <span v-if="item.yuqi != ''">{{ item.yuqi }}</span>
                            <span v-else>否</span>
                        </el-col>
                        <el-col class="pad" :span="9">是否授权其他网货平台</el-col>
                        <el-col class="pad" :span="6">
                            <span v-if="item.shouquanwangdai != ''">是</span>
                            <span v-else>否</span>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col class="pad" :span="3">芝麻分</el-col>
                        <el-col class="pad" :span="6">{{ item.zhimafen[0] }}
                            <span v-if="showpic == 1">
                                <span v-for="(i, ind) in item.zhimafen[1]" :key="ind">
                                    <el-image style="width: 100px; height: 100px" :src="i" fit="fit"
                                        :preview-src-list="item.zhimafen[1]" />
                                </span>
                            </span>
                        </el-col>
                        <el-col class="pad" :span="9">是否有进行中的网货小贷</el-col>
                        <el-col class="pad" :span="6">
                            <span v-if="item.jinxingzhongwangdai != ''">{{
                                item.jinxingzhongwangdai
                            }}</span>
                            <span v-else>否</span>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col class="pad" :span="3">手机号使用年限/年</el-col>
                        <el-col class="pad" :span="6">{{ item.shoujihao }}</el-col>
                        <el-col class="pad" :span="9">支付宝是否有其他在租订单</el-col>
                        <el-col class="pad" :span="6" v-if="item.zaizu != ''">
                            是
                            <span v-if="showpic == 1">
                                <span v-for="(i, index) in item.zaizu" :key="index">
                                    <el-image style="width: 100px; height: 100px" :src="i" fit="fit"
                                        :preview-src-list="item.zaizu" />
                                </span>
                            </span>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col class="pad" :span="3">手机号码月套餐金额</el-col>
                        <el-col class="pad" :span="6">{{ item.taocan }}</el-col>
                        <el-col class="pad" :span="9">微信分</el-col>
                        <el-col class="pad" :span="6">
                            {{ item.weixinfen[0] }}
                            <span v-if="showpic == 1">
                                <span v-for="(i, ind) in item.weixinfen[1]" :key="ind">
                                    <el-image style="width: 100px; height: 100px" :src="i" fit="fit"
                                        :preview-src-list="item.weixinfen[1]" />
                                </span>
                            </span>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col class="pad" :span="3">每月工资/元</el-col>
                        <el-col class="pad" :span="6">{{ item.salary }}</el-col>
                        <el-col class="pad" :span="9">微信/QQ/抖音用当前手机号实名</el-col>
                        <el-col class="pad" :span="6">
                            <span v-if="item.shiming != '' && showpic == 1">
                                <span v-for="(i, index) in item.shiming" :key="index">
                                    <el-image style="width: 100px; height: 100px" :src="i" fit="fit"
                                        :preview-src-list="item.shiming" />
                                </span>
                            </span>
                            <span v-else>否</span>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col class="pad" :span="3">有无社保</el-col>
                        <el-col class="pad" :span="6">
                            <span v-if="showpic == 1 && item.shebao != ''">
                                是
                                <span v-for="(i, index) in item.shebao" :key="index">
                                    <el-image style="width: 100px; height: 100px" :src="i" fit="fit"
                                        :preview-src-list="item.shebao" />
                                </span>
                            </span>
                            <span>否</span>
                        </el-col>
                        <el-col class="pad" :span="9">美团/淘宝/京东收货地址是否本地</el-col>
                        <el-col class="pad" :span="6">
                            <span v-if="showpic == 1 && item.shouhuoaddress != ''">
                                是
                                <span style="margin-right: 10px" v-for="(i, index) in item.shouhuoaddress" :key="index">
                                    <el-image style="width: 100px; height: 100px" :src="i" fit="cover"
                                        :preview-src-list="item.shouhuoaddress" :zoom-rate="1.2" />
                                </span>
                            </span>
                            <span v-else>否</span>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col class="pad" :span="3">是否有房贷车贷</el-col>
                        <el-col class="pad" :span="6">
                            <span v-if="showpic == 1 && item.fangdai != ''">
                                是
                                <span v-for="(i, index) in item.fangdai" :key="index">
                                    <el-image style="width: 100px; height: 100px" :src="i" fit="fit"
                                        :preview-src-list="fangdai" />
                                </span>
                            </span>
                            <span v-else>否</span>
                        </el-col>
                        <el-col class="pad" :span="9">是否有信用卡</el-col>
                        <el-col class="pad" :span="6">
                            <span v-if="item.xinyongka != ''">是{{ item.xinyongka }}</span>
                            <span v-else>否</span>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col class="pad" :span="3">是否补押金</el-col>
                        <el-col class="pad" :span="6" v-if="item.yajin != ''">是{{ item.yajin }}</el-col>
                        <el-col class="pad" :span="6" v-else>否</el-col>
                        <el-col class="pad" :span="9">紧急联系人</el-col>
                        <el-col class="pad" :span="6">
                            <span v-if="item.contact != ''">
                                <span v-for="(i, index) in item.contact" :key="index">
                                    {{ i }}<br />
                                </span>
                            </span>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col class="pad" :span="3">房屋信息</el-col>
                        <el-col class="pad" :span="6">
                            <span v-if="item.fangwu == 1">自建房</span>
                            <span v-if="item.fangwu == 2">商品房</span>
                            <span v-if="item.fangwu == 3">租房</span>
                        </el-col>
                        <el-col class="pad" :span="9">婚姻</el-col>
                        <el-col class="pad" :span="6">
                            <span v-if="item.hunyin == 1"> 已婚 </span>
                            <span v-if="item.hunyin == 2"> 未婚 </span>
                            <span v-if="item.hunyin == 3"> 离异 </span>
                            <span v-if="item.hunyin == 4"> 已婚有子女 </span>
                            <span v-if="item.hunyin == 5"> 离异有子女 </span>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col class="pad" :span="3">单位</el-col>
                        <el-col class="pad" :span="6" v-if="item.danwei != ''">{{
                            item.danwei
                        }}</el-col>
                        <el-col class="pad" :span="9">单位地址</el-col>
                        <el-col class="pad" :span="6">
                            <span v-if="item.danweidizhi != ''">
                                {{ item.danweidizhi }}
                            </span>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col class="pad" :span="3">单位电话</el-col>
                        <el-col class="pad" :span="6" v-if="item.danweidianhua != ''">{{
                            item.danweidianhua
                        }}</el-col>
                        <el-col class="pad" :span="9">发薪日期</el-col>
                        <el-col class="pad" :span="6">
                            <span v-if="item.faxin != ''">
                                {{ item.faxin }}
                            </span>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col class="pad" :span="3">学历</el-col>
                        <el-col class="pad" :span="6" v-if="item.xueli != ''">{{
                            item.xueli
                        }}</el-col>
                        <el-col class="pad" :span="6">更多联系人</el-col>
                        <el-col class="pad" :span="6" v-if="item.gengduo != ''">
                            <span v-for="(i, index) in item.gengduo" :key="index">
                                <el-image style="width: 100px; height: 100px" :src="i" fit="fit"
                                    :preview-src-list="item.gengduo" />
                            </span>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col class="pad" :span="3">现场照片</el-col>
                        <el-col class="pad" :span="6" v-if="item.xianchang != ''">
                            <span v-for="(i, index) in item.xianchang" :key="index">
                                <el-image style="width: 100px; height: 100px" :src="i" fit="fit"
                                    :preview-src-list="item.xianchang" />
                            </span>
                        </el-col>
                        <el-col class="pad" :span="6">工作证明</el-col>
                        <el-col class="pad" :span="6" v-if="item.work != ''">
                            <span v-for="(i, index) in item.work" :key="index">
                                <el-image style="width: 100px; height: 100px" :src="i" fit="fit"
                                    :preview-src-list="item.work" />
                            </span>
                        </el-col>
                    </el-row>
                </el-main>
            </el-container>
            <el-footer style="
          display: flex;
          color: red;
          align-items: center;
          justify-content: center;
        ">建议通过</el-footer>
        </el-container>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="audit = false">关闭</el-button>
            </span>
        </template>
    </el-dialog>
    <!--调拨-->
    <el-dialog v-model="db" title="调拨" width="19%" :before-close="handleClose">
        <span style="display: flex; justify-content: center; align-items: center">
            <el-select v-model="dbvalue" clearable @change="dbchange" placeholder="请选择商铺">
                <el-option @click="selectdb(item)" v-for="item in dboptions" :key="item.value" :label="item.label"
                    :value="item.value" />
            </el-select>
            <div style="margin-left: 10px; cursor: pointer" @click="Pinned(1)" v-if="pinned == 0">
                置顶
            </div>
            <div style="margin-left: 10px; cursor: pointer" @click="Pinned(0)" v-if="pinned == 1">
                取消置顶
            </div>
        </span>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="db = false">取消</el-button>
                <el-button @click="savedb">确定</el-button>
            </span>
        </template>
    </el-dialog>
    <!--查看交付情况-->
    <el-dialog v-model="jiaofudetail" title="交付详情" width="30%">
        <el-container>
            <el-main>
                <el-row>
                    <el-col :span="12" class="jiaofu">详情</el-col>
                    <el-col :span="12" v-if="ismerchant != 1" class="jiaofu">操作</el-col>
                </el-row>
                <el-row style="align-items: center; text-align: center">
                    <el-col :span="12">
                        <span v-for="(item1, index1) in jiaofuimage" :key="index1">
                            <el-image style="width: 100px; height: 100px" v-for="(item, index) in item1['file']"
                                :key="index" :src="item" :zoom-rate="1.2" :preview-src-list="item1['file']"
                                :initial-index="4" fit="cover" />
                        </span>
                    </el-col>
                    <el-col :span="12" v-if="ismerchant != 1 && jiaofuimage[0]['status'] == 0" class="jiaofu2">
                        <span @click="pass" class="jiaofu3"><el-button type="primary" size="small"
                                round>交付</el-button></span>
                        <span @click="reject" class="jiaofu3"><el-button type="danger" size="small"
                                round>驳回</el-button></span>
                    </el-col>
                </el-row>
            </el-main>
        </el-container>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="jiaofudetail = false">关闭</el-button>
            </span>
        </template>
    </el-dialog>
    <!--分销信息-->
    <el-dialog v-model="fenxiaomodel" title="店铺信息" width="15%">
        <span>店铺名称:{{ storedata.storename }}</span><br />
        <el-text class="mx-1">签约日期:{{ storedata.addtime }}</el-text><br />
        <el-text class="mx-1">合作时长:{{ storedata.cooperation }}天</el-text><br />
        <el-text class="mx-1">在租订单:{{ storedata.renting }}</el-text><br />
        <el-text class="mx-1">逾期订单:{{ storedata.overdueing }}</el-text><br />
        <el-text class="mx-1">逾期率:{{ storedata.overdue }}%</el-text><br />
        <el-text class="mx-1">店铺类型:
            <span v-if="storedata.storetype == 1">连锁</span>
            <span v-else-if="storedata.storetype == 2">店铺</span>
            <span v-else-if="storedata.storetype == 3">柜台</span>
            <span v-else>暂无</span> </el-text><br />
        <el-text class="mx-1">备注:{{ storedata.remark }}</el-text>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="fenxiaomodel = false">关闭</el-button>
            </span>
        </template>
    </el-dialog>
    <!--分销信息-->
    <!--抖音实名认证-->
    <el-dialog v-model="renzheng" title="抖音实名" width=" 30%" align-center>
        <el-form :model="dy">
            <el-form-item label="姓名">
                <el-input v-model="dy.RealName" placeholder="请输入姓名" />
            </el-form-item>
            <el-form-item label="身份证号">
                <el-input v-model="dy.LicenseID" maxlength="18" placeholder="请输入身份证号" />
            </el-form-item>
        </el-form>
        <template #footer>
            <div class="dialog-footer">
                <el-button @click="renzheng = false">取消</el-button>
                <el-button type="primary" @click="dysave"> 保存 </el-button>
            </div>
        </template>
    </el-dialog>
    <!--抖音实名认证-->
</template>
<script>
import qs from "qs";
import Cookies from "js-cookie";
import constant from "@/constant";
import { ref, reactive } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import problem from "@/zl_common_problem";
import * as imageConversion from "image-conversion";
import pdf from "vue3-pdf";
import JsonExcel from "vue-json-excel3";
// import { ArrowDown } from '@element-plus/icons-vue'
export default {
    components: {
        pdf,
        downloadExcel: JsonExcel,
        // ArrowDown
    },
    data() {
        return {
            dy: reactive({
                RealName: "",
                LicenseID: "",
            }),
            item: [],
            ispurchase: "",
            label: ref(""),
            aduid: "",
            ywyid: ref(""),
            ywyoptions: ref([]),
            ywyorderid: "",
            saveywy1: ref(false),
            storedata: reactive([]),
            fenxiaomodel: ref(false),
            neworderid: "",
            partorderid: "",
            vv: ref(""),
            parts: [],
            dialog: ref(false),
            jiaofuorderid: "",
            jiaofuimage: [],
            jiaofudetail: ref(false),
            weixin: require("@/assets/image/weixin.png"),
            douyin: require("@/assets/image/douyin.png"),
            zhifubao1: require("@/assets/image/zhifubao1.png"),
            dbvalue: ref(""),
            dboptions: [],
            showpic: ref("1"),
            memberinfo: [],
            infolist: ref(false),
            audit: ref(false),
            auditinfo2: [],
            auditinfo: [],
            deposit4: [],
            deposit2: ref(false),
            orderids: "",
            wanchengstatus: ref(""),
            wanchengid: ref(),
            wanchengitem: [],
            accomplish: ref(false),
            bohui3: ref(""),
            bohui2: ref(),
            imgurl: ref(),
            textarea: ref(),
            rentmoney: ref(),
            xuzu2: ref(),
            xuzutime: ref(),
            Object1: [],
            xuzu: ref(false),
            orderidd: "",
            orderiddb: "",
            statuss: ref(""),
            editss: ref(false),
            auth_no: ref(""),
            trade_no: ref(""),
            uid: ref(""),
            orderid2: ref(""),
            yuqi: ref(""),
            orderhao: ref(false),
            refunddetail: [],
            partrefund: ref(false),
            refundmoney: ref(""),
            paymoney: ref(""),
            refundmodel: ref(false),
            num: "",
            movebilldata: [],
            tab_for: [],
            footer1: ref(true),
            footer2: ref(false),
            loading: ref(false),
            exportorder: ref(false),
            order: reactive({
                status2: "",
            }),
            orderstatus: [
                {
                    label: "全部",
                    value: "0",
                },
                {
                    label: "租赁中",
                    value: "28",
                },
                {
                    label: "剔除下单中和已取消",
                    value: "110",
                },
                {
                    label: "待审核",
                    value: "2",
                },
                {
                    label: "待付款",
                    value: "3",
                },
                {
                    label: "待发货",
                    value: "4",
                },
                {
                    label: "待收货",
                    value: "5",
                },
                {
                    label: "待归还",
                    value: "6",
                },
                {
                    label: "归还中",
                    value: "7",
                },
                {
                    label: "已完成",
                    value: "9",
                },
                {
                    label: "已买断",
                    value: "21",
                },
                {
                    label: "逾期中",
                    value: "1224",
                },
            ],
            json_fields: {
                订单ID: "OrderUID",
                商品标题: "PdtName",
                套餐: "PlanTitle",
                "租期(天)": "RentDay",
                "期数(月)": "RentCount",
                "数量(个)": "OrderCount",
                "订单总租金(元)": "sum",
                "签约价值(元)": "PlanProp.buyoutprice",
                "已支付租金(元)": {
                    field: "Paid",
                    callback: (value) => {
                        if (value) {
                            return `${value}`;
                        } else {
                            return `0.0`;
                        }
                    },
                },
                "未交租金(元)": {
                    field: "UnPaid",
                    callback: (value) => {
                        if (value) {
                            return `${value}`;
                        } else {
                            return `0.0`;
                        }
                    },
                },
                是否买断: {
                    field: "Status",
                    callback: (value) => {
                        if (value == 21) {
                            return `是`;
                        } else {
                            return `否`;
                        }
                    },
                },
                订单状态: {
                    field: "Status",
                    callback: (value) => {
                        if (value == 1) {
                            return `下单中`;
                        }
                        if (value == 2) {
                            return `待审核`;
                        }
                        if (value == 3) {
                            return `待付款`;
                        }
                        if (value == 4) {
                            return `待发货`;
                        }
                        if (value == 5) {
                            return `待收货`;
                        }
                        if (value == 6) {
                            return `待归还`;
                        }
                        if (value == 7) {
                            return `归还中 `;
                        }
                        if (value == 9) {
                            return ` 已完成`;
                        }
                        if (value == 10) {
                            return `已取消`;
                        }
                        if (value == 12) {
                            return `还机逾期中`;
                        }
                        if (value == 13) {
                            return `审核不通过`;
                        }
                        if (value == 21) {
                            return `已买断`;
                        }
                        if (value == 24) {
                            return `还款逾期中`;
                        }
                        if (value == 27) {
                            return `自动取消`;
                        }
                        if (value == 10000) {
                            return `用户删除订单`;
                        }
                    },
                },
                下单时间: "AddDTime",
                收件人名字: "Receiver",
                收件人电话: "Mobile",
                身份证号码: "LicenseID",
                收件人地址: "Address",
                // Telephone: "phone.mobile",
                // "Telephone 2": {
                // field: "phone.landline",
                //     callback: (value) => {
                //     return `Landline Phone - ${value}`;
                // },
                // },
            },
            json_data: [
                //{
                //name: "Tony Peña",
                //city: "New York",
                // phone: {
                //     mobile: "1-541-754-3010",
                //     landline: "(541) 754-3010",
                // },
                //},
                //{
                //name: "Thessaloniki",
                //city: "Athens",
                // phone: {
                //     mobile: "+1 855 275 5071",
                //     landline: "(2741) 2621-244",
                // },
                //},
            ],
            json_meta: [
                [
                    {
                        key: "charset",
                        value: "utf-8",
                    },
                ],
            ],
            bill: {
                id: "",
                billno: "",
                orderid: "",
                outtradeno: "",
                refund: "",
                type: "",
                index: "",
            },
            active: ref("0"),
            cur_guarant: "",
            zu: "0",
            gui: "0",
            yu: "0",
            shen: "0",
            fu: "0",
            fa: "0",
            shou: "0",
            huan: "0",
            wan: "0",
            duan: "0",
            yi: "0",
            qu: "0",
            weifukuan: "0",
            arraypost: [],
            dialogImageUrl1: ref(""),
            dialogVisible1: ref(false),
            dialogImageUrl4: ref(""),
            dialogVisible4: ref(false),
            lease: ref(""),
            pdfurl: ref(""),
            downloadfilename: ref(""),
            hetong: ref(false),
            dis: ref(true),
            fileList1: ref(""),
            fileList2: ref(""),
            uploadcard: ref(false),
            insurance: ref(false),
            ht: ref(false),
            operator: ref(""),
            activeName: ref("0"),
            problem: problem.items,
            trades: ref(""),
            trade: ref(false),
            visible: ref(false),
            curproof: "",
            uploadproof: ref(false),
            addid: ref(false),
            idimg1: [],
            idimg2: [],
            id1: ref(false),
            id2: ref(false),
            ImageUrl1: "",
            ImageUrl2: "",
            title: "",
            expName: ref(""),
            danhao: ref(""),
            number: ref(""),
            savelog: ref(""),
            hint: false,
            id: ref(false),
            curorderstatus: "",
            wxtype: "",
            excuting: false,
            orderid: "",
            extendop: 1,
            extendinfo: {
                ID: "",
                Status: "",
                OrderUID: "",
                RentDay: "",
                ReturnDTime: "",
                ResignDTime: "",
                ShouldReturnDTime: "",
                extendday: 0,
                newreturn: "",
                remark: "",
                extype: 1,
            },
            viewev1: "",
            extendorder: false,
            editorder: false,
            isshow: "0",
            curriskmsg: "",
            curindex: "",
            editrisk: false,
            selmerchant: [],
            selagent: [],
            selpartner: [],
            transfertitle: ["商户列表", "已选商户"],
            transfertitle2: ["代理商列表", "已选代理商"],
            transfertitle3: ["分销商列表", "已选分销商"],
            merchantlist: [],
            merchantlist2: [],
            merchantlist3: [],
            merchant: false,
            agent: false,
            partner: false,
            load: false,
            confirmreceive: false,
            receiveday: "",
            reportorder: "",
            curreport: [],
            tableData: [],
            checkreport: false,
            curpage: 1,
            pagesize: 10,
            totalcount: "",
            totalcount1: 0,
            addremark: false,
            sremark: "",
            curorderid: "",
            orderuid: "",
            report: false,
            estimateinfo: {
                orderid: "",
                type: "",
                PdtImg2: [],
                PdtImg3: [],
                TotalCompensation: 0,
                Compensation: 0,
                CompensationDecrease: 0,
                TestType: "1",
            },
            plan_deposit: 0.0,
            plan_sdeposit: 0.0,
            plan_rdeposit: 0.0,
            plan_replenish: 0.0,
            deposit: 0.0,
            depositinfo: false,
            keyword: "",
            range: [],
            prepayinfo: {
                orderid: "",
                pbino: [],
                isprepay: 2,
                result: "",
                tip: ref(false),
                replenish: 0,
                item: "",
            },
            rentorder: [],
            prepay: false,
            dialogImageUrl: "",
            dialogVisible: false,
            dialogImageUrl2: "",
            dialogVisible2: false,
            dialogImageUrl3: "",
            dialogVisible3: false,
            FileList: [],
            FileList2: [],
            FileList3: [],
            FileListid1: [],
            FileListid2: [],
            upload_url: constant.upload_temp_img_url,
            uploadfile: constant.uploadfile,
            additioninfo: {
                orderid: "",
                type: "",
                money: "",
                PdtImg: [],
                status: "",
                buyoutprice: "",
                needbuyoutprice: "",
            },
            addition: false,
            receipt: false,
            receiptdata: [],
            customeraddr: false,
            logcom: [],
            returnid: [],
            provinces: [],
            citys: [],
            areas: [],
            logistic: false,
            cancelorder: false,
            shortcuts: [
                {
                    text: "最近一周",
                    value: () => {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        return [start, end];
                    },
                },
                {
                    text: "最近一个月",
                    value: () => {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                        return [start, end];
                    },
                },
                {
                    text: "最近三个月",
                    value: () => {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                        return [start, end];
                    },
                },
            ],
            ordertype: "1",
            status: "0",
            type: "1",
            cancelinfo: {
                id: "",
                type: "",
                reason: "",
                penalty: "",
                haspenalty: 1,
                first: "",
                item: "",
            },
            logisticinfo: {
                orderid: "",
                logisticdata: [],
                returnid: "1",
                item: [],
            },
            cancelrule: {
                type: [
                    {
                        required: true,
                        message: "必须选择一个取消类型",
                        trigger: "blur",
                    },
                ],
            },
            options: [
                {
                    value: 0,
                    label: "快递出库存证",
                },
                {
                    value: 1,
                    label: "用户身份证存证",
                },
                { value: 2, label: "双方补充协议存证" },
                { value: 3, label: "用户人脸存证" },
                { value: 4, label: "用户线下付款存证" },
                { value: 5, label: "催缴证据存证" },
                { value: 6, label: "其他证据存证" },
                { value: 7, label: "企业信息存证" },
                { value: 8, label: "自定义存证" },
            ],
            value: ref(""),
            options2: [
                {
                    value: 0,
                    label: "图片类型",
                    tip: "请上传图片文件(jpg、png、jpeg、gif)",
                },
                {
                    value: 1,
                    label: "文件类型",
                    tip: "请上传文件(txt、doc、xls、pdf、zip格式)",
                },
                {
                    value: 2,
                    label: "音频类型",
                    tip: "请上传音频文件(mp3、ogg、m4a、aac)",
                },
                {
                    value: 3,
                    label: "视频类型",
                    tip: "请上传视频文件(mp4、mpeg、WebM格式)",
                },
            ],
            value2: ref(""),
            remarkitem: [],
            tip: ref(""),
            ev: false,
            ev2: "",
            ev3: false,
            addrinfo: {},
            orderdata: [],
            offline: false,
            offlineinfo: {
                returntype: "2",
                orderid: "",
                shippingcom: "",
                shippingno: "",
            },
            logisticdetail: false,
            returnlog: false,
            returnlogtable: [],
            oplogdialog: false,
            oplogdata: [],
            imgUrlArray: [],
            dialogloading: false,
            alterorderinfo: {
                OrderUID: "",
                Status: "",
                TotalOrderMoney: "",
                RentDay: "",
                alterrentday: 0,
                alterordermoney: 0,
            },
            centerDialogVisible: false,
            viewevidence: [],
            evidence: false,
            tradebill: false,
            orderbill: false,
            tradedata: [],
            tra_curpage: 1,
            tra_totalcount: "",
            billdata: [],
            question: require("/src/assets/image/question.png"),
            idhide: require("/src/assets/image/idhide.png"),
            idshow: require("/src/assets/image/idshow.png"),
            copy: require("/src/assets/image/copy.png"),
            urlImg: [],
            hetongurl: ref(""),
            cunzhengurl: ref(""),
            summarydata: [
                {
                    overdue: 0,
                    mitigate: 0,
                    needoverdue: 0,
                    payoverdue: 0,
                },
            ],
            loginid: ref(),
            offlineitem: [],
            rentcount: ref(),
            db: false,
            pinned: ref(0),
            fkMemberID: ref(""),
            renzheng: ref(false),
        };
    },
    created: function () {
        this.ismerchant = Cookies.get("IsMerchant");
        this.mid = Cookies.get("mid");
        console.log(this.mid)
        this.loginid = Cookies.get("id");
        var curpage = this.$route.query.curpage;
        console.log("ret--" + curpage);
        if (curpage) this.curpage = curpage;
        var pagesize = this.$route.query.pagesize;
        if (pagesize) this.pagesize = pagesize;
        var status = this.$route.query.status;
        if (status) this.status = status;
        var range = this.$route.query.range;
        if (range) this.range = JSON.parse(range);
        var keyword = this.$route.query.keyword;
        if (keyword) this.keyword = keyword;
        var type = this.$route.query.type;
        if (type) this.type = type;
        if (this.tab_for.length == 0) {
            this.loading = true;
        }
        this.init();
        /*
                this.axios.get(constant.get_order_list,{
                  headers: {
                    "Content-Type": "application/json",
                  },
                  params:{
                    curpage:this.curpage,
                    pagesize:this.pagesize,
                  }
                }).then((response)=>{
                  console.log(response.data);
                  this.orderdata = response.data.list;
                  this.curpage = response.data.curpage*1;
                  this.totalcount = response.data.totalcount*1;
                })*/
        this.axios
            .get(constant.get_operator_control, {
                headers: {
                    "Content-Type": "application/json",
                },
                params: {
                    operatorid: Cookies.get("session"),
                },
            })
            .then((response) => {
                console.log(response.data);
                console.log(this.operator);
                this.operator = response.data[0]["OperatorName"];
                console.log(this.operator);
            });
        this.axios
            .get(constant.get_cascader_url, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                console.log(response.data);
                this.option = response.data;
            });
        this.axios
            .get(constant.get_region, {
                headers: {
                    "Content-Type": "application/json",
                },
                params: {
                    parentid: 1,
                },
            })
            .then((response) => {
                console.log(response.data);
                this.provinces = response.data;
            });
        //this.GetReturnAddr();
        //商户列表
        this.axios
            .get(constant.allmerchant, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                console.log("商户列表");
                console.log(response.data);
                let list = response.data;
                this.merchantlist = [];
                for (let i = 0; i < list.length; i++) {
                    let obj = new Object();
                    obj.label = list[i]["Merchant"];
                    obj.key = list[i]["ID"];
                    this.merchantlist.push(obj);
                }
                console.log(this.merchantlist);
            });
        //代理商列表
        this.axios
            .get(constant.getagent, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                console.log("商户列表");
                console.log(response.data);
                let list = response.data;
                this.merchantlist2 = [];
                for (let i = 0; i < list.length; i++) {
                    let obj = new Object();
                    obj.label = list[i]["Merchant"];
                    obj.key = list[i]["ID"];
                    this.merchantlist2.push(obj);
                }
                console.log(this.merchantlist2);
            });
        //分销商列表
        this.axios
            .get(constant.getallpartner, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                console.log("商户列表");
                console.log(response.data);
                let list = response.data;
                this.merchantlist3 = [];
                for (let i = 0; i < list.length; i++) {
                    let obj = new Object();
                    obj.label = list[i]["storename"];
                    obj.key = list[i]["id"];
                    this.merchantlist3.push(obj);
                }
                console.log(this.merchantlist3);
            });
    },
    methods: {
        dysave() {
            if (this.dy.RealName == "" || this.dy.LicenseID == "") {
                this.$message.error("姓名且身份证号不能空");
                return;
            } else {
                this.dy.memberid = this.fkMemberID;
                this.dy.LicenseImg = "";
                console.log(this.dy);
                this.axios
                    .post(
                        "https://xuanzj.xianniuzu.com/alimini/index.php?entry=realname&op=savememberinfo&memberid=" +
                        this.fkMemberID,
                        { postdata: this.dy },
                        {
                            headers: {
                                "Content-Type": "application/x-www-form-urlencoded",
                            },
                        }
                    )
                    .then((response) => {
                        console.log(response.data);
                        if (response.data.code == "SUCCESS") {
                            this.$message.success("操作成功");
                            this.item.RealName = this.dy.RealName;
                            this.item.LicenseID = this.dy.LicenseID;
                            this.renzheng = false;
                        }
                    });
            }
        },
        authentication(item) {
            console.log(item.fkMemberID);
            this.item = item;
            this.fkMemberID = item.fkMemberID;
            this.renzheng = true;
        },
        selectdb(value) {
            console.log(value);
            this.label = value.label;
        },
        signcontract(FlowIds, isFlow) {
            console.log(FlowIds);
            if (isFlow == 0) {
                this.$message.error("该订单未签署合同");
                return false;
            }
            this.axios
                .get(constant.DescribeResourceUrlsByFlows, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    params: {
                        flowid: FlowIds,
                    },
                })
                .then((response) => {
                    console.log(response.data);
                    if (response.data.FlowResourceUrlInfos.length > 0) {
                        // window.location.href = response.data.FlowResourceUrlInfos[0].ResourceUrlInfos[0].Url
                        window.open(
                            response.data.FlowResourceUrlInfos[0].ResourceUrlInfos[0].Url,
                            "_blank"
                        );
                    } else {
                        this.$message.error("该订单未签署合同");
                        return false;
                    }
                });
        },
        dbchange() {
            const selectedOption = this.dboptions.find(
                (option) => option.value === this.dbvalue
            );
            // Access the desired property (e.g., customProperty)
            if (selectedOption) {
                this.pinned = selectedOption.desc;
                // Perform any other actions with the custom property
            }
        },
        Pinned(val) {
            console.log(this.dbvalue);
            if (this.dbvalue == "") {
                this.$message.error("请选择置顶商户");
                return false;
            }
            this.axios
                .get(constant.pinned, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    params: {
                        id: this.dbvalue,
                        val: val,
                    },
                })
                .then((response) => {
                    if (response.data.code == 200) {
                        this.$message.success("操作成功");
                    } else {
                        this.$message.error("操作失败");
                    }
                });
        },
        deleteflow(id) {
            console.log(id);
            ElMessageBox.confirm("确定删除?", "提示", {
                confirmButtonText: "确认",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    this.axios.get(constant.deleteflow, {
                        headers: {
                            "Content-Type": "application/json",
                        },
                        params: {
                            id: id,
                        },
                    });
                })
                .then((response) => {
                    console.log(response);
                    if (response.code == 200) {
                        this.tradebill = false;
                        this.$message.success("删除成功");
                    } else {
                        this.$message.error("删除失败");
                    }
                });
        },
        rainrisk(orderid, LicenseID, item) {
            console.log(orderid, LicenseID, item);
            if (LicenseID == "") {
                this.$message.error("请上传身份证号");
                return false;
            }
            this.axios
                .get(constant.jindun, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    params: {
                        orderid: orderid,
                        LicenseID: LicenseID,
                    },
                })
                .then((res) => {
                    console.log(res.data);
                    var j = res.data;
                    if (j["header"]["status"] == "success") {
                        this.$message.success("获取成功");
                        item.rainrisk =
                            j["body"]["data"]["b0647"]["items"][0]["result"][0]["risk_level"];
                    } else {
                        this.$message.error("获取失败");
                    }
                });
        },
        editprice(defaultValue) {
            ElMessageBox.prompt("请输入你要修改的金额", "提示", {
                roundButton: true,
                inputValue: defaultValue,
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                inputPattern: /^[+]?([0-9]+(?:[.][0-9]*)?|\.[0-9]+)$/,
                inputErrorMessage: "输入正数",
            })
                .then(({ value }) => {
                    console.log(value, this.aduid);
                    this.axios
                        .get(constant.editprice, {
                            headers: {
                                "Content-Type": "application/json",
                            },
                            params: {
                                aduid: this.aduid,
                                margin: value,
                            },
                        })
                        .then((response) => {
                            if (response.data.code == 200) {
                                this.$message.success("修改成功");
                                this.audit = false;
                            } else {
                                this.$message.error("修改失败");
                            }
                        });
                })
                .catch(() => {
                    ElMessage({
                        type: "info",
                        message: "取消修改",
                    });
                });
        },
        saveywy2() {
            this.axios
                .get(constant.saveywy, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    params: {
                        orderid: this.ywyorderid,
                        ywyid: this.ywyid,
                    },
                })
                .then((response) => {
                    if (response.data.code == 200) {
                        this.$message.success("保存成功");
                        this.saveywy1 = false;
                    } else {
                        this.$message.error("保存失败");
                    }
                });
        },
        saveywy(orderid) {
            this.ywyorderid = orderid;
            this.saveywy1 = true;
            this.ywyoptions = [];
            this.axios.get(constant.getywy).then((response) => {
                let r = response.data;
                for (var i = 0; i < r.length; i++) {
                    this.ywyoptions.push({ value: r[i].id, label: r[i].ywyname });
                }
            });
        },
        fenxiao(id) {
            console.log(id);
            this.fenxiaomodel = true;
            this.axios
                .get(constant.getstore, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    params: {
                        orderid: id,
                    },
                })
                .then((response) => {
                    if (response.data.code == 200) {
                        this.storedata = response.data.data;
                    } else {
                        this.$message.error("获取数据失败!");
                    }
                });
        },
        tuikuan(id, money, type) {
            if (type == 1) {
                ElMessageBox.confirm("确认退款" + money + "元?", "警告", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then((response) => {
                    console.log(response.data, id);
                    if (this.wxtype == 1) {
                        this.axios
                            .get(constant.wxyajinrefund, {
                                headers: {
                                    "Content-Type": "application/json",
                                },
                                params: {
                                    id: id,
                                    type: type,
                                    orderid: this.neworderid,
                                },
                            })
                            .then((response) => {
                                console.log(response.data);
                                if (response.data.ret_code == "0000") {
                                    this.$message.success("退款成功" + money + "元");
                                    this.deposit2 = false;
                                } else {
                                    this.$meesage.error("退款失败,请联系管理员");
                                }
                            });
                    } else {
                        this.axios
                            .get(constant.tuikuan, {
                                headers: {
                                    "Content-Type": "application/json",
                                },
                                params: {
                                    id: id,
                                    orderid: this.neworderid,
                                },
                            })
                            .then((response) => {
                                console.log(response.data);
                                if (response.data.code == 200) {
                                    this.$message.success("退款成功" + money + "元");
                                    this.deposit2 = false;
                                } else {
                                    this.$meesage.error("退款失败,请联系管理员");
                                }
                            });
                    }
                });
            } else {
                ElMessageBox.prompt("请输入退款金额", "提示", {
                    confirmButtonText: "确认",
                    cancelButtonText: "取消",
                    inputPattern: /^[1-9]\d*$/,
                    inputErrorMessage: "无效金额",
                }).then(({ value }) => {
                    if (this.wxtype == 1) {
                        this.axios
                            .get(constant.wxyajinrefund, {
                                headers: {
                                    "Content-Type": "application/json",
                                },
                                params: {
                                    id: id,
                                    type: type,
                                    money: value,
                                    orderid: this.neworderid,
                                },
                            })
                            .then((response) => {
                                console.log(response.data);
                                if (response.data.ret_code == "0000") {
                                    this.$message.success("退款成功" + value + "元");
                                    this.deposit2 = false;
                                } else {
                                    this.$meesage.error("退款失败,请联系管理员");
                                }
                            });
                    } else {
                        this.axios
                            .get(constant.tuikuan, {
                                headers: {
                                    "Content-Type": "application/json",
                                },
                                params: {
                                    id: id,
                                    orderid: this.neworderid,
                                },
                            })
                            .then((response) => {
                                console.log(response.data);
                                if (response.data.code == 200) {
                                    this.$message.success("退款成功" + value + "元");
                                    this.deposit2 = false;
                                } else {
                                    this.$meesage.error("退款失败,请联系管理员");
                                }
                            });
                    }
                });
            }
        },
        remoteMethod(query) {
            console.log(query);
            this.parts = [];
            this.axios
                .get(constant.getpart, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    params: {
                        orderid: this.partorderid,
                        keyword: query,
                    },
                })
                .then((response) => {
                    console.log(response.data);
                    if (response.data.code == 200) {
                        for (var i = 0; i < response.data.data.length; i++) {
                            this.parts.push({
                                label: response.data.data[i].storename,
                                value: response.data.data[i].id,
                            });
                        }
                        console.log(this.parts);
                    } else {
                        this.$message.error("没有搜索到相关数据");
                    }
                });
        },
        savedp() {
            console.log(this.partorderid, this.vv);
            this.axios
                .get(constant.savedp, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    params: {
                        orderid: this.partorderid,
                        partid: this.vv,
                    },
                })
                .then((response) => {
                    if (response.data.code == 200) {
                        this.$message.success("更改成功");
                        this.dialog = false;
                    } else {
                        this.$meesage.error("更改失败");
                    }
                });
        },
        changedp(id) {
            this.partorderid = id;
            console.log(id);
            this.parts = [];
            this.dialog = true;
            this.axios.get(constant.getpart).then((response) => {
                console.log(response.data);
                if (response.data.code == 200) {
                    for (var i = 0; i < response.data.data.length; i++) {
                        this.parts.push({
                            label: response.data.data[i].storename,
                            value: response.data.data[i].id,
                        });
                    }
                    console.log(this.parts);
                } else {
                    this.$message.error("获取失败");
                }
            });
        },
        reject() {
            this.axios
                .get(constant.reject, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    params: {
                        orderid: this.jiaofuorderid,
                    },
                })
                .then((response) => {
                    console.log(response.data);
                    if (response.data.code == 200) {
                        this.$message.success("驳回成功");
                        this.jiaofudetail = false;
                    } else {
                        this.$message.error("驳回失败");
                    }
                });
        },
        pass() {
            this.axios
                .get(constant.getaudit, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    params: {
                        orderid: this.jiaofuorderid,
                    },
                })
                .then((response) => {
                    console.log(response.data.code);
                    if (response.data.code == 200) {
                        ElMessageBox.confirm("确认交付?", "提示", {
                            confirmButtonText: "确认",
                            cancelButtonText: "取消",
                            type: "warning",
                        })
                            .then(() => {
                                this.axios
                                    .get(constant.jiaofuconfirm, {
                                        headers: {
                                            "Content-Type": "application/json",
                                        },
                                        params: {
                                            orderid: this.jiaofuorderid,
                                        },
                                    })
                                    .then((response) => {
                                        console.log(response.data);
                                        if (response.data.code == 200) {
                                            this.$message.success("交付成功");
                                            this.jiaofudetail = false;
                                        } else {
                                            this.$message.error("交付失败");
                                        }
                                    });
                            })
                            .catch(() => { });
                    } else {
                        this.$message.error("暂时没有审核资料,无法交付");
                    }
                });
            console.log(this.jiaofuorderid);
        },
        jiaofu(orderid) {
            console.log(orderid);
            this.jiaofuorderid = orderid;
            this.axios
                .get(constant.getjiaofu, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    params: {
                        orderid: orderid,
                    },
                })
                .then((response) => {
                    console.log(response.data);
                    if (response.data.code == 200) {
                        this.jiaofuimage = response.data.data;
                        this.jiaofudetail = true;
                    } else {
                        this.$message.error("暂无数据");
                    }
                });
        },
        savedb() {
            console.log(this.dbvalue, this.orderiddb);
            if (this.dbvalue == "") {
                this.$message.error("请选择调拨商家");
                return false;
            }
            this.axios
                .get(constant.savedb, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    params: {
                        id: this.dbvalue,
                        wxtype: this.wxtype,
                        orderid: this.orderiddb,
                    },
                })
                .then((data) => {
                    if (data.data.code == 200) {
                        this.$message.success("调拨成功");
                        this.db = false;
                        this.item.Merchant = this.label;
                        this.item.allot = 1;
                    } else {
                        this.$message.error("调拨失败");
                    }
                });
        },
        diaobo(item, wxtype) {
            console.log(wxtype);
            this.wxtype = wxtype;
            this.db = true;
            this.item = item;
            this.orderiddb = item.ID;
            this.axios.get(constant.allmerchant).then((data) => {
                console.log(data.data);
                this.dboptions = [];
                for (let i = 0; i < data.data.length; i++) {
                    this.dboptions.push({
                        label: data.data[i].Merchant,
                        value: data.data[i].ID,
                        desc: data.data[i].pinned,
                    });
                }
            });
        },
        infolist2(orderid) {
            this.axios
                .get(constant.getaudit, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    params: {
                        orderid: orderid,
                    },
                })
                .then((response) => {
                    console.log(response.data.code);
                    if (response.data.code == 200) {
                        this.infolist = true;
                        this.auditinfo2 = response.data.data;
                    } else {
                        this.$message.error("暂时没有审核资料");
                    }
                });
        },
        viewaudit(id, orderid) {
            this.aduid = id;
            this.infolist = false;
            this.audit = true;
            this.axios
                .get(constant.getaudit2, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    params: {
                        id: id,
                        orderid: orderid,
                    },
                })
                .then((response) => {
                    console.log(response.data);
                    if (response.data.code == 200) {
                        this.audit = true;
                        this.auditinfo = response.data.data.result1;
                        this.memberinfo = response.data.data.result2;
                        console.log(this.memberinfo);
                    } else {
                        this.$message.error("暂时没有审核资料");
                    }
                });
        },
        deposit3() {
            this.deposit2 = false;
        },
        newdeposit(orderid, wxtype) {
            console.log(orderid);
            this.neworderid = orderid;
            this.wxtype = wxtype;
            this.axios
                .get(constant.newdeposit, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    params: {
                        orderid: orderid,
                    },
                })
                .then((response) => {
                    if (response.data.code == 200) {
                        console.log(response.data);
                        this.deposit2 = true;
                        this.deposit4 = response.data.data;
                    } else {
                        this.$message.error("数据获取失败");
                    }
                });
        },
        bohui4() {
            if (this.bohui3 == "") {
                ElMessage({
                    type: "error",
                    message: "请选择驳回原因",
                });
                return false;
            } else {
                this.axios
                    .get(constant.bohui, {
                        headers: {
                            "Content-Type": "application/json",
                        },
                        params: {
                            orderid: this.orderids,
                        },
                    })
                    .then((response) => {
                        if (response.data.code == 200) {
                            ElMessage({
                                type: "success",
                                message: response.data.msg,
                            });
                        } else {
                            ElMessage({
                                type: "error",
                                message: response.data.msg,
                            });
                        }
                        this.bohui2 = false;
                        console.log(response.data);
                    });
            }
        },
        bohui(orderid) {
            this.bohui2 = true;
            console.log(orderid);
            this.orderids = orderid;
        },
        xuzuupload(data) {
            console.log(data);
            this.imgurl = data.url;
        },
        reletfunction() {
            console.log("续租");
            if (this.xuzutime == "") {
                ElMessage({
                    type: "warning",
                    message: "续租时间不能为空",
                });
                return false;
            }
            this.axios
                .get(constant.relet, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    params: {
                        orderid: this.Object1.ID,
                        day: this.xuzutime,
                        money: this.rentmoney,
                        imgurl: this.imgurl,
                        remark: this.textarea,
                    },
                })
                .then((response) => {
                    console.log(response.data);
                    if (response.data.code == 200) {
                        ElMessage({
                            type: "success",
                            message: response.data.msg,
                        });
                    } else {
                        ElMessage({
                            type: "error",
                            message: response.data.msg,
                        });
                    }
                    this.xuzu = false;
                });
        },
        disabledDate(time) {
            if (this.xuzutime == "") {
                return false;
            }
            const dd = Date.parse(new Date(time));
            if (dd >= this.xuzutime * 1) {
                ElMessage({
                    type: "warning",
                    message: "选择的时间必须大于起始时间",
                });
                this.xuzutime = "";
                return false;
            } else {
                console.log(this.Object1);
                this.xuzu2 = (this.xuzutime * 1 - dd) / (1000 * 24 * 60 * 60);
                this.rentmoney = (
                    (this.Object1.TotalOrderMoney / this.Object1.RentDay) *
                    this.xuzu2 *
                    (this.Object1.premiumrate * 1)
                ).toFixed(2);
                return false;
            }
        },
        relet(item) {
            this.xuzu = true;
            this.xuzu2 = "";
            this.rentmoney = "";
            this.xuzutime = "";
            this.Object1 = item;
            console.log(this.Object1);
        },
        editsss() {
            if (this.statuss == "") {
                ElMessage({
                    type: "warning",
                    message: "请选择你要修改的状态",
                });
                return false;
            }
            this.axios
                .get(constant.editstatus, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    params: {
                        orderid: this.orderidd,
                        status: this.statuss,
                    },
                })
                .then((response) => {
                    console.log(response);
                    if (response.data.code == 200) {
                        ElMessage({
                            type: "success",
                            message: "修改成功",
                        });
                        this.editss = false;
                    } else {
                        ElMessage({
                            type: "error",
                            message: "修改失败",
                        });
                    }
                });
        },
        editstatus(orderid, type) {
            console.log(type);
            let wenan = "";
            let status = "";
            if (type == 7) {
                this.orderidd = orderid;
                this.editss = true;
                return false;
            } else if (type == "gui") {
                wenan = "《待收货》";
                status = 5;
            }
            console.log(status);
            ElMessageBox.confirm(`确定要修改为${wenan}吗?`, "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    console.log(orderid, status);
                    this.axios
                        .get(constant.editstatus, {
                            headers: {
                                "Content-Type": "application/json",
                            },
                            params: {
                                orderid: orderid,
                                status: status,
                            },
                        })
                        .then((response) => {
                            console.log(response);
                            if (response.data.code == 200) {
                                ElMessage({
                                    type: "success",
                                    message: "修改成功",
                                });
                            } else {
                                ElMessage({
                                    type: "error",
                                    message: "修改失败",
                                });
                            }
                        });
                })
                .catch(() => {
                    ElMessage({
                        type: "info",
                        message: "修改取消",
                    });
                });
        },
        getrefund(billid) {
            console.log(billid);
            this.axios
                .get(constant.getrefundbill, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    params: {
                        billid: billid,
                    },
                })
                .then((response) => {
                    if (response.data.code == 200) {
                        console.log(response.data.data);
                        this.partrefund = true;
                        this.refunddetail = response.data.data;
                    } else {
                        ElMessage({
                            type: "error",
                            message: "数据错误，请联系开发人员",
                        });
                    }
                });
        },
        confirmrefund() {
            console.log(this.refundmoney, this.paymoney, this.wxtype);
            this.footer1 = false;
            this.footer2 = true;
            let that = this;
            if (this.wxtype == 1) {
                console.log(this.bill);
                // if(this.bill.index > 0){
                this.axios
                    .post(
                        constant.lianliansectionrefund,
                        JSON.stringify({
                            id: this.bill.id,
                            billno: this.bill.billno,
                            paymoney: this.refundmoney,
                            amount: this.paymoney,
                            outtradeno: this.bill.outtradeno,
                            orderid: this.bill.orderid,
                            type: this.bill.type,
                        }),
                        { headers: { "content-type": "application/x-www-form-urlencoded" } }
                    )
                    .then((response) => {
                        console.log(response.data);
                        let data = response.data;
                        if (
                            data.refund_status == "PROCESSING" ||
                            data.refund_status == "SUCCESS"
                        ) {
                            ElMessage({
                                type: "success",
                                message: `退款已成功提交,3秒左右到账`,
                            });
                            this.orderbill = false;
                        } else {
                            ElMessage({
                                type: "warning",
                                message: "退款失败",
                            });
                        }
                    });
                // }else{
                // 	this.axios
                // 	.post(
                // 		constant.wxfullrefund,
                // 		JSON.stringify({
                // 			id: this.bill.id,
                // 			billno: this.bill.billno,
                // 			paymoney: this.refundmoney,
                // 			amount: this.paymoney,
                // 			outtradeno: this.bill.outtradeno,
                // 			orderid: this.bill.orderid,
                // 			type: this.bill.type,
                // 		}),
                // 		{ headers: { "content-type": "application/x-www-form-urlencoded" } }
                // 	)
                // 	.then((response) => {
                // 		console.log(response.data);
                // 		let data = JSON.parse(response.data);
                // 		if (data.status == "PROCESSING" || data.status == "SUCCESS") {
                // 			ElMessage({
                // 				type: "success",
                // 				message: `退款已成功提交,3秒左右到账`,
                // 			});
                // 			this.orderbill = false;
                // 		} else {
                // 			ElMessage({
                // 				type: "warning",
                // 				message: "退款失败",
                // 			});
                // 		}
                // 	});
                // }
            } else if (this.wxtype == 3) {
                this.axios
                    .post(
                        constant.dyfullrefund,
                        {
                            id: this.bill.id,
                            billno: this.bill.billno,
                            total_amount: this.refundmoney,
                            orderid: this.bill.orderid,
                            type: this.bill.type,
                            out_order_no: this.bill.outtradeno,
                        },
                        {
                            headers: {
                                "Content-Type": "application/x-www-form-urlencoded",
                            },
                        }
                    )
                    .then((response) => {
                        console.log(response.data);
                        if (response.data.err_no == 0) {
                            ElMessage({
                                type: "success",
                                message: `退款成功,退款金额为${that.refundmoney}元`,
                            });
                            this.orderbill = false;
                            this.refundmodel = false;
                        } else {
                            ElMessage({
                                type: "warning",
                                message: "退款失败",
                            });
                        }
                    });
            } else {
                this.axios
                    .get(constant.fullrefund, {
                        headers: {
                            "Content-Type": "application/json",
                        },
                        params: {
                            id: this.bill.id,
                            billno: this.bill.billno,
                            paymoney: this.refundmoney,
                            orderid: this.bill.orderid,
                            type: this.bill.type,
                        },
                    })
                    .then((response) => {
                        console.log(response.data);
                        if (response.data.code == 10000) {
                            ElMessage({
                                type: "success",
                                message: `退款成功,退款金额为${this.refundmoney}元`,
                            });
                            this.refundmodel = false;
                        } else {
                            ElMessage({
                                type: "warning",
                                message: "退款失败",
                            });
                        }
                    });
            }
        },
        refund(id, orderid, paymoney, billno, type, outtradeno, index) {
            this.footer1 = true;
            this.footer2 = false;
            console.log(
                this.wxtype,
                "----",
                id,
                orderid,
                paymoney,
                billno,
                type,
                outtradeno,
                index
            );
            if (this.wxtype == 1) {
                if (type == 1) {
                    // if(index == 0){
                    // 	ElMessageBox.confirm(`确定退全款(${paymoney}元)？`, "警告", {
                    // 		confirmButtonText: "确认",
                    // 		cancelButtonText: "取消",
                    // 		type: "warning",
                    // 	}).then(() => {
                    // 		this.axios
                    // 			.post(
                    // 				constant.lianlianwxfullrefund,
                    // 				JSON.stringify({
                    // 					id: id,
                    // 					billno: billno,
                    // 					paymoney: paymoney,
                    // 					amount: paymoney,
                    // 					orderid: orderid,
                    // 					outtradeno: outtradeno,
                    // 					type: type,
                    // 				}),
                    // 				{
                    // 					headers: {
                    // 						"content-type": "application/x-www-form-urlencoded",
                    // 					},
                    // 				}
                    // 			)
                    // 			.then((response) => {
                    // 				console.log(response.data);
                    // 				let data = JSON.parse(response.data);
                    // 				if (data.status == "PROCESSING" || data.status == "SUCCESS") {
                    // 					ElMessage({
                    // 						type: "success",
                    // 						message: `退款已成功提交,3秒左右到账`,
                    // 					});
                    // 					this.orderbill = false;
                    // 					this.refundmodel = false;
                    // 				} else {
                    // 					ElMessage({
                    // 						type: "warning",
                    // 						message: "退款失败",
                    // 					});
                    // 				}
                    // 			});
                    // 	})
                    // }else{
                    ElMessageBox.confirm(`确定退全款(${paymoney}元)？`, "警告", {
                        confirmButtonText: "确认",
                        cancelButtonText: "取消",
                        type: "warning",
                    }).then(() => {
                        this.load = true;
                        this.axios
                            .post(
                                constant.lianlianfullrefund,
                                JSON.stringify({
                                    id: id,
                                    billno: billno,
                                    paymoney: paymoney,
                                    amount: paymoney,
                                    orderid: orderid,
                                    outtradeno: outtradeno,
                                    type: type,
                                }),
                                {
                                    headers: {
                                        "content-type": "application/x-www-form-urlencoded",
                                    },
                                }
                            )
                            .then((response) => {
                                console.log(response.data);
                                let data = response.data;
                                if (
                                    data.refund_status == "PROCESSING" ||
                                    data.refund_status == "SUCCESS"
                                ) {
                                    ElMessage({
                                        type: "success",
                                        message: `退款已成功提交,3秒左右到账`,
                                    });
                                    this.load = false;
                                    this.orderbill = false;
                                    this.refundmodel = false;
                                } else {
                                    ElMessage({
                                        type: "warning",
                                        message: "退款失败",
                                    });
                                }
                            });
                    });
                    // }
                } else {
                    console.log(type, billno);
                    this.refundmodel = true;
                    this.axios.get(constant.searchrefund, {}).then((response) => {
                        console.log(response);
                    });
                    this.bill.id = id;
                    this.bill.orderid = orderid;
                    this.bill.billno = billno;
                    this.bill.outtradeno = outtradeno;
                    this.bill.type = type;
                    this.bill.index = index;
                    this.paymoney = paymoney;
                }
            } else if (this.wxtype == 3) {
                if (type == 1) {
                    ElMessageBox.confirm(`确定退全款(${paymoney}元)？`, "警告", {
                        confirmButtonText: "确认",
                        cancelButtonText: "取消",
                        type: "warning",
                    })
                        .then(() => {
                            this.axios
                                .post(
                                    constant.dyfullrefund,
                                    {
                                        id: id,
                                        billno: billno,
                                        total_amount: paymoney,
                                        orderid: orderid,
                                        type: type,
                                        out_order_no: outtradeno,
                                    },
                                    {
                                        headers: {
                                            "Content-Type": "application/x-www-form-urlencoded",
                                        },
                                    }
                                )
                                .then((response) => {
                                    console.log(response.data);
                                    if (response.data.err_no == 0) {
                                        ElMessage({
                                            type: "success",
                                            message: `退款成功,退款金额为${paymoney}元`,
                                        });
                                        this.orderbill = false;
                                    } else {
                                        ElMessage({
                                            type: "warning",
                                            message: "退款失败",
                                        });
                                    }
                                });
                        })
                        .catch(() => {
                            ElMessage({
                                type: "info",
                                message: "取消退款",
                            });
                        });
                } else {
                    console.log(type, billno);
                    this.refundmodel = true;
                    this.bill.id = id;
                    this.bill.orderid = orderid;
                    this.bill.billno = billno;
                    this.bill.type = type;
                    this.bill.outtradeno = outtradeno;
                    this.bill.paymoney = paymoney;
                }
            } else {
                if (type == 1) {
                    ElMessageBox.confirm(`确定退全款(${paymoney}元)？`, "警告", {
                        confirmButtonText: "确认",
                        cancelButtonText: "取消",
                        type: "warning",
                    })
                        .then(() => {
                            this.axios
                                .get(constant.fullrefund, {
                                    headers: {
                                        "Content-Type": "application/json",
                                    },
                                    params: {
                                        id: id,
                                        billno: billno,
                                        paymoney: paymoney,
                                        orderid: orderid,
                                        type: type,
                                    },
                                })
                                .then((response) => {
                                    console.log(response.data);
                                    if (response.data.code == 10000) {
                                        ElMessage({
                                            type: "success",
                                            message: `退款成功,退款金额为${paymoney}元`,
                                        });
                                        this.orderbill = false;
                                    } else {
                                        ElMessage({
                                            type: "warning",
                                            message: "退款失败",
                                        });
                                    }
                                });
                        })
                        .catch(() => {
                            ElMessage({
                                type: "info",
                                message: "取消退款",
                            });
                        });
                } else {
                    console.log(type, billno);
                    this.refundmodel = true;
                    this.axios.get(constant.searchrefund, {}).then((response) => {
                        console.log(response);
                    });
                    this.bill.id = id;
                    this.bill.orderid = orderid;
                    this.bill.billno = billno;
                    this.bill.type = type;
                    this.paymoney = paymoney;
                }
            }
        },
        movenumber(scopeid, id, scopeo, od) {
            console.log(scopeid, id);
            ElMessageBox.confirm(
                `是否确认将《第${scopeo}期》移动到《第${od}期》?`,
                "警告",
                {
                    confirmButtonText: "确认",
                    cancelButtonText: "取消",
                    type: "warning",
                }
            )
                .then(() => {
                    this.axios
                        .get(constant.movebill2, {
                            headers: {
                                "Content-Type": "application/json",
                            },
                            params: {
                                scopeid: scopeid,
                                id: id,
                            },
                        })
                        .then((response) => {
                            console.log(response.data);
                            if (response.data.code == 200) {
                                ElMessage({
                                    type: "success",
                                    message: "移动成功",
                                });
                                this.orderbill = false;
                            } else {
                                ElMessage({
                                    type: "warning",
                                    message: "移动失败",
                                });
                            }
                        });
                })
                .catch(() => {
                    ElMessage({
                        type: "info",
                        message: "取消移动",
                    });
                });
        },
        movebill(orderid) {
            this.axios
                .get(constant.movebill, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    params: {
                        orderid: orderid,
                    },
                })
                .then((response) => {
                    console.log(response.data);
                    if (response.data.data.length == 0) {
                        ElMessage({
                            type: "warning",
                            message: "没有可移动分期",
                        });
                    } else {
                        this.movebilldata = response.data.data;
                    }
                });
        },
        exportorder1() {
            this.exportorder = true;
        },
        selectstatus(val) {
            console.log(val);
            this.status = val;
        },
        beforeUpload(rawFile) {
            return new Promise((resolve) => {
                if (rawFile.size / 1024 > 2048) {
                    // 大于 200 kb 就压缩
                    imageConversion.compressAccurately(rawFile, 2000).then((res) => {
                        // The res in the promise is a compressed Blob type (which can be treated as a File type) file;
                        console.log(res);
                        resolve(res);
                    });
                } else {
                    // 无需压缩直接返回原文件
                    resolve(rawFile);
                }
            });
        },
        beforeUpload2(rawFile) {
            return new Promise((resolve) => {
                if (rawFile.size / 1024 > 2048) {
                    // 大于 200 kb 就压缩
                    imageConversion.compressAccurately(rawFile, 2000).then((res) => {
                        // The res in the promise is a compressed Blob type (which can be treated as a File type) file;
                        console.log(res);
                        resolve(res);
                    });
                } else {
                    // 无需压缩直接返回原文件
                    resolve(rawFile);
                }
            });
        },
        async fetchData() {
            const response = await this.axios.get(constant.exportdata, {
                headers: {
                    "Content-Type": "application/json",
                },
                params: {
                    status: this.status,
                    range: JSON.stringify(this.range),
                    keyword: this.keyword.trim(),
                    type: this.type,
                    selmerchant: JSON.stringify(this.selmerchant),
                    ismerchant: this.ismerchant,
                    mid: this.mid,
                },
            });
            console.log(response.data.exportdata);
            if (response.data.exportdata.length == 0) {
                ElMessage({
                    type: "warning",
                    message: "暂无数据可下载",
                    center: true,
                });
                return false;
            }
            return response.data.exportdata;
        },
        startDownload() {
            ElMessage({
                type: "warning",
                message: "开始下载",
                center: true,
            });
        },
        finishDownload() {
            ElMessage({
                type: "success",
                message: "下载完成",
                center: true,
            });
        },
        compress(file) {
            console.log(file);
            imageConversion.compressAccurately(file, 200).then((res) => {
                //The res in the promise is a compressed Blob type (which can be treated as a File type) file;
                console.log(res);
            });
        },
        handrisk(orderid, memberid, item) {
            this.axios
                .get(constant.handrisk, {
                    headers: {
                        "content-type": "application/json",
                    },
                    params: {
                        orderid: orderid,
                        memberid: memberid,
                    },
                })
                .then((response) => {
                    item.RiskEstimate = response.data.data;
                    console.log(response.data.data);
                    if (response.data.code == "200") {
                        ElMessage({
                            type: "success",
                            message: response.data.msg,
                        });
                    } else {
                        ElMessage({
                            type: "error",
                            message: response.data.msg,
                        });
                    }
                    console.log(response.data);
                });
        },
        uploadImg() {
            console.log(this.fileList1, this.fileList2);
            if (this.fileList1 == "") {
                ElMessage({
                    type: "warning",
                    message: "身份证正面照片不能为空",
                });
                return false;
            }
            if (this.fileList2 == "") {
                ElMessage({
                    type: "warning",
                    message: "身份证反面照片不能为空",
                });
                return false;
            }
            this.arraypost.zhengmian = this.fileList1;
            this.arraypost.fanmian = this.fileList2;
            this.arraypost.orderid = this.orderid;
            console.log(this.arraypost);
            let that = this;
            this.axios
                .post(constant.updatecard, qs.stringify(this.arraypost), {
                    headers: {
                        "content-type": "application/x-www-form-urlencoded",
                    },
                })
                .then((response) => {
                    console.log(response.data);
                    if (response.data.code == 200) {
                        ElMessage({
                            type: "success",
                            message: response.data.msg,
                        });
                    } else {
                        ElMessage({
                            type: "error",
                            message: response.data.msg,
                        });
                    }
                    setTimeout(function () {
                        that.uploadcard = false;
                    }, 1500);
                });
        },
        handleRemove2() {
            this.fileList1 = "";
            console.log(this.fileList1);
        },
        handleRemove1() {
            this.fileList2 = "";
            console.log(this.fileList2);
        },
        handlePictureCardPreview1(file) {
            console.log(file);
            this.dialogImageUrl1 = file.url;
            this.dialogVisible1 = true;
        },
        handlePictureCardPreview2(file) {
            console.log(file);
            this.dialogImageUrl4 = file.url;
            this.dialogVisible4 = true;
        },
        zhengmian(file) {
            console.log(file);
            this.fileList1 = file.url;
            console.log(this.fileList1);
        },
        fanmian(file) {
            console.log(file);
            this.fileList2 = file.url;
        },
        handleClose1() {
            console.log("关闭");
            this.pdfurl = "";
            this.insurance = false;
        },
        Download(url) {
            window.open(url, "_blank");
        },
        lookerror(message) {
            ElMessage.error(message);
        },
        look(insuranceurl, OrderUID) {
            this.pdfurl = "";
            this.downloadfilename = "";
            this.insurance = true;
            this.pdfurl = insuranceurl;
            this.downloadfilename = OrderUID;
        },
        handleClose2() {
            this.trade = false;
        },
        tradingsnapshot(orderid) {
            console.log(orderid);
            this.axios
                .get(constant.get_trades, {
                    headers: {
                        "content-type": "application/json",
                    },
                    params: {
                        orderid: orderid,
                    },
                })
                .then((response) => {
                    console.log(response.data);
                    if (response.data.length == 0) {
                        ElMessage({
                            type: "error",
                            message: "暂时无法查看，请稍后在试",
                        });
                        return false;
                    } else {
                        this.trade = true;
                        this.trades = response.data;
                    }
                });
        },
        copycourier(orderNo) {
            let copyInput = document.createElement("input"); //创建input元素
            document.body.appendChild(copyInput); //向页面底部追加输入框uploadproof
            copyInput.setAttribute("value", orderNo); //添加属性，将url赋值给input元素的value属性
            copyInput.select(); //选择input元素
            document.execCommand("Copy"); //执行复制命令
            copyInput.remove(); //删除动态创建的节点
        },
        auth() {
            console.log(JSON.stringify(this.yuqi));
            if (this.yuqi == "") {
                ElMessage({
                    type: "error",
                    message: "请选择日期",
                });
                return false;
            }
            this.axios
                .get(constant.getauth, {
                    headers: {
                        "content-type": "application/json",
                    },
                    params: {
                        orderid: this.orderid2,
                        date: this.yuqi,
                    },
                })
                .then((response) => {
                    // let pinjie = "授权单号："+response.data.result.auth_no+ "订单号："+ response.data.tradeno.trade_no +" uid：" + response.data.result.payer_user_id
                    this.auth_no = response.data.result.auth_no;
                    console.log(response.data.tradeno);
                    if (response.data.tradeno != null) {
                        this.trade_no = response.data.tradeno.trade_no;
                    } else {
                        this.trade_no = "无订单号";
                    }
                    this.uid = response.data.result.payer_user_id;
                    // let copyInput = document.createElement('input');//创建input元素
                    // document.body.appendChild(copyInput);//向页面底部追加输入框uploadproof
                    // copyInput.setAttribute('value', pinjie);//添加属性，将url赋值给input元素的value属性
                    // copyInput.select();//选择input元素
                    // document.execCommand("Copy");//执行复制命令
                    // copyInput.remove();//删除动态创建的节点
                });
        },
        getauth(orderid) {
            console.log(orderid);
            this.orderid2 = orderid;
            this.orderhao = true;
        },
        saveproof() {
            console.log(this.curproof);
            if (!this.curproof) {
                this.$message.error("请上传签收截图");
                return false;
            } else {
                this.axios
                    .get(constant.uploadproof, {
                        headers: {
                            "content-type": "application/json",
                        },
                        params: {
                            orderid: this.curorderid,
                            proof: this.curproof,
                        },
                    })
                    .then((response) => {
                        console.log(response.data);
                        if (response.data == "OK") {
                            this.$message.success("操作成功");
                            //上传保险
                            this.axios
                                .get(constant.insurance, {
                                    headers: {
                                        "Content-Type": "application/json",
                                    },
                                    params: {
                                        OrderID: this.curorderid,
                                    },
                                })
                                .then((response) => {
                                    console.log(response);
                                });
                            this.uploadproof = false;
                        }
                    });
            }
        },
        UploadProofSuccess(response) {
            console.log(response);
            this.curproof = response.url;
        },
        UploadConsign(orderid) {
            this.curorderid = orderid;
            this.axios
                .get(constant.getproof, {
                    headers: {
                        "content-type": "application/json",
                    },
                    params: {
                        orderid: this.curorderid,
                    },
                })
                .then((response) => {
                    console.log(response.data);
                    if (response.data) {
                        this.curproof = response.data;
                    } else {
                        this.curproof = "";
                    }
                });
            this.uploadproof = true;
        },
        addinfoadd() {
            console.log(this.orderid);
            console.log(this.idimg1);
            console.log(this.idimg2);
        },
        addinfocancel() {
            console.log("sfdsf");
            this.addid = false;
            this.orderid = "";
            this.ImageUrl1 = [];
            this.ImageUrl2 = [];
            this.FileListid1 = [];
            this.FileListid2 = [];
            this.id1 = false;
            this.id2 = false;
            this.idimg1 = [];
            this.idimg2 = [];
            this.orderid = "";
        },
        addidinfo(orderid) {
            console.log(orderid);
            this.orderid = orderid;
            //this.addid = true
            ElMessageBox.confirm("该客户未上传身份证，是否继续上传?", "警告", {
                confirmButtonText: "确认",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    console.log("3112");
                    this.fileList1 = [];
                    this.fileList2 = [];
                    this.uploadcard = true;
                })
                .catch(() => {
                    console.log("3118");
                });
        },
        showid(url) {
            console.log(url);
            this.urlImg = JSON.parse(url);
            this.id = true;
        },
        handleClose() {
            this.urlImg = [];
            this.orderid = "";
            this.id = false;
        },
        Getdate(row, column) {
            var cur_date = row[column.property];
            console.log(cur_date);
            return formatDate(cur_date);
            function formatDate(date) {
                var datetime = new Date(date * 1000);
                var year = datetime.getFullYear();
                var month = ("0" + (datetime.getMonth() + 1)).slice(-2);
                var day = ("0" + datetime.getDate()).slice(-2);
                var hour = ("0" + datetime.getHours()).slice(-2);
                var minute = ("0" + datetime.getMinutes()).slice(-2);
                var second = ("0" + datetime.getSeconds()).slice(-2);
                return (
                    year +
                    "-" +
                    month +
                    "-" +
                    day +
                    " " +
                    hour +
                    ":" +
                    minute +
                    ":" +
                    second
                );
            }
        },
        Changetip(event) {
            console.log(event);
            if (event == 20) {
                this.hint = true;
            } else {
                this.hint = false;
            }
        },
        AgreementPay(orderbillid, index) {
            console.log(orderbillid, this.wxtype, index);
            // if (this.wxtype == 1 && index == 0) {
            // 	this.$message.error('操作失败')
            // 	return
            // }
            this.excuting = true;
            this.load = true;
            if (this.wxtype == 1) {
                this.axios
                    .get(constant.lianlianpay, {
                        headers: {
                            "content-type": "application/json",
                        },
                        params: {
                            orderbillid: orderbillid,
                        },
                    })
                    .then((response) => {
                        console.log(response.data);
                        this.orderbill = false;
                        this.load = false;
                        if (response.data == "OK") {
                            this.excuting = false;
                            this.$message.success("代扣成功");
                            // this.GetOrderBill(this.curorderid, this.curorderstatus);
                            //this.$router.go(0);
                        } else if (response.data == "8888") {
                            this.$message.error("渠道短信已下发，需验证短信验证码");
                            ElMessageBox.prompt("请输入验证码", "验证码", {
                                confirmButtonText: "确认",
                                cancelButtonText: "取消",
                                inputPattern: /^[1-9]\d*$/,
                                inputErrorMessage: "验证码错误",
                            })
                                .then(({ value }) => {
                                    ElMessage({
                                        type: "success",
                                        message: `Your email is:${value}`,
                                    });
                                })
                                .catch(() => {
                                    ElMessage({
                                        type: "info",
                                        message: "Input canceled",
                                    });
                                });
                        } else {
                            this.$message.error(response.data);
                            // this.GetOrderBill(this.curorderid, this.curorderstatus);
                        }
                    });
            } else {
                this.axios
                    .get(constant.agreementpay, {
                        headers: {
                            "content-type": "application/json",
                        },
                        params: {
                            orderbillid: orderbillid,
                        },
                    })
                    .then((response) => {
                        console.log(response.data);
                        this.load = false;
                        if (response.data == "OK") {
                            this.excuting = false;
                            this.$message.success("代扣成功");
                            this.GetOrderBill(this.curorderid, this.curorderstatus);
                            //this.$router.go(0);
                        } else {
                            if (response.data == "DEALING") {
                                this.$message.error("支付宝扣款处理中");
                                this.excuting = 1;
                                this.GetOrderBill(this.curorderid, this.curorderstatus);
                            } else {
                                this.$message.error(response.data);
                                this.GetOrderBill(this.curorderid, this.curorderstatus);
                            }
                        }
                    });
            }
        },
        tipSelect(val) {
            console.log(val);
            this.tip = this.options2[val]["tip"];
        },
        ev1(val) {
            console.log(val);
            if (val == 8) {
                this.ev = true;
            } else {
                this.ev = false;
            }
        },
        evidence2(orderid) {
            this.orderid = orderid;
            this.evidence = true;
        },
        Export(id, imgurl) {
            console.log(imgurl);
            // var r = imgurl.filter(function(s){
            //     return s && s.trim()
            // })
            // console.log(r)
            if (imgurl.length < 1) {
                this.$message.error("暂无可下载的文件");
                return false;
            }
            this.axios
                .get(constant.export, {
                    headers: {
                        "content-type": "application/json",
                    },
                    params: {
                        imgurl: imgurl,
                    },
                })
                .then((response) => {
                    console.log(response.data);
                    window.location.href = response.data.msg;
                });
        },
        beforeAvatarUpload(rawFile) {
            let type_record = [0, 1, 2, 3];
            let index1 = type_record.indexOf(this.value2);
            if (index1 == -1) {
                ElMessage.error("请选择要上传的数据类型");
                return false;
            }
            let array1 = [];
            let attrname = rawFile.name.split(".")[1];
            console.log(attrname);
            if (this.value2 == 0) {
                array1 = ["jpg", "png", "jpeg", "gif"];
            } else if (this.value2 == 1) {
                array1 = ["txt", "doc", "xls", "pdf", "zip"];
            } else if (this.value2 == 2) {
                array1 = ["mp3", "ogg", "m4a", "aac"];
            } else if (this.value2 == 3) {
                array1 = ["mp4", "mpeg", "WebM"];
            }
            let index = array1.indexOf(attrname);
            if (index == -1) {
                ElMessage.error("文件类型错误，请上传指定类型的文件");
                return false;
            }
            console.log(array1);
            return true;
            // return new Promise((resolve) => {
            //   if (rawFile.size / 1024 > 2048) { // 大于 200 kb 就压缩
            //     imageConversion.compressAccurately(rawFile, 2000).then(res => {
            //       // The res in the promise is a compressed Blob type (which can be treated as a File type) file;
            //       console.log(res)
            //       resolve(res)
            //     })
            //   } else{
            //     // 无需压缩直接返回原文件
            //     resolve(rawFile)
            //   }
            // })
        },
        saveEvidence() {
            console.log(this.value);
            if (this.value.length == 0) {
                this.$message.error("请选择证据选项");
                return false;
            }
            if (this.value2.length == 0) {
                this.$message.error("请选择证据类型");
                return false;
            }
            console.log(this.value2);
            console.log(this.ev2.length);
            console.log(this.estimateinfo.PdtImg3.length);
            if (this.ev2.length > 20) {
                this.$message.error("请最多输入20个自定义存证字符串");
                return false;
            }
            let imgurl = "";
            if (this.estimateinfo.PdtImg3.length > 0) {
                let objImgurl = Object.values(this.estimateinfo.PdtImg3);
                console.log(objImgurl);
                for (var item in objImgurl) {
                    console.log(objImgurl[item]["url"]);
                    imgurl += objImgurl[item]["url"] + "|";
                }
            }
            console.log(imgurl);
            if (imgurl.length == 0) {
                this.$message.error("请上传文件");
                return false;
            }
            console.log(this.orderid);
            this.axios
                .get(constant.add_evidence, {
                    headers: {
                        "content-type": "application/json",
                    },
                    params: {
                        orderid: this.orderid,
                        value: this.value,
                        value2: this.value2,
                        ev2: this.ev2,
                        imgurl: imgurl,
                        adduser: Cookies.get("name"),
                    },
                })
                .then((response) => {
                    console.log(response.data);
                    this.evidence = false;
                    this.$router.go(0);
                });
        },
        //查看证据
        viewev(orderid) {
            console.log(orderid);
            console.log(constant.getev);
            this.viewev1 = orderid;
            this.axios
                .get(constant.getev, {
                    headers: {
                        "content-type": "application/json",
                    },
                    params: {
                        orderid: orderid,
                    },
                })
                .then((response) => {
                    console.log(response.data);
                    if (!response.data.length) {
                        this.$message.error("暂无证据");
                    } else {
                        this.ev3 = true;
                        for (var img in response.data) {
                            let newimgurl = response.data[img]["imgurl"];
                            let imgurlarray = newimgurl.split("|");
                            response.data[img]["newimgurl"] = imgurlarray;
                            response.data[img]["i"] = response.data.length - img;
                        }
                        this.viewevidence = response.data;
                    }
                    console.log(this.viewevidence);
                });
        },
        exportall() {
            this.axios
                .get(constant.getevall, {
                    headers: {
                        "content-type": "application/json",
                    },
                    params: {
                        orderid: this.viewev1,
                    },
                })
                .then((response) => {
                    console.log(response.data);
                    window.location.href = response.data.url;
                });
        },
        changeToStar(str, len1, len2 = 0) {
            let strLen = str.length;
            let stars = "";
            for (let i = 0; i < strLen - len1 - len2; i++) {
                stars += "*";
            }
            str = str.substr(0, len1) + stars + str.substr(strLen - len2);
            return str;
        },
        handleTraSizeChange(val) {
            console.log(val);
            this.pagesize = val;
            // this.GetTrade(this.curorderid);
        },
        handleTraCurrentChange(val) {
            console.log(val);
            this.tra_curpage = val;
            // this.GetTrade(this.curorderid);
        },
        GetTrade(orderid) {
            console.log(this.tra_curpage);
            this.curorderid = orderid;
            this.axios
                .get(constant.get_trade, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    params: {
                        orderid: orderid,
                        curpage: this.tra_curpage,
                        pagesize: this.tra_pagesize,
                    },
                })
                .then((response) => {
                    console.log(response.data);
                    if (response.data.length != 0) {
                        this.tradedata = response.data.list;
                        this.tra_curpage = response.data.curpage * 1;
                        this.tra_totalcount = response.data.totalcount * 1;
                        this.tradebill = true;
                    } else {
                        this.tradebill = false;
                    }
                });
        },
        GetOrderBill(orderid, status, rentcount, wxtype) {
            console.log(orderid);
            this.rentcount = rentcount;
            this.curorderid = orderid;
            this.curorderstatus = status;
            this.wxtype = wxtype;
            this.axios
                .get(constant.get_order_bill, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    params: {
                        orderid: orderid,
                    },
                })
                .then((response) => {
                    console.log(response.data);
                    this.cur_guarant = response.data.guarant;
                    this.billdata = response.data.billdata;
                    this.num = response.data.num;
                    this.summarydata[0].overdue =
                        (response.data.otherinfo.Overdue * 1).toFixed(2) * 1;
                    this.summarydata[0].mitigate =
                        (response.data.otherinfo.Mitigate * 1).toFixed(2) * 1;
                    this.summarydata[0].needoverdue =
                        (
                            response.data.otherinfo.Overdue * 1 -
                            response.data.otherinfo.Mitigate * 1 -
                            response.data.otherinfo.PayOverdue * 1
                        ).toFixed(2) * 1;
                    this.summarydata[0].payoverdue =
                        (response.data.otherinfo.PayOverdue * 1).toFixed(2) * 1;
                });
            this.orderbill = true;
        },
        Gotopdt(pdtcode) {
            console.log(pdtcode);
            /*
                        this.$router.push({path:'/pdt',query:{'keyword':pdtname}});*/
            const routeurl = this.$router.resolve({
                path: "/pdt",
                query: { keyword: pdtcode },
            });
            window.open(routeurl.href, "_blank");
        },
        EditValue2(orderid) {
            console.log(orderid);
            this.$prompt(
                "请填写续租比例（区间：0.5～3）: ",
                "提示（续租比例=变更后的租金/原租金）",
                {
                    confirmButtonText: "确认",
                    cancelButtonText: "取消",
                }
            )
                .then((obj) => {
                    if (!obj.value) {
                        ElMessage({
                            type: "warning",
                            message: "不能为空",
                        });
                        return false;
                    } else {
                        if (obj.value * 1 < 0.5 || obj.value * 1 > 3) {
                            ElMessage({
                                type: "warning",
                                message: "填写的数字必须在0.5到3之间",
                            });
                            return false;
                        }
                        this.axios
                            .get(constant.premiumrate, {
                                headers: {
                                    "content-type": "application/json",
                                },
                                params: {
                                    orderid: orderid,
                                    rate: obj.value,
                                },
                            })
                            .then((response) => {
                                if (response.data.code == "200") {
                                    this.$message.success(response.data.msg);
                                } else {
                                    this.$meesage.error(response.data.msg);
                                }
                            });
                    }
                })
                .catch(() => {
                    return false;
                });
        },
        EditValue(orderid) {
            console.log(orderid);
            this.$prompt("请填写结算比例（100以内的整数）:", "提示", {
                confirmButtonText: "确认",
                cancelButtonText: "取消",
            })
                .then((obj) => {
                    console.log(obj.value);
                    if (!obj.value) {
                        this.$message.error("不能为空");
                        return false;
                    } else {
                        this.axios
                            .get(constant.alterorderrate, {
                                headers: {
                                    "content-type": "application/json",
                                },
                                params: {
                                    orderid: orderid,
                                    rate: obj.value,
                                },
                            })
                            .then((response) => {
                                if (response.data == "OK") {
                                    this.$router.go(0);
                                } else {
                                    this.$meesage.error("参数不正确");
                                }
                            });
                    }
                })
                .catch(() => {
                    return false;
                });
        },
        formatDay(date) {
            var datetime = new Date(date * 1000);
            var year = datetime.getFullYear();
            var month = ("0" + (datetime.getMonth() + 1)).slice(-2);
            var day = ("0" + datetime.getDate()).slice(-2);
            return year + "-" + month + "-" + day + " ";
        },
        DisableOption(val) {
            if (val == 1) {
                this.extendinfo.newreturn = "";
            }
            if (val == 2) {
                this.extendinfo.extendday = 0;
            }
        },
        saveextend() {
            if (this.extendinfo.type == 1) {
                if (this.extendinfo.extendday <= 0) {
                    this.$message.error("请输入延长天数");
                    return false;
                }
            } else if (this.extendinfo.type == 2) {
                if (!this.extendinfo.newreturn) {
                    this.$message.error("请选择还机日");
                    return false;
                }
            }
            this.axios
                .post(constant.saveextend, qs.stringify(this.extendinfo), {
                    headers: {
                        "content-type": "application/x-www-form-urlencoded",
                    },
                })
                .then((response) => {
                    console.log(response.data);
                    if (response.data == "OK") {
                        this.$message.success("操作成功");
                        this.extendorder = false;
                        this.keyword = this.extendinfo.OrderUID;
                        this.init();
                    }
                });
        },
        cancelextend() {
            this.extendinfo.extendday = 0;
            this.extendinfo.newreturn = "";
            this.extendorder = false;
        },
        GetReturnDay(type) {
            console.log(type);
            if (type == 2) {
                let date = new Date(this.extendinfo.newreturn);
                let return_timestamp = date.getTime().toString();
                let returndtime = this.formatDay(
                    return_timestamp / 1000 + 24 * 60 * 60
                );
                return returndtime;
            } else {
                let date = new Date(this.extendinfo.ShouldReturnDTime);
                let return_timestamp = date.getTime().toString();
                let returndtime = this.formatDay(
                    return_timestamp / 1000 + 24 * 60 * 60 * this.extendinfo.extendday
                );
                return returndtime;
            }
        },
        Extend(id) {
            this.axios
                .get(constant.extendorder, {
                    headers: {
                        "content-type": "application/json",
                    },
                    params: {
                        id: id,
                    },
                })
                .then((response) => {
                    console.log(response.data);
                    console.log("status--" + response.data.Status);
                    this.extendinfo = response.data;
                    this.extendinfo.extype = 1;
                    this.extendinfo.extendday = 0;
                    this.extendorder = true;
                    console.log(this.extendinfo);
                });
        },
        savealter() {
            //console.log(this.alterorderinfo);
            //console.log(this.alterorderinfo.alterrentday);
            //console.log(this.alterorderinfo.alterordermoney);
            if (
                this.alterorderinfo.alterrentday == 0 ||
                this.alterorderinfo.alterordermoney == 0
            ) {
                this.$message.error("请填写修改后的租期和总租金");
                return false;
            }
            this.axios
                .post(constant.savealter, qs.stringify(this.alterorderinfo), {
                    headers: {
                        "content-type": "application/x-www-form-urlencoded",
                    },
                })
                .then((response) => {
                    console.log(response.data);
                    if (response.data == "OK") {
                        this.$message.success("操作成功");
                        this.editorder = false;
                        this.keyword = this.alterorderinfo.OrderUID;
                        this.init();
                    } else this.$message.error("操作失败");
                });
        },
        cancelalter() {
            this.editorder = false;
            this.alterorderinfo.alterrentday = 0;
            this.alterorderinfo.alterordermoney = 0;
        },
        EditOrder(id) {
            console.log(id);
            this.axios
                .get(constant.alterorder, {
                    headers: {
                        "content-type": "application/json",
                    },
                    params: {
                        id: id,
                    },
                })
                .then((response) => {
                    console.log(response.data);
                    this.alterorderinfo = response.data;
                    this.alterorderinfo.alterrentday = 0;
                    this.alterorderinfo.alterordermoney = 0;
                    this.editorder = true;
                });
        },
        PushMsg(orderid, isshow) {
            this.axios
                .get(constant.pushmsg, {
                    headers: {
                        "content-type": "application/json",
                    },
                    params: {
                        orderid: orderid,
                        isshow: isshow,
                    },
                })
                .then((response) => {
                    console.log(response.data);
                });
        },
        SaveRiskMsg() {
            if (!this.curriskmsg) {
                this.$message.error("请填写具体风控信息");
                return false;
            }
            this.axios
                .get(constant.saveriskmsg, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    params: {
                        orderid: this.curorderid,
                        msg: this.curriskmsg,
                        isshow: this.isshow,
                    },
                })
                .then((response) => {
                    console.log(response.data);
                    if (response.data == "OK") {
                        this.$message.success("操作成功");
                        console.log("isshow--" + this.isshow);
                        console.log("curindex--" + this.curindex);
                        if (this.isshow == 1) this.orderdata[this.curindex].IsShow = "1";
                        else this.orderdata[this.curindex].IsShow = "0";
                        this.orderdata[this.curindex].RiskMsg = this.curriskmsg;
                        this.editrisk = false;
                    }
                });
        },
        CancelRiskMsg() {
            this.editrisk = false;
            this.curorderid = "";
            this.curindex = "";
            this.curriskmsg = "";
        },
        EditRiskMsg(id, index) {
            console.log("id--" + id + "index--" + index);
            this.curorderid = id;
            this.curindex = index;
            this.axios
                .get(constant.editriskmsg, {
                    headers: {
                        "content-type": "application/json",
                    },
                    params: {
                        orderid: id,
                    },
                })
                .then((response) => {
                    console.log(response.data);
                    this.curriskmsg = response.data.RiskMsg;
                    this.isshow = response.data.IsShow;
                    this.editrisk = true;
                });
        },
        ConfirmMerchant() {
            console.log(this.selmerchant);
            this.merchant = false;
        },
        ConfirmMerchant2() {
            console.log(this.selagent);
            this.agent = false;
        },
        ConfirmMerchant3() {
            console.log(this.selpartner);
            this.partner = false;
        },
        Back() {
            this.merchant = false;
        },
        Back2() {
            this.agent = false;
        },
        Back3() {
            this.partner = false;
        },
        SelectMerchant() {
            this.merchant = true;
        },
        Selectagent() {
            this.agent = true;
        },
        Selectpartner() {
            this.partner = true;
        },
        SaveReceiveDay() {
            console.log(this.receiveday);
            if (!this.receiveday) {
                this.$message.error("请选择收货时间");
                return false;
            }
            this.axios
                .get(constant.set_receive, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    params: {
                        id: this.curorderid,
                        date: this.receiveday,
                    },
                })
                .then((response) => {
                    console.log(response.data);
                    if (response.data == "OK") {
                        this.$message.success("操作成功");
                        this.$router.go(0);
                    } else {
                        return false;
                    }
                });
        },
        CloseReceiveDay() {
            this.confirmreceive = false;
            this.curorderid = "";
        },
        Oplog(id) {
            this.axios
                .get(constant.getoplog, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    params: {
                        orderid: id,
                    },
                })
                .then((response) => {
                    console.log(response.data);
                    this.oplogdialog = true;
                    this.oplogdata = response.data;
                });
        },
        ShowReport(index) {
            this.curreport = this.orderdata[index].Report;
            this.checkreport = true;
        },
        CheckReport(id) {
            this.axios
                .get(constant.get_estimate, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    params: {
                        id: id,
                    },
                })
                .then((response) => {
                    console.log(response.data);
                    this.report = true;
                    this.estimateinfo = response.data;
                    if (response.data.Report) this.FileList = response.data.Report;
                    if (
                        response.data.Compensation * 1 &&
                        response.data.CompensationDecrease * 1
                    )
                        this.estimateinfo.TotalCompensation = (
                            response.data.Compensation * 1 +
                            response.data.CompensationDecrease * 1
                        ).toFixed(2);
                });
        },
        formatDate(date) {
            var datetime = new Date(date * 1000);
            var year = datetime.getFullYear();
            var month = ("0" + (datetime.getMonth() + 1)).slice(-2);
            var day = ("0" + datetime.getDate()).slice(-2);
            var hour = ("0" + datetime.getHours()).slice(-2);
            var minute = ("0" + datetime.getMinutes()).slice(-2);
            var second = ("0" + datetime.getSeconds()).slice(-2);
            return (
                year +
                "-" +
                month +
                "-" +
                day +
                " " +
                hour +
                ":" +
                minute +
                ":" +
                second
            );
        },
        CheckReturn(id) {
            this.axios
                .get(constant.checkreturn, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    params: {
                        orderid: id,
                    },
                })
                .then((response) => {
                    console.log(response.data);
                    this.returnlogtable = response.data;
                    this.returnlog = true;
                });
        },
        handleSizeChange(val) {
            this.pagesize = val;
            this.init();
        },
        handleCurrentChange(val) {
            console.log(val);
            this.curpage = val;
            this.init();
        },
        handleClick(tab) {
            console.log(tab.props.name);
            this.status = tab.props.name;
            this.curpage = 1;
            this.init();
        },
        init() {
            console.log(this.ispurchase);
            this.axios
                .get(constant.get_order_list, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    params: {
                        curpage: this.curpage,
                        pagesize: this.pagesize,
                        status: this.status,
                        range: JSON.stringify(this.range),
                        keyword: this.keyword.trim(),
                        type: this.type,
                        ispurchase: this.ispurchase,
                        selmerchant: JSON.stringify(this.selmerchant),
                        selagent: JSON.stringify(this.selagent),
                        selpartner: JSON.stringify(this.selpartner),
                        ismerchant: this.ismerchant,
                        mid: this.mid,
                    },
                })
                .then((response) => {
                    console.log(response.data);
                    this.orderdata = response.data.list;
                    this.zu = response.data.zu * 1;
                    this.gui = response.data.gui * 1;
                    this.yu = response.data.yu * 1;
                    this.shen = response.data.shen * 1;
                    this.fu = response.data.fu * 1;
                    this.fa = response.data.fa * 1;
                    this.shou = response.data.shou * 1;
                    this.huan = response.data.huan * 1;
                    this.wan = response.data.wan * 1;
                    this.duan = response.data.duan * 1;
                    this.qu = response.data.qu * 1;
                    this.weifukuan = response.data.tcount * 1;
                    this.curpage = response.data.curpage * 1;
                    this.totalcount = response.data.totalcount * 1;
                    this.totalcount1 = response.data.totalcount1 * 1;
                    this.yi = response.data.yi * 1;
                    this.tab_for = [
                        { one: "全部订单(", two: this.totalcount1, three: ")", name: 0 },
                        { one: "租赁中(", two: this.zu, three: ")", name: 28 },
                        { one: "待审核(", two: this.shen, three: ")", name: 2 },
                        { one: "待付款(", two: this.fu, three: ")", name: 3 },
                        { one: "待发货(", two: this.fa, three: ")", name: 4 },
                        { one: "待收货(", two: this.shou, three: ")", name: 5 },
                        { one: "待归还(", two: this.gui, three: ")", name: 6 },
                        { one: "归还中(", two: this.huan, three: ")", name: 7 },
                        { one: "已完成(", two: this.wan, three: ")", name: 9 },
                        { one: "逾期订单(", two: this.yu, three: ")", name: 12 },
                        { one: "当天未扣款(", two: this.weifukuan, three: ")", name: 39 },
                        { one: "一周内到期(", two: this.yi, three: ")", name: 1000 },
                        { one: "已买断(", two: this.duan, three: ")", name: 21 },
                        { one: "申请取消(", two: this.qu, three: ")", name: 29 },
                    ];
                });
        },
        //添加证据
        addevidence(orderid) {
            this.axios
                .get(constant.get_evidence, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    params: {
                        orderid: orderid,
                    },
                })
                .then((response) => {
                    console.log(response);
                });
        },
        AddRemark(orderid, item) {
            this.sremark = "";
            this.remarkitem = item;
            this.axios
                .get(constant.get_seller_remark, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    params: {
                        orderid: orderid,
                    },
                })
                .then((response) => {
                    console.log(response);
                    //this.sremark = response.data;
                    this.curorderid = orderid;
                    this.addremark = true;
                });
        },
        //查看备注
        viewNotes(orderid, orderuid) {
            console.log(orderid);
            console.log(orderuid);
            this.axios
                .get(constant.get_seller_remarks, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    params: {
                        orderid: orderid,
                        orderuid: orderuid,
                    },
                })
                .then((response) => {
                    console.log(response.data);
                    if (!response.data.length) {
                        this.$message.error("没有备注信息");
                    } else {
                        this.centerDialogVisible = true;
                        this.tableData = [];
                        for (var key in response.data) {
                            let obj = new Object();
                            obj.info = response.data[key]["sellerRemark"];
                            obj.addtime = response.data[key]["addtime"];
                            obj.username = response.data[key]["userName"];
                            if (response.data[key]["imgUrl"]) {
                                let imgurl = response.data[key]["imgUrl"];
                                //console.log(imgurl)
                                //console.log(imgurl.split("|"))
                                let arr = imgurl.split("|");
                                let a = arr.filter(function (s) {
                                    return s && s.trim(); // 注：IE9(不包含IE9)以下的版本没有trim()方法
                                });
                                obj.imgurlArray = a;
                            }
                            this.tableData.push(obj);
                        }
                        console.log(this.tableData);
                    }
                });
        },
        SaveRemark() {
            console.log(this.sremark);
            let obj = this.estimateinfo.PdtImg2;
            console.log(obj.length);
            console.log(Cookies.get("name"));
            let imgurl = "";
            if (obj.length > 0) {
                for (var key in obj) {
                    console.log(Object.values(obj[key])[2]);
                    imgurl += Object.values(obj[key])[2] + "|";
                }
            }
            console.log(constant.add_remark);
            this.axios
                .get(constant.add_remark, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    params: {
                        orderid: this.curorderid,
                        sremark: this.sremark,
                        username: Cookies.get("name"),
                        imgurl: imgurl,
                    },
                })
                .then((response) => {
                    this.remarkitem.lastRemark = this.sremark;
                    console.log(response.data);
                    this.$message.success("添加成功");
                    this.addremark = false;
                });
        },
        CloseRemark() {
            this.curorderid = "";
            this.addremark = false;
            this.sremark = "";
        },
        CloseEstimate() {
            this.estimateinfo = {};
            this.report = false;
        },
        CheckSel() {
            console.log(this.pbino);
        },
        Check() {
            console.log(this.key);
        },
        CloseCancel() {
            this.cancelorder = false;
            this.cancelinfo.type = "";
            this.cancelinfo.reason = "";
            this.cancelinfo.penalty = "";
            this.cancelinfo.item = "";
        },
        OpenCancel(id, paymoney, item) {
            this.cancelinfo.paymoney = paymoney;
            this.cancelinfo.id = id;
            this.cancelinfo.item = item;
            this.cancelorder = true;
            console.log(this.cancelinfo);
        },
        SaveCancel() {
            console.log(this.cancelinfo.item);
            this.axios
                .get(constant.cancelmsg, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    params: {
                        type: "cancel",
                        phone: this.cancelinfo.item.Mobile,
                        templateCode: "SMS_257886195",
                        templateParam:
                            '{"orderNo":"' + this.cancelinfo.item.OrderUID + '"}',
                    },
                })
                .then((response) => {
                    console.log(response.data);
                });
            this.$refs["cancelinfo"].validate((valid) => {
                if (valid) {
                    this.axios
                        .post(constant.save_cancel_order, this.cancelinfo, {
                            headers: { "Content-Type": "application/x-www-form-urlencoded" },
                        })
                        .then((response) => {
                            console.log(response.data);
                            if (response.data == "OK") {
                                this.cancelinfo.item.Status = 10;
                                this.cancelorder = false;
                                this.$message.success("操作成功");
                                //this.$router.go(0);
                            } else {
                                return false;
                            }
                        });
                } else {
                    return false;
                }
            });
        },
        Overdue(id) {
            this.$confirm("要将此订单从强制买断状态改为逾期么？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    this.axios
                        .get(constant.turn_overdue, {
                            headers: {
                                "Content-Type": "application/json",
                            },
                            params: {
                                id: id,
                            },
                        })
                        .then((response) => {
                            console.log(response.data);
                            if (response.data == "OK") {
                                this.$message.success("操作成功");
                                this.$router.go(0);
                            } else {
                                return false;
                            }
                        });
                })
                .catch(() => {
                    return false;
                });
        },
        Receive(id) {
            console.log(id);
            this.$confirm("确认商品已交付给客户了么？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    this.curorderid = id;
                    this.confirmreceive = true;
                    /*
                                      this.axios.get(constant.set_receive,{
                                        headers: {
                                          "Content-Type": "application/json",
                                        },
                                        params:{
                                          id: id
                                        }
                                      }).then((response)=>{
                                        console.log(response.data);
                                        if(response.data == "OK"){
                                          this.$message.success("操作成功");
                                          this.$router.go(0);
                                        }else{
                                          return false;
                                        }
                                      })*/
                })
                .catch(() => {
                    return false;
                });
        },
        Complete(id, item) {
            this.accomplish = true;
            console.log(id, typeof item);
            this.wanchengid = id;
            this.wanchengitem = item;
            console.log(this.wanchengitem);
        },
        wancheng() {
            let that = this;
            let msg = "";
            if (this.wanchengstatus == "") {
                ElMessage({
                    type: "error",
                    message: "请选择要处理的订单类型",
                });
                return false;
            }
            console.log(this.wanchengitem);
            if (this.wanchengstatus == 9) {
                msg = "归还";
            } else {
                msg = "买断";
            }
            this.$confirm("确认订单" + msg + "吗？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    this.axios
                        .get(constant.set_complete, {
                            headers: {
                                "Content-Type": "application/json",
                            },
                            params: {
                                id: that.wanchengid,
                                status: that.wanchengstatus,
                            },
                        })
                        .then((response) => {
                            console.log(response.data);
                            if (response.data == "OK") {
                                this.accomplish = false;
                                console.log(that.wanchengitem, this.wanchengstatus);
                                that.wanchengitem.Status = this.wanchengstatus;
                                this.$message.success("操作成功");
                                //this.$router.go(0);
                            } else {
                                return false;
                            }
                        });
                })
                .catch(() => {
                    return false;
                });
        },
        Consign(id) {
            this.axios
                .get(constant.set_consign, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    params: {
                        orderid: id,
                    },
                })
                .then((response) => {
                    console.log(response.data);
                    if (response.data == "OK") {
                        this.$message.success("发货成功");
                        // this.hetong = false
                        // this.dis = true
                        // this.$router.go(0);
                        this.logistic = false;
                        // info.Status = 5;
                        console.log(this.logisticinfo.item);
                        // this.logisticinfo.item.Status = 5
                        // this.logisticinfo.
                    } else if (response.data == "NOINFO") {
                        this.$message.error("请完善发货信息之后再进行操作");
                        return false;
                    }
                });
        },
        AddLogistic(id, item, lease) {
            this.lease = lease;
            this.title = "发货";
            this.savelog = "确认发货";
            this.logisticinfo.orderid = id;
            this.logisticinfo.item = item;
            this.GetLogCom();
            this.axios
                .get(constant.get_return_addr, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    params: {
                        orderid: id,
                    },
                })
                .then((response) => {
                    console.log("返回地址");
                    console.log(response.data);
                    this.returnid = response.data;
                    for (let i = 0; i < this.returnid.length; i++) {
                        let cur = response.data[i];
                        if (cur.IsDefault == 1) this.logisticinfo.returnid = cur.ID;
                    }
                    console.log("默认地址ID--" + this.logisticinfo.returnid);
                    if (this.logisticinfo.returnid) {
                        this.axios
                            .get(constant.get_order_logistic, {
                                headers: {
                                    "Content-Type": "application/json",
                                },
                                params: {
                                    orderid: id,
                                },
                            })
                            .then((response) => {
                                console.log("收货地址--" + response.data.list.length);
                                console.log(response.data.list.length);
                                if (response.data.list.length > 0) {
                                    this.logisticinfo.item.Status = 5;
                                    this.logisticinfo.logisticdata = response.data.list;
                                } else {
                                    console.log(this.savelog);
                                    this.logisticinfo.logisticdata.push({
                                        id: "",
                                        UID: "",
                                        ShippingCount: 1,
                                        LogisticCompany: "",
                                        LogisticNo: "",
                                    });
                                }
                            });
                    }
                });
            //this.GetReturnAddr(id);
            this.logistic = true;
        },
        EditLogistic(id) {
            this.title = "修改发货信息";
            this.savelog = "确认修改";
            this.logisticinfo.orderid = id;
            this.logistic = true;
            this.GetLogCom();
            this.axios
                .get(constant.get_order_logistic, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    params: {
                        orderid: id,
                    },
                })
                .then((response) => {
                    console.log(response.data);
                    console.log(this.savelog);
                    this.logisticinfo.logisticdata = response.data.list;
                    //this.logisticinfo.returnid = response.data.returnid;
                });
        },
        Add() {
            this.logisticinfo.logisticdata.push({
                ID: "",
                UID: "",
                ShippingCount: 1,
                LogisticCompany: "",
                LogisticNo: "",
            });
        },
        Del(index) {
            if (index != -1) {
                this.logisticinfo.logisticdata.splice(index, 1);
            }
        },
        SaveLogistic() {
            console.log(this.logisticinfo);
            this.axios
                .post(constant.save_logistic, this.logisticinfo, {
                    headers: { "Content-Type": "application/x-www-form-urlencoded" },
                })
                .then((response) => {
                    console.log(response.data);
                    // if(that.lease != null){
                    //   this.hetong = true
                    //   this.dis = false
                    // }
                    if (response.data == "OK") {
                        this.Consign(this.logisticinfo.orderid, this.logisticinfo.item);
                        // this.$message.success("操作成功");
                        // this.logistic = false;
                        // this.logisticinfo.item.Status = 5;
                    } else if (response.data == "NOLICENSE") {
                        this.$message.error("该订单尚未上传身份证");
                        return false;
                    } else {
                        this.$message.error(
                            "请检查物流信息是否填写完整(IMEI/SN,快递公司,快递单号)"
                        );
                        return false;
                    }
                });
        },
        CloseLogistic() {
            this.logistic = false;
            this.logisticinfo.logisticdata = [];
            this.logisticinfo.orderid = "";
            this.logisticinfo.item = "";
        },
        GetLogCom() {
            this.axios
                .get(constant.get_logistic_company, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((response) => {
                    this.logcom = response.data;
                });
        },
        GetReturnAddr(id) {
            console.log(id);
            this.axios
                .get(constant.get_return_addr, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    params: {
                        orderid: id,
                    },
                })
                .then((response) => {
                    console.log("返回地址");
                    console.log(response.data);
                    this.returnid = response.data;
                    for (let i = 0; i < response.data.length; i++) {
                        let cur = response.data[i];
                        if (cur.IsDefault) this.logisticinfo.returnid = cur.ID;
                    }
                });
        },
        EditAddress(id) {
            this.customeraddr = true;
            this.axios
                .get(constant.get_customer_addr, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    params: {
                        orderid: id,
                    },
                })
                .then((response) => {
                    console.log(response.data);
                    this.addrinfo = response.data;
                    let provinceid = response.data.fkAreaIDL1;
                    let cityid = response.data.fkAreaIDL2;
                    this.GetCity(provinceid);
                    this.GetArea(cityid);
                });
        },
        CloseAddr() {
            this.customeraddr = false;
        },
        SaveAddr() {
            this.axios
                .post(constant.save_addr, this.addrinfo, {
                    headers: { "Content-Type": "application/x-www-form-urlencoded" },
                })
                .then((response) => {
                    console.log(response.data);
                    if (response.data == "OK") {
                        this.$message.success("操作成功");
                        this.customeraddr = false;
                    }
                });
        },
        GetCity(provinceid) {
            //
            this.axios
                .get(constant.get_region, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    params: {
                        parentid: provinceid,
                    },
                })
                .then((response) => {
                    console.log(response.data);
                    this.citys = response.data;
                });
        },
        GetArea(cityid) {
            //
            this.axios
                .get(constant.get_region, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    params: {
                        parentid: cityid,
                    },
                })
                .then((response) => {
                    console.log(response.data);
                    this.areas = response.data;
                });
        },
        ResetCity(provinceid) {
            this.addrinfo.fkAreaIDL2 = "";
            this.addrinfo.fkAreaIDL3 = "";
            this.axios
                .get(constant.get_region, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    params: {
                        parentid: provinceid,
                    },
                })
                .then((response) => {
                    console.log(response.data);
                    this.citys = response.data;
                    this.addrinfo.fkAreaIDL2 = this.citys[0]["region_id"];
                    this.ResetArea(this.addrinfo.fkAreaIDL2);
                });
        },
        ResetArea(cityid) {
            this.addrinfo.fkAreaIDL3 = "";
            this.axios
                .get(constant.get_region, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    params: {
                        parentid: cityid,
                    },
                })
                .then((response) => {
                    console.log(response.data);
                    this.areas = response.data;
                    this.addrinfo.fkAreaIDL3 = this.areas[0]["region_id"];
                });
        },
        /*
                Comfirm(id){
                  console.log(id);
                  this.$confirm('确认要通过该订单的审核么？','提示',{
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                  }).then(()=>{
                    this.axios.get(constant.confirm_order,{
                      headers: {
                        "Content-Type": "application/json",
                      },
                      params:{
                        id: id
                      }
                    }).then((response)=>{
                      console.log(response.data);
                      if(response.data == "OK"){
                        this.$message.success("操作成功");
                        this.$router.go(0);
                      }else{
                        return false;
                      }
                    })
                  }).catch(()=>{
                    return false;
                  })
                },
                */
        CheckReceipt(id) {
            this.axios
                .get(constant.get_order_logistic, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    params: {
                        orderid: id,
                    },
                })
                .then((response) => {
                    console.log(response.data);
                    this.receipt = true;
                    this.receiptdata = response.data;
                });
        },
        UploadSuccess(response, file, filelist) {
            console.log(file);
            console.log("list");
            console.log(filelist);
            console.log(response);
            if (response.error == "0") {
                //this.pdtinfo.PdtImg.push(response.url);
                this.estimateinfo.PdtImg = [];
                for (var i = 0; i < filelist.length; i++) {
                    var uid = filelist[i]["uid"];
                    var name = filelist[i]["name"];
                    var url = "";
                    if (filelist[i]["response"]) {
                        var upload_response = filelist[i]["response"];
                        url = upload_response.url;
                    } else {
                        url = filelist[i]["url"];
                    }
                    var obj = new Object();
                    obj.uid = uid;
                    obj.name = name;
                    obj.url = url;
                    this.estimateinfo.PdtImg.push(obj);
                }
                console.log("mylist");
                console.log(this.estimateinfo.PdtImg);
            }
        },
        RemovePic(file, filelist) {
            console.log(file);
            console.log(filelist);
            this.estimateinfo.PdtImg = [];
            for (var i = 0; i < filelist.length; i++) {
                var uid = filelist[i]["uid"];
                var name = filelist[i]["name"];
                var url = filelist[i]["url"];
                var obj = new Object();
                obj.uid = uid;
                obj.name = name;
                obj.url = url;
                this.estimateinfo.PdtImg.push(obj);
            }
        },
        Preview(file) {
            console.log(file);
            if (file.response) {
                if (file.response.error == 0) {
                    this.dialogImageUrl = file.response.url;
                    console.log(this.dialogImageUrl);
                }
            } else {
                this.dialogImageUrl = file.url;
            }
            this.dialogVisible = true;
        },
        UploadSuccess2(response, file, filelist) {
            console.log(file);
            console.log("list");
            console.log(filelist);
            console.log(response);
            if (response.error == "0") {
                //this.pdtinfo.PdtImg.push(response.url);
                this.estimateinfo.PdtImg2 = [];
                for (var i = 0; i < filelist.length; i++) {
                    var uid = filelist[i]["uid"];
                    var name = filelist[i]["name"];
                    var url = "";
                    if (filelist[i]["response"]) {
                        var upload_response = filelist[i]["response"];
                        url = upload_response.url;
                    } else {
                        url = filelist[i]["url"];
                    }
                    var obj = new Object();
                    obj.uid = uid;
                    obj.name = name;
                    obj.url = url;
                    this.estimateinfo.PdtImg2.push(obj);
                }
                console.log("mylist");
                console.log(this.estimateinfo.PdtImg2);
            }
        },
        RemovePic2(file, filelist) {
            console.log(file);
            console.log(filelist);
            this.estimateinfo.PdtImg2 = [];
            for (var i = 0; i < filelist.length; i++) {
                var uid = filelist[i]["uid"];
                var name = filelist[i]["name"];
                var url = filelist[i]["url"];
                var obj = new Object();
                obj.uid = uid;
                obj.name = name;
                obj.url = url;
                this.estimateinfo.PdtImg2.push(obj);
            }
        },
        Preview2(file) {
            console.log(file);
            if (file.response) {
                if (file.response.error == 0) {
                    this.dialogImageUrl2 = file.response.url;
                    console.log(this.dialogImageUrl2);
                }
            } else {
                this.dialogImageUrl2 = file.url;
            }
            this.dialogVisible2 = true;
        },
        UploadSuccess3(response, file, filelist) {
            console.log(file);
            console.log("list");
            console.log(filelist);
            console.log(response);
            if (response.error == "0") {
                //this.pdtinfo.PdtImg.push(response.url);
                this.estimateinfo.PdtImg3 = [];
                for (var i = 0; i < filelist.length; i++) {
                    var uid = filelist[i]["uid"];
                    var name = filelist[i]["name"];
                    var url = "";
                    if (filelist[i]["response"]) {
                        var upload_response = filelist[i]["response"];
                        url = upload_response.url;
                    } else {
                        url = filelist[i]["url"];
                    }
                    var obj = new Object();
                    obj.uid = uid;
                    obj.name = name;
                    obj.url = url;
                    this.estimateinfo.PdtImg3.push(obj);
                }
                console.log("mylist");
                console.log(this.estimateinfo.PdtImg3);
            }
        },
        RemovePic3(file, filelist) {
            console.log(file);
            console.log(filelist);
            this.estimateinfo.PdtImg3 = [];
            for (var i = 0; i < filelist.length; i++) {
                var uid = filelist[i]["uid"];
                var name = filelist[i]["name"];
                var url = filelist[i]["url"];
                var obj = new Object();
                obj.uid = uid;
                obj.name = name;
                obj.url = url;
                this.estimateinfo.PdtImg3.push(obj);
            }
        },
        Preview3(file) {
            console.log(file);
            if (file.response) {
                if (file.response.error == 0) {
                    this.dialogImageUrl3 = file.response.url;
                    console.log(this.dialogImageUrl3);
                }
            } else {
                this.dialogImageUrl3 = file.url;
            }
            this.dialogVisible3 = true;
        },
        UploadError3(error) {
            console.log(error);
        },
        beforeAvatarUploadid(file) {
            console.log(file.name);
            let array1 = [];
            let attrname = file.name.split(".")[1];
            console.log(attrname);
            if (this.value2 == 0) {
                array1 = ["jpg", "png", "jpeg"];
            }
            let index = array1.indexOf(attrname);
            if (index == -1) {
                ElMessage.error("文件类型错误，请上传指定类型的文件");
                return false;
            }
            return true;
        },
        UploadSuccessid1(response, file, filelist) {
            if (response.error == "0") {
                this.idimg1 = [];
                for (var i = 0; i < filelist.length; i++) {
                    var uid = filelist[i]["uid"];
                    var name = filelist[i]["name"];
                    var url = "";
                    if (filelist[i]["response"]) {
                        var upload_response = filelist[i]["response"];
                        url = upload_response.url;
                    } else {
                        url = filelist[i]["url"];
                    }
                    var obj = new Object();
                    obj.uid = uid;
                    obj.name = name;
                    obj.url = url;
                    this.idimg1.push(obj);
                }
            }
        },
        RemovePicid1(file, filelist) {
            this.idimg1 = [];
            for (var i = 0; i < filelist.length; i++) {
                var uid = filelist[i]["uid"];
                var name = filelist[i]["name"];
                var url = filelist[i]["url"];
                var obj = new Object();
                obj.uid = uid;
                obj.name = name;
                obj.url = url;
                this.idimg1.push(obj);
            }
        },
        Previewid1(file) {
            console.log(file);
            if (file.response) {
                if (file.response.error == 0) {
                    this.ImageUrl1 = file.response.url;
                }
            } else {
                this.ImageUrl1 = file.url;
            }
            this.id1 = true;
        },
        UploadSuccessid2(response, file, filelist) {
            console.log(file);
            console.log("list");
            console.log(filelist);
            console.log(response);
            if (response.error == "0") {
                //this.pdtinfo.PdtImg.push(response.url);
                this.idimg2 = [];
                for (var i = 0; i < filelist.length; i++) {
                    var uid = filelist[i]["uid"];
                    var name = filelist[i]["name"];
                    var url = "";
                    if (filelist[i]["response"]) {
                        var upload_response = filelist[i]["response"];
                        url = upload_response.url;
                    } else {
                        url = filelist[i]["url"];
                    }
                    var obj = new Object();
                    obj.uid = uid;
                    obj.name = name;
                    obj.url = url;
                    this.idimg2.push(obj);
                }
            }
        },
        RemovePicid2(file, filelist) {
            this.id2 = [];
            for (var i = 0; i < filelist.length; i++) {
                var uid = filelist[i]["uid"];
                var name = filelist[i]["name"];
                var url = filelist[i]["url"];
                var obj = new Object();
                obj.uid = uid;
                obj.name = name;
                obj.url = url;
                this.idimg2.push(obj);
            }
        },
        Previewid2(file) {
            console.log(file);
            if (file.response) {
                if (file.response.error == 0) {
                    this.ImageUrl2 = file.response.url;
                }
            } else {
                this.ImageUrl2 = file.url;
            }
            this.id2 = true;
        },
        UploadExceed(file, filelist) {
            this.$message.warning(
                `当前限制选择 5 个图片，本次选择了 ${file.length} 个文件，共选择了 ${file.length + filelist.length
                } 个文件`
            );
        },
        Addition(id, status) {
            this.axios
                .get(constant.getadditioninfo, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    params: {
                        orderid: id,
                    },
                })
                .then((response) => {
                    console.log(response.data);
                    this.additioninfo.buyoutprice = response.data.BuyoutPrice;
                    this.additioninfo.needbuyoutprice = response.data.NeedBuyoutPrice;
                    this.additioninfo.orderid = id;
                    this.additioninfo.status = status;
                    this.addition = true;
                });
        },
        CloseAddition() {
            this.addition = false;
            this.additioninfo.orderid = "";
            this.additioninfo.type = "";
            this.additioninfo.money = "";
            this.additioninfo.PdtImg = "";
        },
        SaveAddition() {
            this.$refs["additioninfo"].validate((valid) => {
                if (valid) {
                    console.log(valid);
                    if (this.additioninfo.money > this.additioninfo.needbuyoutprice) {
                        this.$message.error("买断金额不能大于未还金额");
                        return false;
                    }
                    this.axiso
                        .post(constant.save_addition, this.additioninfo, {
                            headers: { "Content-Type": "application/x-www-form-urlencoded" },
                        })
                        .then((response) => {
                            console.log(response.data);
                            if (response.data == "OK") {
                                this.$message.success("操作成功");
                                this.addition = false;
                            }
                        });
                } else {
                    return false;
                }
            });
        },
        Offline(item, id) {
            this.offline = true;
            this.offlineitem = item;
            this.offlineinfo.orderid = id;
            this.GetLogCom();
        },
        SaveOffline() {
            this.$refs["offlineinfo"].validate((valid) => {
                if (valid) {
                    console.log(valid);
                    this.axios
                        .post(constant.save_offline, this.offlineinfo, {
                            headers: { "Content-Type": "application/x-www-form-urlencoded" },
                        })
                        .then((response) => {
                            console.log(response.data);
                            if (response.data == "OK") {
                                this.offlineitem.Status = 7;
                                this.$message.success("操作成功");
                                this.offline = false;
                                this.$router.go(0);
                            }
                        });
                } else return false;
            });
        },
        CloseOffline() {
            this.offline = false;
            this.offlineinfo.orderid = "";
            this.offlineinfo.shippingcom = "";
            this.offlineinfo.shippingno = "";
        },
        OrderDetail(id) {
            console.log(this.curpage);
            let href1 = this.$router.resolve({
                path: "/orderdetail",
                query: {
                    id: id,
                    curpage: this.curpage,
                    pagesize: this.pagesize,
                    status: this.status,
                    range: JSON.stringify(this.range),
                    keyword: this.keyword,
                    type: this.type,
                },
            });
            window.open(href1.href, "_blank");
        },
        GetLogisticDetail(logno) {
            console.log(logno);
            this.axios
                .get(constant.get_logistic_detail, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    params: {
                        logno: logno,
                    },
                })
                .then((response) => {
                    console.log(response.data.result.danhao);
                    this.expName = response.data.result.expName;
                    this.danhao = response.data.result.danhao;
                    this.number = response.data.result.number;
                    let index = this.number.lastIndexOf(":");
                    this.number = this.number.substring(0, index);
                    this.logdetail = response.data.result.list;
                    this.logisticdetail = true;
                });
            return false;
        },
        Prepay(orderid, item) {
            this.prepayinfo.orderid = orderid;
            this.prepayinfo.item = item;
            console.log(item);
            this.axios
                .get(constant.get_billno, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    params: {
                        orderid: orderid,
                        item: JSON.stringify(item),
                    },
                })
                .then((response) => {
                    console.log(response.data);
                    this.prepayinfo.isprepay = response.data.isprepay;
                    this.prepayinfo.pbino = response.data.pbino;
                    this.rentorder = response.data.list;
                    this.prepay = true;
                });
            console.log(this.prepayinfo);
        },
        ClosePrepay() {
            this.prepay = false;
            this.prepayinfo.tip = false;
            this.prepayinfo.orderid = "";
            this.prepayinfo.pbino = [];
            this.prepayinfo.isprepay = "2";
            this.prepayinfo.replenish = 0;
            this.prepayinfo.result = "";
            this.prepayinfo.item = "";
        },
        getIdentityId() {
            this.axios
                .get(constant.get_identity_id, {
                    headers: {
                        "content-type": "application/json",
                    },
                    params: {
                        id: this.prepayinfo.item.ID,
                    },
                })
                .then((response) => {
                    //console.log("")
                    if (response.data[0]["LicenseImg"].length == 0) {
                        this.prepayinfo.tip = true;
                        return false;
                    }
                });
        },
        SavePrepay() {
            this.axios
                .get(constant.checkdb, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    params: {
                        orderid: this.prepayinfo.orderid,
                    },
                })
                .then((response) => {
                    console.log(response.data);
                    if (response.data.code == 200) {
                        this.$message.error("请调拨后在审核");
                        return false;
                    } else {
                        this.SavePrepay2();
                    }
                });
        },
        SavePrepay2() {
            console.log(this.prepayinfo);
            console.log(this.question);
            console.log(this.prepayinfo.tip);
            console.log(this.prepayinfo.result);
            if (this.prepayinfo.result == 1) {
                if (this.prepayinfo.tip) {
                    this.$confirm("该客户未上传身份证信息，确认审核通过吗？", "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                    })
                        .then(() => {
                            this.axios
                                .post(constant.save_pbi, this.prepayinfo, {
                                    headers: {
                                        "Content-Type": "application/x-www-form-urlencoded",
                                    },
                                })
                                .then((response) => {
                                    console.log("审核结果");
                                    console.log(response.data);
                                    if (response.data == "OK") {
                                        this.prepayinfo.item.Status = 3;
                                        this.shenheloading = false;
                                        this.$message.success("操作成功");
                                        this.prepay = false;
                                        //this.$router.go(0);
                                    }
                                });
                        })
                        .catch(() => {
                            return false;
                        });
                } else {
                    this.axios
                        .post(constant.save_pbi, this.prepayinfo, {
                            headers: { "Content-Type": "application/x-www-form-urlencoded" },
                        })
                        .then((response) => {
                            console.log(response.data);
                            if (response.data == "OK") {
                                this.prepayinfo.item.Status = 3;
                                // this.hetong = true
                                // this.dis = false
                                this.$message.success("操作成功");
                                this.prepay = false;
                                //this.$router.go(0);
                            }
                        });
                }
            }
            if (this.prepayinfo.result == 2) {
                this.axios
                    .post(constant.save_pbi, this.prepayinfo, {
                        headers: { "Content-Type": "application/x-www-form-urlencoded" },
                    })
                    .then((response) => {
                        console.log(response.data);
                        if (response.data == "OK") {
                            this.prepayinfo.item.Status = 13;
                            this.$message.success("操作成功");
                            this.prepay = false;
                            //this.$router.go(0);
                        }
                    });
            }
        },
        ShowDeposit(orderid) {
            this.depositinfo = true;
            this.axios
                .get(constant.get_deposit, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    params: {
                        orderid: orderid,
                    },
                })
                .then((response) => {
                    console.log(response.data);
                    this.plan_deposit = response.data.PlanDeposit * 1;
                    this.plan_sdeposit = response.data.SDecrease * 1;
                    this.plan_rdeposit = response.data.RDecrease * 1;
                    this.plan_replenish = response.data.Replenish * 1;
                    this.plan_depositpaid = response.data.DepositPaid * 1;
                    this.restor_deposit = response.data.DepositPaid * 1;
                    this.deposit = response.data.Deposit * 1;
                    this.cur_order_status = response.data.Status;
                    this.zhimadecrease = response.data.ZhimaDecrease * 1;
                });
        },
        //归还确认收货
        ReReceive(orderid) {
            this.$confirm("确认已经收到客户寄回来的商品了么？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    this.axios
                        .get(constant.confirm_ReReceive, {
                            headers: {
                                "Content-Type": "application/json",
                            },
                            params: {
                                orderid: orderid,
                            },
                        })
                        .then((response) => {
                            console.log(response.data);
                            if (response.data == "OK") {
                                this.$message.success("操作成功");
                                this.$router.go(0);
                            } else {
                                return false;
                            }
                        });
                })
                .catch(() => {
                    return false;
                });
        },
        //填写报告，赔偿金
        EstimateInfo(orderid) {
            //this.reportorder = orderid
            this.estimateinfo.orderid = orderid;
            this.report = true;
            this.axios
                .get(constant.get_estimate_order, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    params: {
                        orderid: orderid,
                    },
                })
                .then((response) => {
                    console.log(response.data);
                    this.estimateinfo = response.data;
                });
        },
        //保存质检报告
        SaveEstimate() {
            console.log(this.estimateinfo);
            if (!this.estimateinfo.TestType) {
                this.$message.error("请选择报告的类型");
                return false;
            }
            this.axios
                .post(constant.save_estimate, this.estimateinfo, {
                    headers: { "Content-Type": "application/x-www-form-urlencoded" },
                })
                .then((response) => {
                    console.log(response.data);
                    if (response.data == "OK") {
                        this.$message.success("操作成功");
                        this.report = false;
                        this.$router.go(0);
                    } else {
                        return false;
                    }
                });
        },
        //合同
        handleClose5() {
            this.ht = false;
        },
        CheckContract(orderuid, lease) {
            console.log(lease);
            if (lease != null) {
                this.ht = true;
                this.hetongurl = orderuid;
            } else {
                window.open(
                    "https://xuanzj.xianniuzu.com/alimini/pageconfig/users/user_1/agreement/" +
                    orderuid +
                    ".pdf",
                    "_blank"
                );
            }
            // this.hetongurl = "https://xuanzj.xianniuzu.com/alimini/pageconfig/users/user_1/agreement/" + OrderUID +".pdf";
        },
        jumpurl(url) {
            if (url == 1) {
                window.open(
                    "https://xuanzj.xianniuzu.com/alimini/pageconfig/users/user_1/agreement/" +
                    this.hetongurl +
                    ".pdf",
                    "_blank"
                );
            }
            if (url == 2) {
                this.axios
                    .get(constant.cunzheng, {
                        headers: {
                            "Content-Type": "application/json",
                        },
                        params: {
                            orderuid: this.hetongurl,
                        },
                    })
                    .then((response) => {
                        console.log(response.data.flowdownload == "");
                        if (response.data.flowdownload != "") {
                            window.open(response.data.flowdownload, "_blank");
                        } else {
                            this.$message.error("验签报告正在获取中,请稍后在试...");
                        }
                    });
            }
        },
    },
};
</script>
<style>
.jiaofu3 {
    margin: 5px 0;
}

.jiaofu2 {
    display: flex;
    flex-direction: column;
}

.jiaofu {
    padding: 10px;
    text-align: center;
}

.image222 {
    width: 100px;
    height: 100px;
}

.ziliao {
    padding: 5px 0;
    text-align: center;
}

.hei {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    padding: 50% 29%;
    border: 1px solid #b5b3b3;
    border-radius: 10px;
}

.pad {
    border: 1px solid #b5b3b3;
    border-radius: 10px;
    text-align: center;
    padding: 10px 0;
}

.cen {
    margin-top: 15px;
}

.container .el-header {
    /* text-align: center;
  font-size:16px; */
    /* border-bottom:0;
  line-height: 0px;
  --el-header-height:0; */
}

.el-upload {
    width: 100%;
}

.el-upload-dragger {
    width: 100%;
    display: flex;
    align-items: center;
}

.el-upload__text {
    flex: 1;
}

.is-loading {
    transform: rotate(180deg);
}

.el-card__body {
    padding: 0 20px;
}

.demo-tabs {
    color: #6b778c;
    font-size: 32px;
    font-weight: 600;
}

.rad {
    text-align: center;
    line-height: 30px;
    width: 30px;
    height: 30px;
    font-size: 20px;
    margin-top: -5px;
    background-color: #b4bbc5;
    -webkit-border-radius: 15px;
}

.editbtn {
    display: inline-block;
    margin-right: 10px;
}

.blockitem {
    margin-right: 10px;
}

.msginfo {
    font-size: 14px;
    color: #757575;
    margin-right: 10px;
}

.orderheader,
.orderbottom,
.orderbottom-merchant {
    border-top: 1px solid #eeeded;
    border-bottom: 1px solid #eeeded;
    padding: 5px;
    background-color: #f6fbff;
    display: flex;
    align-items: center;
    height: 32px;
}

.orderbottom {
    justify-content: space-between;
}

.orderbottom-merchant {
    justify-content: flex-end;
}

.leftbtn {
    display: flex;
    justify-content: center;
    align-items: center;
}

.riskinfo-block {
    border-bottom: 1px solid #eeeded;
    padding: 5px;
    font-size: 14px;
    color: #d83737;
    background-color: #f6fbff;
}

.riskinfo-block p {
    display: inline;
}

.headerspan {
    margin-right: 20px;
    font-size: 14px;
    font-weight: bold;
    color: #757575;
}

.bottom {
    line-height: 12px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.order-item {
    margin-bottom: 15px;
}

.pdt-block {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
}

.merchant-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.merchant-block .bottom {
    margin-top: 20px;
}

.riskinfo {
    float: left;
}

.el-radio {
    padding-bottom: 10px;
}
</style>
